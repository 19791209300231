
import InsightUser from '@/models/core/InsightUser'
import { PropValidator } from 'vue/types/options'
import store from '@/store'

export default {
  props: {
    size: {
      type: String,
      default: '32'
    },

    user: {
      type: Object,
      default: null,
      required: true,
    } as PropValidator<InsightUser>,
  },

  methods: {
    store: store,
  }
}
