import api from '@/util/api'
import { ApiEntityPage } from '@/models/core/api/ApiEntityPage'
import { ApiRequestParams } from '@/models/core/api/ApiRequestParams'
import { buildQueryVarsForSearch } from '@/models/core/searchQueries/ApiQueryVarsBuilder'
import { Commit } from 'vuex'
import EntitiesForRef from '@/models/core/vuex/EntitiesForRef'
import EntityActionResult from '@/models/core/api/EntityActionResult'
import { EntityLinkDescriptionUpdateRequest } from '@/models/core/entities/EntityLinkDescriptionUpdateRequest'
import { EntityPagingData } from '@/models/core/entities/EntityPagingData'
import PrivateEntity from '@/models/core/entities/PrivateEntity'
import PrivateEntityListModel from '@/models/core/entities/PrivateEntityListModel'
import { SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest'
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode'

export function findEntityById<T extends PrivateEntity>(arr: T[], spec: PrivateEntity, trySpectempId = false): number {
  let idx = -1;

  if (trySpectempId) {
    idx = arr.findIndex(e => { return e.id == spec.tempId && e.client == spec.client })
  }

  if (idx < 0) {
    idx = arr.findIndex(e => { return e.id == spec.id && e.client == spec.client })
  }

  return idx;
}



function getSearchUrlForController(controllerUrl: string): string {
  return `${controllerUrl}/searchRequest`;
}

export async function getEntityList<T extends PrivateEntityListModel<PrivateEntity>>(
  url: string,
  clientCode: string,
  mutationName: string,
  refName: string,
  commit: Commit,
  includeDeleted = false,
  pagerId?: string,
  pageSize?: number,
  pageNumber?: number,
  pageDataMutationName?: string,
): Promise<T[]> {
  const params: ApiRequestParams = [['includeDeleted', (includeDeleted || false) + ""]];

  if (pagerId) {
    params.push(["pageSize", `${pageSize}`])
    params.push(["pageNumber", `${pageNumber}`])
  }

  const response = await api.get(url, params);

  if (!response) return Promise.reject();

  if (response.error) {
    return Promise.reject(response)
  }

  let entities: T[];

  if (pagerId) {
    entities = (response.data as ApiEntityPage<T>).entities;
  } else {
    entities = response.data as T[]
  }

  const mutationData: EntitiesForRef<T> = {
    entities,
    refName,
    client: clientCode,
  }

  commit(mutationName, mutationData)

  if (pagerId && pageDataMutationName) {
    const d = response.data as ApiEntityPage<T>;
    const pageData = new EntityPagingData(pagerId, d.pageSize, d.pageNumber, d.entityCount);

    commit(pageDataMutationName, pageData);
  }

  return entities as T[];
}

export async function doEntitySearch<T extends PrivateEntityListModel<PrivateEntity>>(
  controllerUrl: string,
  clientCode: string,
  searchValues: SearchUIFieldValue[],
  mutationName: string,
  refName: string,
  commit: Commit,
  includeDeleted = false,
  pagerId?: string,
  pageSize?: number,
  pageNumber?: number,
  pageDataMutationName?: string,
  orderBy?: string,
  orderDescending?: boolean,
): Promise<T[]> {
  const queryVars = buildQueryVarsForSearch(
    searchValues,
    includeDeleted,
    false,
    pageSize,
    pageNumber,
    orderBy,
    orderDescending,
  );

  const response = await api.create(getSearchUrlForController(controllerUrl), queryVars);

  if (!response) return Promise.reject();

  if (response.error) {
    return Promise.reject(response)
  }

  let entities: T[];

  if (pagerId) {
    entities = (response.data as ApiEntityPage<T>).entities;
  } else {
    entities = response.data as T[]
  }

  const mutationData: EntitiesForRef<T> = {
    entities,
    refName,
    client: clientCode,
  }

  commit(mutationName, mutationData)

  if (pagerId && pageDataMutationName) {
    const d = response.data as ApiEntityPage<T>;
    const pageData = new EntityPagingData(pagerId, d.pageSize, d.pageNumber, d.entityCount);

    commit(pageDataMutationName, pageData);
  }

  return entities as T[];
}

export async function getPrivateEntity<T extends PrivateEntity>(
  url: string,
  mutationName: string,
  refName: string,
  commit: Commit
): Promise<T | null> {
  const response = await api.get(url)

  if (!response) return Promise.reject(null);

  if (response.error) {
    return Promise.reject(response)
  }

  commit(mutationName, {entity: response.data, refName});

  return response.data as T
}

export async function getPrivateEntityBySearch<T extends PrivateEntity>(
  controllerUrl: string,
  searchValues: SearchUIFieldValue[],
  mutationName: string,
  refName: string,
  commit: Commit,
  includeDeleted = false,
  expectPagedResult = false
): Promise<T | null> {
  const queryVars = buildQueryVarsForSearch(searchValues, includeDeleted, true);
  const response = await api.create(getSearchUrlForController(controllerUrl), queryVars)

  if (!response) return Promise.reject(null);

  if (response.error) {
    return Promise.reject(response)
  }

  let entities: T[];

  if (expectPagedResult) {
    entities = (response.data as ApiEntityPage<T>).entities;
  } else {
    entities = response.data as T[];
  }

  if (entities.length !== 1) {
    return Promise.reject("The query returned multiple results!")
  }

  commit(mutationName, {entity: entities[0], refName});

  return entities[0] as T
}

export async function createOrUpdatePrivateEntity<T extends PrivateEntity>(
  entity: T,
  controllerUrl: string,
  mutationName: string,
  refName: string,
  commit: Commit,
  mode: VuexEntityStorageRequestMode = VuexEntityStorageRequestMode.Add
): Promise<T|null> {

  const entityId = +(entity.id ?? "-1");
  const hasTempId = (entityId < 0)
  let response
  
  if (!hasTempId) {
    response = await api.update(controllerUrl + '/' + entity.id, entity)
  } else {
    response = await api.create(controllerUrl, entity)
  }

  if (!response) return Promise.reject(null);

  if (response.error) {
    return Promise.reject(response)
  }

  const savedEntity = response.data as T;

  if (hasTempId) {
    savedEntity.tempId = entity.id;
  } else {
    savedEntity.tempId = entity.tempId;
  }

  const mutationData: VuexEntityStorageRequest<T> = {
    entity: savedEntity,
    refName,
    mode
  }

  commit(mutationName, mutationData)

  return savedEntity
}

export async function deletePrivateEntity<T extends PrivateEntity>(
  entity: T,
  url: string,
  forceDelete: boolean,
  mutationName: string,
  commit: Commit,
  hardDelete = false
): Promise<void> {
  const params: ApiRequestParams = [['forceDelete', (forceDelete || false) + ""], ['hardDelete', `${hardDelete ?? false}`]]

  const response = await api.delete(url, params)

  if (!response) return Promise.reject();
  
  if (response.error) {
    return Promise.reject(response)
  }

  commit(mutationName, entity)
}

export async function executeActionOnEntity<T extends PrivateEntity>(
  entity: T,
  controllerUrl: string,
  actionName: string,
  mutationName: string,
  commit: Commit,
  actionPayload?: unknown,
): Promise<T> {
  const url = `${controllerUrl}/${entity.id}/actions/${actionName}`

  const apiResponse = await api.create(url, actionPayload ?? null);

  if (apiResponse) {
    if (!apiResponse.error) {
      const actionResult = apiResponse.data as EntityActionResult<T>
      const entity = actionResult.entity;

      commit(mutationName, {entity: entity, refName: '', mode: 'updateOnly'})

      return entity;
    } else {
      return Promise.reject(apiResponse);
    }
  } else {
    return Promise.reject("No response from API");
  }
}

export async function getLinkDescription(
  clientCode: string,
  entityId: string,
  targetEntityType: string,
  targetEntityId: string,
  mutationName: string,
  commit: Commit
): Promise<EntityLinkDescriptionUpdateRequest> {
  const url = `${clientCode}/linkDescriptions/${targetEntityType}/${targetEntityId}`

  const apiResponse = await api.get(url);

  if (apiResponse) {
    if (!apiResponse.error) {
      const description = apiResponse.data as {id: string; description: string}

      const req = new EntityLinkDescriptionUpdateRequest(entityId, description.id, description.description);

      commit(mutationName, req);

      return req;
    } else {
      return Promise.reject(apiResponse);
    }
  } else {
    return Promise.reject("No response from API");
  }
}