
import { EntityManagerInterface } from '@/models/core/entityManager/EntityManagerInterface';
import { TicketTypeListModel } from '@/models/tickets/TicketType';
import TicketTypeManager from './TicketTypeManager.vue'
import Vue from 'vue';

export default Vue.extend({
  components: {
    TicketTypeManager
  },

  props: {
    clientCode: {
      type: String,
      default: '',
      required: true,
    },

    show: Boolean,
  },

  data() {
    return {
      selectedType: null as TicketTypeListModel|null,
    }
  },

  beforeDestroy() {
    (this.$refs["mgr"] as unknown as EntityManagerInterface)?.unloadEntities(true);
  },

  methods: {
    async close(mode: 'cancel'|'save'): Promise<void> {
      if (mode == 'save') {
        this.$emit('entity-selected', this.selectedType);
      }

      this.selectedType = null;
      this.$emit('update:show', false);
    }
  }
})
