
import { CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED, TICKET_SAVE } from '@/store/actionNames'
import { CORE_GET_LIFECYCLE_FOR_TYPE } from '@/store/getterNames';
import EntityNew from '../common/EntityNew.vue'
import { EntityType } from '@/models/assignments/AssignmentTemplate';
import { Lifecycle } from '@/models/lifecycles/Lifecycle';
import { PropValidator } from 'vue/types/options';
import { Ticket } from '@/models/tickets/Ticket';
import { TICKET_EDITOR } from '@/router/routeNames';
import TicketEditorFieldSet from "./TicketEditorFieldSet.vue";
import Vue from 'vue'

export default Vue.extend({

  components: {
    EntityNew,
    TicketEditorFieldSet,
  },

  props: {
    client: {
      type: String,
      default: ''
    },

    entityType: {
      type: String,
      default: 'Ticket',
    } as PropValidator<EntityType>,

    show: Boolean,
  },

  data () {
    return {
      newTicket: new Ticket()
    }
  },

  computed: {
    lifecycle(): Lifecycle {
      return this.$store.getters[CORE_GET_LIFECYCLE_FOR_TYPE]("Ticket") ?? new Lifecycle()
    },

    routeName(): string {
      return TICKET_EDITOR
    },

    saveAction(): string {
      return TICKET_SAVE;
    },
  },

  watch: {
    show: {
      handler() {
        if (this.show) {
          this.newTicket = new Ticket();
          this.newTicket.client = this.client;
          this.newTicket.status = this.lifecycle.stages[0]?.statuses[0]?.status ?? '';
          Vue.set(this.newTicket, "requestedLinks", []);
          Vue.set(this.newTicket, "targetDate", "0001-01-01T00:00:00");
          Vue.set(this.newTicket, "initialWorkflow", null);
        }
      }
    },
  },

  created() {
    this.$store.dispatch(CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED, "Ticket");
  },
})
