import { MessageContactState, MessageContactType, MessageState } from './MessageEnums';
import Message from './Message';
import MessageContact from './MessageContact';
import PrivateEntityListModel from '../core/entities/PrivateEntityListModel';

export default class MessageListModel extends PrivateEntityListModel<Message> {
    
  public state: MessageState = MessageState.Draft;
  public conversationId = "0";
  public subject = "";
  public from = "";
  public to = "";
  public attachmentCount = 0;
  public messageDate = '';
  public unread: string[] = [];
  
  protected populateCore(model: Message): void {
    this.state = model.state
    this.conversationId = model.conversationId;
    this.subject = model.subject;
    this.from = this.formatContacts(model.contacts.filter(x => x.type == MessageContactType.From));
    this.to = this.formatContacts(model.contacts.filter(x => x.type == MessageContactType.To));
    this.attachmentCount = model.attachments.length;
    this.messageDate = model.messageDate;
    this.unread = this.getUnreadAddresses(model.contacts);
  }

  private formatContacts(contacts: MessageContact[]): string {
    const formattedAddresses: string[] = [];

    contacts.forEach(contact => {      
      if ((contact.name || '') > '') {
        formattedAddresses.push(contact.name || '')
      } else {
        formattedAddresses.push(contact.address)
      }
    });

    return formattedAddresses.join(', ')
  }

  private getUnreadAddresses(contacts: MessageContact[]): string[] {
    const unreadAddresses: string[] = [];

    contacts.forEach(c => {
      if (c.type != MessageContactType.From && c.state != MessageContactState.Read) {
        unreadAddresses.push(c.address);
      }
    });

    return unreadAddresses;
  }
}

export class MessageListModelWithPaging extends MessageListModel {
  constructor(
    public isPageControlItem?: boolean,
    public pageNumberToLoad?: number,
  ) {
    super();
  }
}