
import { CUSTOMER_LOAD, WORK_ORDER_SAVE } from '@/store/actionNames';
import { CUSTOMERS_OPEN_FOR_REF, WORK_ORDER_CATEGORIES_LISTED_FOR_REF } from '@/store/getterNames';
import { ENTITY_MANAGER_REF, WORK_ORDER_CATEGORY_FIELD_REF, WORK_ORDER_NEW_REF } from '@/store/refNames';
import { fetchSetting, SettingName } from '@/util/settingsApi';
import reformatDate, { ensureDateObject } from '@/util/reformatDate';
import Address from '@/models/core/Address';
import AddressField from '@/components/common/forms/AddressField.vue'
import Customer from '@/models/customers/Customer';
import { CUSTOMER_DROP_REFERRER } from '@/store/mutationNames';
import CustomerChooser from '../customers/CustomerChooser.vue'
import CustomerListModel from '@/models/customers/CustomerListModel';
import DateField from '@/components/common/forms/DateField.vue'
import delay from '@/util/delay';
import { moneyFormatter } from "@/util/ValueFormatter";
import NameField from "../common/forms/NameField.vue"
import popup from '@/util/popup';
import ReadOnlyField from '../common/forms/ReadOnlyField.vue'
import Vue from 'vue'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec';
import { WORK_ORDER_EDITOR } from '@/router/routeNames';
import { WorkOrder } from '@/models/workOrders/WorkOrder';
import WorkOrderCategoryField from '@/components/workOrderCategories/WorkOrderCategoryField.vue'
import { WorkOrderCategoryListModel } from '@/models/workOrderCategories/WorkOrderCategoryListModel';
import { WorkOrderStep } from '@/models/workOrders/WorkOrderStep';
import { WorkOrderWorkflow } from '@/models/workOrders/WorkOrderWorkflow';
import WorkOrderWorkflowChooser from '../workOrders/WorkOrderWorkflowChooser.vue'

function getDefaultTargetDate(): string {
  const dt = new Date();
  dt.setDate(dt.getDate() + 7);

  return reformatDate(dt, 'yyyy-mm-dd');
}

export default Vue.extend({
  components: {
    AddressField,
    CustomerChooser,
    DateField,
    NameField,
    ReadOnlyField,
    WorkOrderCategoryField,
    WorkOrderWorkflowChooser,
  },

  props: {
    client: {
      type: String,
      default: '',
      required: true,
    },

    show: Boolean,
  },

  data() {
    return {
      addressesLoaded: false,
      billAddress: new Address(),
      categoryId: "0",
      currentStep: 1,
      customerId: "0",
      fetchingCustomer: false,
      name: "",
      poNumber: "",
      primaryDiscountPercent: 0,
      priority: 'Normal' as 'Normal'|'Rush',
      secondaryDiscountPercent: 0,
      selectedWorkflow: null as WorkOrderWorkflow|null,
      shipAddress: new Address(),
      showCustomerChooser: false,
      targetDate: getDefaultTargetDate(),
      workflowList: [] as WorkOrderWorkflow[],
      working: false,
      workOrderNumber: '',
      workOrderStatus: 'waiting' as 'waiting'|'error'|'created'
    }
  },

  computed: {
    canGoNext(): boolean {
      if (this.currentStep == 1) {
      return this.woDataIsValid
      } else if (this.currentStep == 2) {
        return this.selectedWorkflow != null
      } else {
        return true;
      }
    },

    canGoPrev(): boolean {
      if (this.currentStep == 1 || (this.currentStep == 4 && this.workOrderStatus != 'error')) {
        return false;
      } else {
        return true;
      }
    },

    categoryName(): string {
      return (this.$store.getters[WORK_ORDER_CATEGORIES_LISTED_FOR_REF](this.client, WORK_ORDER_CATEGORY_FIELD_REF)
        .find((x: WorkOrderCategoryListModel) => x.id == this.categoryId) as WorkOrderCategoryListModel)?.name ?? "Unknown Category"
    },

    customer(): Customer|undefined {
      return this.$store.getters[CUSTOMERS_OPEN_FOR_REF](this.client, WORK_ORDER_NEW_REF)[0]
    },

    customerName(): string {
      return this.customer?.name ?? "(Click to select)"
    },

    formattedTargetDate(): string {
      return reformatDate(this.targetDate, 'mm/dd/yyyy');
    },

    nextText(): string {
      if (this.currentStep == 3) {
        return 'Create Work Order'
      } else if (this.currentStep == 4) {
        return 'Close'
      } else {
        return 'Next'
      }
    },

    woDataIsValid(): boolean {
      const today = new Date();
      today.setHours(0,0,0,0);

      const target = ensureDateObject(this.targetDate);

      return (target ? target >= today : false) && this.categoryId != "0" && this.name > "" && !!this.customer;
    },
  },

  async mounted(): Promise<void> {
    //Load the workflow list
    this.workflowList = await fetchSetting<WorkOrderWorkflow[]>(this.client, SettingName.WorkOrderWorkflows, [])
  },

  beforeDestroy(): void {
    this.$store.commit(CUSTOMER_DROP_REFERRER, {client: this.client, name: WORK_ORDER_NEW_REF});
  },

  methods: {
    closeDialog(mode: 'goToWorkOrder'|'cancel'|'close'): void {
      if (mode == 'goToWorkOrder') {
        this.$nextTick(() => this.$router.push({
          name: WORK_ORDER_EDITOR,
          params: {
            clientCode: this.client,
            workOrderNumber: this.workOrderNumber
          }
        }));
      }

      this.$emit('update:show', false);

      delay(300).then(() => this.resetComponent());
    },

    compileSteps(): WorkOrderStep[] {
      const steps = [] as WorkOrderStep[]

      this.selectedWorkflow?.steps.forEach(s => {
        const ws = new WorkOrderStep();
        ws.populateFromWorkOrderStepConfig(s);
        steps.push(ws);
      });

      return steps;
    },

    async createWorkOrder(): Promise<void> {
      this.working = true;

      const wo = new WorkOrder();

      wo.quote = undefined;

      wo.customer = {
        id: this.customer?.id ?? '',
        name: this.customer?.name ?? '',
      }

      wo.client = this.client;
      wo.name = this.name;
      wo.poNumber = this.poNumber;

      wo.billAddress = this.billAddress;
      wo.shipAddress = this.shipAddress;

      wo.category = new WorkOrderCategoryListModel();
      wo.category.id = this.categoryId;

      wo.steps = this.compileSteps();
      wo.sections = [];
      wo.priority = this.priority;

      wo.primaryDiscountPercent = this.primaryDiscountPercent;
      wo.secondaryDiscountPercent = this.secondaryDiscountPercent;

      wo.targetDate = this.targetDate;

      try {
        const workOrder = await this.$store.dispatch(WORK_ORDER_SAVE, {entity: wo, refName: ENTITY_MANAGER_REF}) as WorkOrder;
        this.workOrderStatus = 'created'
        this.workOrderNumber = workOrder.workOrderNumber;
      } catch (e) {
        this.workOrderStatus = 'error';
      }

      this.working = false;
    },

    formatMoney(value: string|number): string {
      return moneyFormatter(value);
    },

    goNext(): void {
      if (this.currentStep < 4) {
        this.currentStep++

        if (this.currentStep == 4) this.createWorkOrder();
      } else {
        this.closeDialog('close');
      }
    },

    goPrev(): void {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },

    handleCustomerFieldClick(): void {
      this.showCustomerChooser = true;
    },

    async handleCustomerSelected(customer: CustomerListModel): Promise<void> {
      this.fetchingCustomer = true;
      const req: VuexEntityRefSpec = {
        spec: {
          id: customer.id,
          client: this.client,
        },
        refName: WORK_ORDER_NEW_REF,
      }

      await this.$store.dispatch(CUSTOMER_LOAD, req)

      this.customerId = customer.id;
      this.fetchingCustomer = false;

      const doPopulate = await popup.confirm(
        this.$store,
        "Use Address?",
        "Would you like to use the customer's address as the billing address?");

      if (doPopulate) {
        this.loadAddresses();
      }
    },
    
    loadAddresses(): void {
      if (this.customer) {
        this.billAddress = Address.clone(this.customer.billAddress);
      }
    },

    resetComponent(): void {
      this.addressesLoaded = false;
      this.billAddress = new Address();
      this.categoryId = "0";
      this.currentStep = 1;
      this.poNumber = "";
      this.primaryDiscountPercent = 0;
      this.secondaryDiscountPercent = 0;
      this.shipAddress = new Address();
      this.targetDate = getDefaultTargetDate();
      this.selectedWorkflow = null;
      this.working = false;
      this.workOrderStatus = 'waiting';
      this.priority = 'Normal';
      this.workOrderNumber = '';
    },
  }
})
