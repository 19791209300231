
import { handleEvent, VueEventListener } from '@/util/FunctionalComponentEventDispatcher';

export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-check'
    },

    iconColor: {
      type: String,
      default: '',
    },

    id: {
      type: String,
      default: null,
      required: true,
    },

    loading: Boolean,

    status: {
      type: String,
      default: 'unknown',
    },

    statusColor: {
      type: String,
      default: 'primary'
    },

    statusColorIsNotClass: Boolean,
  },

  methods: {
    handleClick(listeners: Record<string, VueEventListener|VueEventListener[]>, payload: never): void {
      handleEvent(listeners, 'click', payload);
    }
  }
}
