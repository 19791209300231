/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from '../routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { clientCodeExpr } from './_typesAndConstants'
import { InsightAuth } from 'insight-auth'
import { VueConstructor } from 'vue'

const WorkOrderManager = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "workorder" */ '@/components/workOrders/WorkOrderManager.vue')
  .then(m => m.default) as any

const WorkOrderEditor = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "workorder" */ '@/components/workOrders/WorkOrderEditor.vue')
  .then(m => m.default) as any

const workOrderKey = 'workOrders:clientCode'

export const WorkOrderRoutes = [
  {
    name: rt.WORK_ORDERS,
    path: '/' + clientCodeExpr + '/workOrders',
    component: WorkOrderManager,
    props: true,
    meta: {
      animationKey: workOrderKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1009100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1009120")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },

  {
    name: rt.WORK_ORDER_EDITOR,
    path: '/' + clientCodeExpr + '/workOrders/:workOrderNumber([a-zA-Z0-9]+)',
    component: WorkOrderEditor,
    props: true,
    meta: {
      animationKey: workOrderKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1009100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1009120")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },
] as RouteConfig[];