import Address from "../core/Address";
import { CustomerNameOnly } from "../customers/CustomerNameOnly";
import EntityActionType from "../core/entities/EntityActionType";
import PrivateEntity from "../core/entities/PrivateEntity";
import QuoteListModel from "../quotes/QuoteListModel";
import {WorkOrderCategoryListModel} from "../workOrderCategories/WorkOrderCategoryListModel";
import { WorkOrderSection } from "./WorkOrderSection";
import { WorkOrderStep } from "./WorkOrderStep";

export class WorkOrder extends PrivateEntity {
  public workOrderNumber = '';
  public customer: CustomerNameOnly = {
    id : "0",
    name : ''
  };
  public quote?: QuoteListModel = new QuoteListModel();
  public category: WorkOrderCategoryListModel = new WorkOrderCategoryListModel();
  public name = '';
  public billAddress: Address = new Address();
  public shipAddress: Address = new Address();
  public poNumber = '';
  public clientInstructions = '';
  public internalNotes = '';
  public targetDate? = ''
  public priority = '';
  public closedBy = '';
  public closedAt?: string;
  public reasonClosed = '';
  public reasonClosedDesc = '';
  public status = '';
  public sections: WorkOrderSection[] = [];
  public steps: WorkOrderStep[] = [];
  public quotedPrice = 0;
  public discountableAmount = 0;
  public primaryDiscountPercent = 0;
  public secondaryDiscountPercent = 0;
  public effectiveDiscountPercent = 0;
  public effectiveDiscountAmt = 0;
  public sectionTotal = 0; //Only used in free-form mode
  public sectionTotalEligibleForDiscount = 0; //Only used in free-form mode
  public salesValue = 0;
  public sortIndex = 0;
  
  public readyActions: EntityActionType[] = [];
}