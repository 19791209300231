import { DashboardWidgetSize } from "./DashboardWidgetSize";
import { ExtendedVue } from "vue/types/vue";
import Vue from "vue";

export class DashboardWidget {
  constructor(
    public size: DashboardWidgetSize,
    public order: number,
    public title: string,
    public component: ExtendedVue<Vue, unknown, unknown, unknown, Record<never, unknown>, unknown>,
  ) {}

  public get vueCols(): number {
    if (this.size == DashboardWidgetSize.Full) {
      return 12;
    } else if (this.size == DashboardWidgetSize.Half) {
      return 6;
    } else if (this.size == DashboardWidgetSize.Quarter) {
      return 3;
    } else {
      return 1;
    }
  }

  public get vueColsMd(): number {
    if (this.size == DashboardWidgetSize.Full) {
      return 12;
    } else if (this.size == DashboardWidgetSize.Half) {
      return 6;
    } else if (this.size == DashboardWidgetSize.Quarter) {
      return 3;
    } else {
      return 1;
    }
  }

  public get vueColsSm(): number {
    if (this.size == DashboardWidgetSize.Full) {
      return 12;
    } else if (this.size == DashboardWidgetSize.Half) {
      return 12;
    } else if (this.size == DashboardWidgetSize.Quarter) {
      return 6;
    } else {
      return 1;
    }
  }

  public get vueColsXs(): number {
    if (this.size == DashboardWidgetSize.Full) {
      return 12;
    } else if (this.size == DashboardWidgetSize.Half) {
      return 12;
    } else if (this.size == DashboardWidgetSize.Quarter) {
      return 12;
    } else {
      return 1;
    }
  }
}