/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from './routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { AssignmentRoutes } from "./routes/assignments";
import { BoardRoutes } from "./routes/boards";
import { clientCodeExpr } from './routes/_typesAndConstants'
import { CustomerRoutes } from "./routes/customers";
import Dashboard from '@/components/core/Dashboard.vue'
import Error from '@/components/core/Error.vue'
import { FileRoutes } from "./routes/files";
import { InsightAuth } from 'insight-auth';
import { ItemRoutes } from "./routes/items";
import { QuoteRoutes } from "./routes/quotes";
import { SettingsRoutes } from './routes/settings'
import { TicketRoutes } from "./routes/tickets";
import { VueConstructor } from 'vue/types/umd'
import { WorkOrderRoutes } from './routes/workOrders'

const ContactManager = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "contact" */ '../components/contacts/ContactManager.vue')
  .then(m => m.default) as any
const MessageManager = (): Promise<VueConstructor<any>> =>
  import(/* webpackChunkName: "message" */ '../components/messages/MessageManager.vue')
  .then(m => m.default) as any


const contactKey = 'contacts:clientCode'
const dashboardKey = 'dashboard:clientCode'
const messageKey = 'messages:clientCode'

const routes: RouteConfig[] = [
  {
    name: rt.DASHBOARD,
    path: '/' + clientCodeExpr + '?',
    component: Dashboard,
    meta: {
      animationKey: dashboardKey
    }
  },

  ...AssignmentRoutes,
  ...BoardRoutes,
  ...CustomerRoutes,
  ...FileRoutes,
  ...ItemRoutes,
  ...QuoteRoutes,
  ...SettingsRoutes,
  ...TicketRoutes,
  ...WorkOrderRoutes,

  {
    name: rt.CONTACTS,
    path: '/' + clientCodeExpr + '/contacts',
    component: ContactManager,
    props: true,
    meta: {
      animationKey: contactKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        if (params.id) {
          return (auth.hasPrivilegeAnyClient("1008300") ||
            auth.hasPrivilegeForClient(params.clientCode, "1008110")) &&
            auth.hasClientAccess(params.clientCode)
        } else {
          return (auth.hasPrivilegeAnyClient("1008100") ||
            auth.hasPrivilegeForClient(params.clientCode, "1008110")) &&
            auth.hasClientAccess(params.clientCode)
        }
      }
    }
  },

  {
    name: rt.MESSAGES,
    path: '/' + clientCodeExpr + '/messages',
    component: MessageManager,
    props: true,
    meta: {
      animationKey: messageKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1005000") ||
          auth.hasPrivilegeForClient(params.clientCode, "1005120")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },

  {
    path: '*',
    component: Error,
    props: { httpErrorCode: '404' }
  }
] as RouteConfig[]

export default routes
