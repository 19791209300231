import * as gt from '../../store/getterNames'
import { Ticket, TicketListModel } from '@/models/tickets/Ticket'
import { getIconNameForEntityType } from '@/util/iconHelper'
import { NavTileFactory } from '@/models/core/NavTiles'

const getTicketObject: NavTileFactory = (client, getters, auth) => {
  const ticketUrl = '/' + client + '/tickets'
  const ticketsAreLoaded = (getters[gt.TICKETS_FOR_CURRENT_CLIENT] as TicketListModel[]).length > 0
  const openTickets: Ticket[] = getters[gt.TICKETS_OPEN_FOR_CURRENT_CLIENT] as Ticket[]
  const ticketChildren = []

  if (ticketsAreLoaded) {
    ticketChildren.push({ name: 'Ticket List', url: ticketUrl })
  }

  openTickets.forEach(ticket => {
    ticketChildren.push({ name: ticket.ticketNumber, url: ticketUrl + '/' + ticket.ticketNumber })
  })

  return {
    title: 'Support Tickets',
    url: ticketUrl,
    icon: getIconNameForEntityType("ticket"),
    isExact: false,
    showBadge: ticketChildren.length > 1,
    badgeCount: ticketChildren.length,
    enableMenu: ticketChildren.length > 1,
    loaded: ticketsAreLoaded,
    children: ticketChildren,
    enableTile: auth.hasFeatureForClient(client, "1003000")
  }
}

export default getTicketObject;
