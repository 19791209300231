
import * as ac from '@/store/actionNames'
import * as gt from '@/store/getterNames'
import { CUSTOMER_ADD_REFERRER, CUSTOMER_DROP_REFERRER } from '@/store/mutationNames'
import Client from '@/models/core/Client'
import { CUSTOMER_CHOOSER_REF } from '@/store/refNames'
import CustomerListModel from '../../models/customers/CustomerListModel'
import ScrollBox from '../common/ScrollBox.vue'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexFetchRequest from '@/models/core/vuex/VuexFetchRequest'

let idCounter = 0;

export default Vue.extend({
  components: {
    ScrollBox,
  },

  props: {
    preselectedCustomer: {
      type: String,
      default: "0",
    },

    show: Boolean,
  },

  data() {
    return {
      activeCustomer: "-1",
      chooserId: `customerChooser${idCounter++}`,
      customerScrollToSelector: '',
      search: '',
      selectedCustomer: null as CustomerListModel | null,
    }
  },

  computed: {
    currentClient(): Client {
      return this.$store.getters[gt.CORE_CLIENT_CURRENT];
    },

    customers(): CustomerListModel[] {
      return this.$store.getters[gt.CUSTOMERS_LISTED_FOR_REF](this.currentClient.code, CUSTOMER_CHOOSER_REF);
    },

    filteredCustomers(): CustomerListModel[] {
      const expr = new RegExp(this.search, 'i');

      return this.customers.filter(
        c => expr.test(c.name) ||
        expr.test(c.billAddressCity) ||
        expr.test(c.billAddressState)
      ).sort((a,b) => {
        const a1 = a.name.toLowerCase();
        const b1 = b.name.toLowerCase();
        if ( a1 > b1)
          return 1;
        else if (a1<b1)
          return -1;
        else 
          return 0;
      });
    },

    referrerExists(): boolean {
      return this.$store.getters[gt.CUSTOMER_REFERRER_EXISTS](CUSTOMER_CHOOSER_REF);
    },
  },

  watch: {
    show: {
      handler(): void {
        if (this.show) {
          this.scrollToElement(this.preselectedCustomer);

          setTimeout(() => {
            const searchBox = document.querySelector(`#${this.chooserId} input`) as HTMLInputElement;

            if (searchBox) {
              searchBox.focus();
              searchBox.select();
            }
          }, 300)
        }
      },
      immediate: true
    }
  },

  created(): void {
    const loadCustomers = !this.referrerExists;

    this.configureEntityReferrer();

    if (loadCustomers) {
      const req: VuexFetchRequest = {
        refName: CUSTOMER_CHOOSER_REF,
      }
      
      this.$store.dispatch(ac.CUSTOMER_FETCH, req);
    }
  },

  methods: {
    configureEntityReferrer(): void {
      const referrer = new VuexEntityReferer(
        this.currentClient.code,
        CUSTOMER_CHOOSER_REF,
        (entity, mode) =>  {
          if (mode == 'entity') {
            return 'noChange';

          } else {
            return 'yes'
          }
        },
        true
      );

      this.$store.commit(CUSTOMER_ADD_REFERRER, referrer)
    },
    
    handleClose(): void {
      this.selectedCustomer = null;
      this.search = '';
      this.customerScrollToSelector = '';

      this.$emit('update:show', false);
    },

    handleRowClick(customer: CustomerListModel): void {
      this.$emit('customer-selected', customer);

      this.handleClose();
    },

    scrollToElement(id: string): void {
      this.activeCustomer = id;

      setTimeout(() => {
        this.customerScrollToSelector = '.v-list-item--active';
      }, 300)
    },

    unload(): void {
      this.$store.commit(CUSTOMER_DROP_REFERRER, {client: this.currentClient.code, name: CUSTOMER_CHOOSER_REF})
    },
  },
})
