
import AddressAutosuggestField from './AddressAutosuggestField.vue'
import { AddressListItem } from '@/models/contacts/AddressListItem'
import Vue from 'vue'

export default Vue.extend({
  components: {
    AddressAutosuggestField,
  },

  props: {
    show: {
      type: Boolean,
    },
  },

  methods: {
    handleSelection(address: AddressListItem|null): void {
      if (address) {
        this.$emit('address-selected', address);
      }

      this.$emit('update:show', false);
    },
  }
})
