
import { Assignment, AssignmentListModel, getActionIconForStatus, getActionTextForStatus } from '@/models/assignments/Assignment';
import api from '@/util/api';
import { ASSIGNMENT_STORE_ENTITY_FOR_REF } from '@/store/mutationNames';
import EntityActionResult from '@/models/core/api/EntityActionResult';
import IconWithTooltip from '../common/IconWithTooltip.vue'
import InsightUser from '@/models/core/InsightUser';
import notifications from '@/util/notifications';
import popup from '@/util/popup';
import { PropValidator } from 'vue/types/options';
import Vue from 'vue'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';

export default Vue.extend({
  components: {
    IconWithTooltip,
  },

  props: {
    assignment: {
      type: Object,
      default: null,
    } as PropValidator<AssignmentListModel>,

    canStartUnassigned: Boolean,

    chooseUser: {
      type: Function,
      default: null,
    } as PropValidator<() => Promise<InsightUser|null>>,

    clientCode: {
      type: String,
      default: null,
    },

    onlyAssign: Boolean,

    refName: {
      type: String,
      default: '',
    },

    showChecklistForFinish: {
      type: Function,
      default: null,
    } as PropValidator<(assignment: AssignmentListModel) => Promise<boolean>>,
  },

  data() {
    return {
      executingAction: false,
    }
  },

  computed: {
    assignmentActionIcon(): string {
      return getActionIconForStatus(this.assignment.status);
    },

    assignmentActionText(): string {
      return getActionTextForStatus(this.assignment.status);
    },
  },

  methods: {
    async assignAssignment(): Promise<void> {
      const user = await this.chooseUser();

      if (user) {
        await this.executeAssignmentAction("Assign", user);
      }
    },

    async executeAssignmentAction(assignmentActionName: string, assignmentActionParams: unknown): Promise<void> {
      this.executingAction  = true;

      try {
        const response = await api.create(
          `${this.clientCode}/assignments/${this.assignment.id}/actions/${assignmentActionName}`,
          assignmentActionParams
        );

        if (response) {
          if (response.error) {
            notifications.fail(this.$store, `Failed to execute assignment action. Message: ${response.data || response.errorCause || response || "(No Message)"}`);
          } else {
            const result = response.data as EntityActionResult<Assignment>;

            const data: VuexEntityStorageRequest<Assignment> = {
              entity: result.entity,
              refName: this.refName,
              mode: VuexEntityStorageRequestMode.ListModelOnly,
            }

            this.$store.commit(ASSIGNMENT_STORE_ENTITY_FOR_REF, data)
          }
        }
      } catch(e) {
        notifications.fail(this.$store, 'Requested action failed.')
      }

      this.executingAction = false;
    },

    finishAssignment(): void {
      if (
        this.assignment.status == "In Progress" ||
        (this.assignment.status == "Assigned" &&
        this.assignment.assignmentType == "One Click")
      ) {
        this.executeAssignmentAction("Finish", null);
      } else {
        console.warn("finishAssignment was called for an invalid assignment status!");
      }
    },

    async handleAssignmentActionClick(): Promise<void> {
      if (this.assignment.status == 'New') {
        this.assignAssignment();

      } else if (this.assignment.status == 'Assigned' && this.assignment.assignmentType == "Start/Pause/Finish") {
        this.startResumeAssignment();

      } else if (this.assignment.status == 'In Progress' || (this.assignment.status == 'Assigned' && this.assignment.assignmentType == "One Click")) {

        if (this.assignment.checklistComplete) {
          this.finishAssignment();
        } else {
          const checklistComplete = await this.showChecklistForFinish(this.assignment);

          if (checklistComplete) {
            this.finishAssignment();
          }
        }

      } else if (this.assignment.status == 'Paused') {
        this.startResumeAssignment();

      } else {
        notifications.warn(this.$store, "Oops, this shouldn't have been allowed!");
      }
    },

    pauseAssignment(): void {
      if (this.assignment.status == "In Progress") {
        this.executeAssignmentAction("Pause", null);
      } else {
        console.warn("pauseAssignment was called for an invalid assignment status!");
      }
    },

    async reassignAssignment(): Promise<void> {
      const user = await this.chooseUser();

      if (user) {
        await this.executeAssignmentAction("Reassign", user);
      }
    },

    async skipAssignment(): Promise<void> {
      if (this.assignment.status != "Done" && this.assignment.status != "Skipped") {
        if (await popup.confirm(this.$store, "Confirm Skip", "Are you sure you want to skip this assignment?")) {
          this.executeAssignmentAction("Skip", null);
        }
      } else {
        console.warn("skipAssignment was called for an invalid assignment status!");
      }
    },

    startResumeAssignment(): void {
      if (this.assignment.status == "Assigned" || (this.assignment.status == "New" && this.canStartUnassigned)) {
        this.executeAssignmentAction("Start", null);
      } else if (this.assignment.status == "Paused") {
        this.executeAssignmentAction("Resume", null);
      } else {
        console.warn("startResumeAssignment was called for an invalid assignment status!");
      }
    },
  }
})
