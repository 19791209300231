export class EntityEditorChangeValueRequest<T> {
  constructor(
    public entityId: string,
    public value: T,
  ) {}
}

export class EntityEditorCounterUpdate extends EntityEditorChangeValueRequest<number> {
  constructor(
    entityId: string,
    public counterName: string,
    value: number,
  ) {
    super(entityId, value);
  }
}