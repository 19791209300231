
import { DASHBOARD, SETTINGS_IMPORT, SETTINGS_IMPORT_JOBS, WORK_ORDER_KANBAN_CURRENT } from '@/router/routeNames'
import Client from '@/models/core/Client'
import { CORE_SWITCH_CLIENT } from '@/store/actionNames'
import InsightClientAvatar from './InsightClientAvatar.vue'
import { Location } from 'vue-router'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  components: {
    InsightClientAvatar,
  },

  props: {
    clients: {
      type: Array,
      default: null,
    } as PropValidator<Client[]>,

    minified: Boolean,

    selectedIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      colors: [
        'cyan darken-4',
        'green darken-4',
        'red darken-4',
        'purple darken-3',
        'orange darken-4',
        'brown darken-2',
        'grey darken-2'
      ],

      sortedClients: [] as Client[],
    }
  },

  computed: {
    currentClient(): Client {
      return this.clients[this.selectedIndex]
    },

    currentClientBackground(): string {
      return this.colors[this.selectedIndex % this.colors.length]
    },
  },

  watch: {
    clients: {
      handler(): void {
        this.populateSortedClients()
      },
      deep: true,
    }
  },

  created(): void {
    this.populateSortedClients()
  },

  methods: {
    avitarBackground(client: Client | null): string {
      const safeClient = client || new Client();

      if (safeClient.logo > '') {
        return 'white'
      } else {
        const idx = this.clients.findIndex(c => { return c.code == safeClient.code })

        if (idx == -1) {
          return 'transparent'
        } else {
          return this.colors[idx % this.colors.length]
        }
      }
    },

    menuItemUrl(client: Client): string | Location {
      const route = this.$route

      const paramNames = Object.keys(route.params);
      const params = {clientCode: client.code};

      if (paramNames.length == 1 && paramNames[0] == 'clientCode') {
        return { name: route.name || undefined, params}
      } else if ((route.name ?? "").endsWith("Editor")) {
        return { name: route.name?.replace("Editor", "s"), params}
      } else if ((route.name ?? "").endsWith("Viewer")) {
        return { name: route.name?.replace("Viewer", "s"), params}
      } else if (route.name == WORK_ORDER_KANBAN_CURRENT) {
        return {
          name: WORK_ORDER_KANBAN_CURRENT,
          params: {
            clientCode: client.code,
            mode: route.params.mode ?? 'current'
          }
        }
      } else if (route.name?.startsWith(SETTINGS_IMPORT)) {
        return {
          name: SETTINGS_IMPORT_JOBS,
          params: {
            clientCode: client.code,
            importType: route.params.importType ?? 'items'
          }
        }
      } else {
        return { name: DASHBOARD, params}
      }
    },

    async handleClientClick(client: Client): Promise<void> {
      await this.$store.dispatch(CORE_SWITCH_CLIENT, {client, router: this.$router, currentRoute: this.$route});
    },
    
    populateSortedClients(): void {
      this.sortedClients.length = 0

      this.sortedClients.push(...JSON.parse(JSON.stringify(this.clients)).sort((a: Client, b: Client): number => {
        if (a.name > b.name) {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else {
          return 0
        }
      }))
    }
  },
})
