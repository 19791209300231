export const getColorForType = (type: string): string => {
  if (type == 'Prep') {
    return 'purple'
  } else if (type == 'Work') {
    return 'primary'
  } else if (type == 'Ship') {
    return 'yellow'
  } else if (type == 'Install') {
    return 'info'
  } else if (type == 'Close') {
    return 'success'
  } else {
    return ''
  }
}

export const typeColors: Record<string, string> = {
  'Prep': 'purple',
  'Work': 'primary',
  'Ship': 'yellow',
  'Install': 'info',
  'Close': 'success',
}

export const getForegroundColorForType = (status: string): string => {
  if (status == 'Ship') { //light blue or yellow
    return "black";
  } else {
    return "white";
  }
}

export const typeForegroundColors: Record<string, string> = {
  'Prep': 'white',
  'Work': 'white',
  'Ship': 'black',
  'Install': 'white',
  'Close': 'white',
}
