import * as gt from '../../store/getterNames'
import { NavTileFactory } from '@/models/core/NavTiles'
import Quote from '@/models/quotes/Quote'
import QuoteListModel from '@/models/quotes/QuoteListModel'

const getQuoteObject: NavTileFactory = (client, getters, auth) => {
  const quoteUrl = '/' + client + '/quotes'
  const quotesAreLoaded = (getters[gt.QUOTES_FOR_CURRENT_CLIENT] as QuoteListModel[]).length > 0
  const openQuotes: Quote[] = getters[gt.QUOTES_OPEN_FOR_CURRENT_CLIENT] as Quote[]
  const quoteChildren = []

  if (quotesAreLoaded) {
    quoteChildren.push({ name: 'Quote List', url: quoteUrl })
  }

  openQuotes.forEach(quote => {
    quoteChildren.push({ name: quote.quoteNumber, url: quoteUrl + '/' + quote.quoteNumber })
  })

  return {
    title: 'Quotes',
    url: quoteUrl,
    icon: 'mdi-calculator',
    isExact: false,
    showBadge: quoteChildren.length > 1,
    badgeCount: quoteChildren.length,
    enableMenu: quoteChildren.length > 1,
    loaded: quotesAreLoaded,
    children: quoteChildren,
    enableTile: auth.hasFeatureForClient(client, "1001000")
  }
}

export default getQuoteObject;
