
import { getColorForStatus, getForegroundColorForStatus, getIconForStatus } from '@/models/workOrders/WorkOrderStatusColors';

export default {
  props: {
    small: Boolean,

    value: {
      type: String,
      default: '',
    }
  },

  methods: {
    getStatusColor(status: string): string {
      return getColorForStatus(status)
    },

    getStatusForeground(status: string): string {
      return getForegroundColorForStatus(status);
    },

    getStatusIcon(status: string): string {
      return getIconForStatus(status);
    }
  }
}
