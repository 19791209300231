import * as gt from '../../store/getterNames'
import { Assignment, AssignmentListModel } from '@/models/assignments/Assignment'
import { getIconNameForEntityType } from '@/util/iconHelper'
import { NavTileFactory } from '@/models/core/NavTiles'

const getAssignmentObject: NavTileFactory = (client, getters, auth) => {
  const assignmentUrl = '/' + client + '/assignments'
  const assignmentsAreLoaded = (getters[gt.ASSIGNMENTS_FOR_CURRENT_CLIENT] as AssignmentListModel[]).length > 0
  const openAssignments: Assignment[] = getters[gt.ASSIGNMENTS_OPEN_FOR_CURRENT_CLIENT] as Assignment[]
  const assignmentChildren = []

  if (assignmentsAreLoaded) {
    assignmentChildren.push({ name: 'Assignment List', url: assignmentUrl })
  }

  openAssignments.forEach(assignment => {
    assignmentChildren.push({ name: `${assignment.associatedEntity?.description ?? ""}: ${assignment.template.name}`, url: assignmentUrl + '/' + assignment.id })
  })

  return {
    title: 'Assignments',
    url: assignmentUrl,
    icon: getIconNameForEntityType("assignment"),
    isExact: false,
    showBadge: assignmentChildren.length > 1,
    badgeCount: assignmentChildren.length,
    enableMenu: assignmentChildren.length > 1,
    loaded: assignmentsAreLoaded,
    children: assignmentChildren,
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }
}

export default getAssignmentObject;
