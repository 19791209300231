export const getColorForQuoteStatus = (status: string): string => {
  if (status == 'New') {
    return 'pink lighten-4'
  } else if (status == 'In Progress' || status == 'In Revision') {
    return 'primary'
  } else if (status == 'Prepared' || status == 'Revised') {
    return 'purple'
  } else if (status == 'Approved' || status == 'Re-approved') {
    return 'yellow'
  } else if (status == 'Submitted' || status == 'Re-submitted') {
    return 'info'
  } else if (status == 'Revision Requested') {
    return 'orange'
  } else if (status == 'Discarded') {
    return 'black'
  } else if (status == 'Accepted') {
    return 'success'
  } else if (status == 'Declined') {
    return 'error'
  } else {
    return ''
  }
}

export const getForegroundColorForStatus = (status: string): string => {
  if (status == 'New' || status == 'Approved' || status == 'Re-approved') {
    return "black";
  } else {
    return "white";
  }
}