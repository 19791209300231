
import { CORE_CLIENT_CURRENT, CORE_GET_CURRENT_USER } from '@/store/getterNames'
import AssignmentWidget from '../assignments/AssignmentWidget.vue'
import CommentWidget from '../comments/CommentWidget.vue'
import { CORE_SET_PAGE_TITLE } from '@/store/mutationNames'
import { DashboardLayout } from '@/models/dashboard/DashboardLayout'
import { DashboardWidget } from '@/models/dashboard/DashboardWidget'
import { DashboardWidgetSize } from '@/models/dashboard/DashboardWidgetSize'
import EstimatorPerformanceChart from '../quotes/EstimatorPerformanceChart.vue'
import InsightUser from '@/models/core/InsightUser'
import MessageWidget from '../messages/MessageWidget.vue'
import Vue from 'vue'

export default Vue.extend({
  computed: {
    clientCode(): string {
      return (this.$store.getters[CORE_CLIENT_CURRENT] || {}).code ?? "";
    },

    layout(): DashboardLayout {
      const widgets = [] as DashboardWidget[];

      if (this.$auth.hasPrivilegeForClient(this.clientCode, "1013110")) {
        widgets.push(new DashboardWidget(DashboardWidgetSize.Half, 0, "My Assignments", AssignmentWidget))
      }

      if (this.$auth.hasPrivilegeForClient(this.clientCode, "1007110")) {
        widgets.push(new DashboardWidget(DashboardWidgetSize.Half, 1, "Comments", CommentWidget))
      }

      if (this.$auth.hasPrivilegeForClient(this.clientCode, "1005120")) {
        widgets.push(new DashboardWidget(DashboardWidgetSize.Half, 2, "Inbox", MessageWidget));
      }

      if (this.$auth.hasPrivilegeForClient(this.clientCode, "1003110")) {
        widgets.push(new DashboardWidget(DashboardWidgetSize.Half, 3, "Estimator Performance", EstimatorPerformanceChart));
      }

      return DashboardLayout.CreateLayout(widgets);
    },

    username(): string {
      return (this.$store.getters[CORE_GET_CURRENT_USER] as InsightUser).name
    }
  },

  created(): void {
    this.$store.commit(CORE_SET_PAGE_TITLE, 'Dashboard')
  }
})
