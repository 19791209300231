import { Comment } from "./Comment"
import { Spec } from "../core/spec/Spec";

export class UnreadMentionForUserSpec extends Spec<Comment> {
  constructor(userId: string) {
    super();
    this._userId = userId;
  }

  private _userId: string;

  public isSatisfiedBy(entity: Comment): boolean {
    if (entity.mentions) {
      return (entity.mentions.findIndex(x => (x.user.id == this._userId) && x.unread) > -1);
    } else {
      return false;
    }
  }
}