/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from '../routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { clientCodeExpr } from './_typesAndConstants'
import { InsightAuth } from 'insight-auth'
import { VueConstructor } from 'vue'

const ItemManager = (): Promise<VueConstructor<any>> =>
  import(/* webpackChunkName: "item" */ '@/components/items/ItemManager.vue')
  .then(m => m.default) as any

const ItemEditor = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "item" */ '@/components/items/ItemEditor.vue')
  .then(m => m.default) as any

const itemKey = 'items:clientCode'

export const ItemRoutes = [
  {
    name: rt.ITEMS,
    path: '/' + clientCodeExpr + '/items/',
    component: ItemManager,
    props: true,
    meta: {
      animationKey: itemKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1002100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1002110")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },


  {
    name: rt.ITEM_EDITOR,
    path: '/' + clientCodeExpr + '/items/:id([0-9]+)',
    component: ItemEditor,
    props: true,
    meta: {
      animationKey: itemKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1002100") ||
            auth.hasPrivilegeForClient(params.clientCode, "1002110")) &&
            auth.hasClientAccess(params.clientCode)
      }
    }
  },
] as RouteConfig[];