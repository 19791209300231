
import AddressChooser from '../contacts/AddressChooser.vue'
import { AddressListItem } from '@/models/contacts/AddressListItem'
import CustomerChooser from '../customers/CustomerChooser.vue'
import CustomerListModel from '@/models/customers/CustomerListModel'
import { EditEventMixin } from '../common/EditEventMixin'
import { EmailContact } from '@/models/core/EmailContact'
import EmailContactField from '../common/forms/EmailContactField.vue'
import NameField from '../common/forms/NameField.vue'
import { PropValidator } from 'vue/types/options'
import Quote from '@/models/quotes/Quote'
import ReadOnlyField from '../common/forms/ReadOnlyField.vue'

export default EditEventMixin.extend({

  components: {
    AddressChooser,
    CustomerChooser,
    EmailContactField,
    NameField,
    ReadOnlyField,
  },

  props: {
    entity: {
      type: Object,
      default: (): Quote => new Quote()
    } as PropValidator<Quote>
  },

  data() {
    return {
      addressChooserRep: 'sales' as 'sales'|'customer',
      showAddressChooser: false,
      showCustomerChooser: false,
    }
  },

  computed: {
    customerName(): string {
      return (this.entity.customer || {}).name || '';
    },
  },

  methods: {
    handleCustomerFieldClick(): void {
      this.showCustomerChooser = true;
    },

    handleCustomerSelected(customer: CustomerListModel): void {
      this.emitEditEventForField("customer", {
        id: customer.id,
        name: customer.name,
      });
    },
    handleRepFieldClick(rep: 'sales'|'customer'): void {
      this.addressChooserRep = rep;
      this.showAddressChooser = true;
    },

    handleRepSelected(rep: AddressListItem): void {
      const ctc = new EmailContact();
      ctc.name = rep.displayName;
      ctc.address = rep.address;

      this.emitEditEventForField(`${this.addressChooserRep}Rep`, ctc);
    },
  }
})
