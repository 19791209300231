import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue, SearchUIRangeValue } from "@/models/core/searchQueries/SearchUIOption";
import reformatDate from "@/util/reformatDate";
import { UpdatableEntitySearchFieldDefs } from "@/models/core/searchQueries/UpdatableEntitySearchFieldDefs";

export const WorkOrderSearchFieldDefs = [
  ...UpdatableEntitySearchFieldDefs,

  {fieldName: "workOrderNumber", displayName: "Work Order Number", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "categoryId", displayName: "Category", type: SearchUIFieldType.WorkOrderCategorySet},
  {fieldName: "customerName", displayName: "Customer Name", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "name", displayName: "Name", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "targetDate", displayName: "Target Date", type: SearchUIFieldType.DateTime},

  {fieldName: "closedAt", displayName: "Closed On", type: SearchUIFieldType.DateTime, notNoun: true},
  {fieldName: "closedBy", displayName: "Closed By", type: SearchUIFieldType.UserSet, notNoun: true},

  {fieldName: "quotedPrice", displayName: "Quoted Price", type: SearchUIFieldType.NumberRange},
  {fieldName: "discountableAmount", displayName: "Discountable Amount", type: SearchUIFieldType.NumberRange},
  {fieldName: "primaryDiscountPercent", displayName: "Primary Discount %", type: SearchUIFieldType.PercentRange},
  {fieldName: "secondaryDiscountPercent", displayName: "Secondary Discount %", type: SearchUIFieldType.PercentRange},
  {fieldName: "effectiveDiscountPercent", displayName: "Effective Discount %", type: SearchUIFieldType.PercentRange},
  {fieldName: "effectiveDiscountAmt", displayName: "Effective Discount $", type: SearchUIFieldType.NumberRange},
  {fieldName: "salesValue", displayName: "Sales Value", type: SearchUIFieldType.NumberRange},

  {
    fieldName: "status",
    displayName: "Status",
    type: SearchUIFieldType.StringSet,
    options: [
      "New", "Preparing", "Prepared", "In Progress", "Complete", "Shipping",
      "Shipped", "Installing", "Installed", "Closing", "Closed", "Canceled",
      "On Hold"
    ]
  },

  {
    fieldName: "priority",
    displayName: "Priority",
    type: SearchUIFieldType.StringSet,
    options: ["Normal", "Rush"]
  },
] as SearchUIFieldDef[];

const thirtyDaysAgoDate = new Date(new Date().getTime() - (1000*3600*24*30));

const thirtyDaysAgo = reformatDate(thirtyDaysAgoDate, 'yyyy-mm-dd');

export const DefaultWorkOrderQuery = [
  new SearchUIFieldValue(
    'createdAt',
    SearchUIFieldType.DateTime,
    new SearchUIRangeValue<string>('gte', thirtyDaysAgo)
  )
] as SearchUIFieldValue[]

export const WorkOrderStatusReportQuery = [
  new SearchUIFieldValue(
    'status',
    SearchUIFieldType.StringSet,
    [
      "New", "Preparing", "Prepared", "In Progress", "Complete", "Shipping",
      "Shipped", "Installing", "Installed", "Closing", "On Hold"
    ]
  )
] as SearchUIFieldValue[]

const now = new Date();
const startOfMonthDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);

const startOfMonth = reformatDate(startOfMonthDate, 'yyyy-mm-dd');

export const WorkOrderRevenueSummaryQuery = [
  new SearchUIFieldValue(
    'targetDate',
    SearchUIFieldType.DateTime,
    new SearchUIRangeValue<string>('gte', startOfMonth)
  )
] as SearchUIFieldValue[]