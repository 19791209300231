import assignmentTile from './assignments'
import contactTile from './contacts'
import customerTile from './customers'
import dashboardTile from './dashboard'
import fileTile from './files'
import itemTile from './items'
import messagesTile from './messages'
import quotesTile from './quotes'
import settingsTile from './settings'
import ticketsTile from './tickets'
import workOrderKanbanTile from './workOrderKanban'
import workOrderTile from './workOrders'

export default [
    dashboardTile,
    assignmentTile,
    messagesTile,
    ticketsTile,
    quotesTile,
    workOrderTile,
    workOrderKanbanTile,
    customerTile,
    contactTile,
    itemTile,
    fileTile,
    settingsTile,
]