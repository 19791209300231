
import * as gt from '../../store/getterNames'
import * as mt from '../../store/mutationNames'
import AppSidebar from './AppSidebar.vue'
import Client from '../../models/core/Client'
import DocChooser from './DocChooserWrapper.vue'
import DocReasonToCloseChooser from './DocReasonToCloseChooser.vue'
import Notifier from './Notifier.vue'
import Popups from './Popups.vue'
import RootState from '@/store/RootState'
import Vue from 'vue'

export default Vue.extend({
  components: {
    AppSidebar,
    DocChooser,
    DocReasonToCloseChooser,
    Notifier,
    Popups,
  },

  computed: {
    animationKey(): string|false {
      const routeConfigWithKey = this.$route.matched.find(x => x.meta?.animationKey != undefined);

      const template = routeConfigWithKey?.meta?.animationKey as string|undefined;

      if (!template) return false

      const parts = template.split(':')

      for (let i = 1; i < parts.length; i++) {
        parts[i] = this.$route.params[parts[i]] || 'undefined'
      }

      return parts.join('_')
    },

    currentClient(): Client {
      return this.$store.getters[gt.CORE_CLIENT_CURRENT] || {}
    },

    isLoaded(): string {
      return this.$store.state.appDataLoaded
    },

    isLoggedIn(): boolean {
      return (this.$store.state as RootState).loggedIn
    },

    isSubscribedToWerk(): boolean {
      const client = this.currentClient

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const loaded = this.isLoaded; //This forces Vue to refresh the isSubscribedToWerk property when the data loads.

      if (client.code && (this.$store.state as RootState).loggedIn) {
        return this.$auth.hasFeatureForClient(client.code, "1000000")
      } else {
        return true
      }
    },

    loadingClient(): boolean {
      return (this.$store.state as RootState).loadingClientData
    },

    pageTitle(): string {
      const store = this.$store
      let titleParts = []

      titleParts.push(store.state.pageTitle)
      titleParts.push((store.getters[gt.CORE_CLIENT_CURRENT] || {}).name)
      titleParts.push('Werk365')

      titleParts = titleParts.filter(p => { return p > '' })

      return titleParts.join(' - ')
    },

    routeKey(): string {
      // If the route has an animation key, use that. Otherwise, use the full path.
      return this.animationKey || this.$route.fullPath
    },

    showLoadErrorUI(): boolean {
      return this.isLoggedIn && this.isSubscribedToWerk && this.isLoaded == 'failed'
    },

    showLoadingUI(): boolean {
      return this.isLoggedIn && this.isSubscribedToWerk && this.isLoaded == 'no'
    },

    showMainContentArea(): boolean {
      return this.isLoggedIn && this.isLoaded == 'yes' && this.isSubscribedToWerk
    },

    showNav(): boolean {
      return this.isLoggedIn && this.isLoaded == 'yes' &&
            !(this.$route.meta || {}).hideNav
    },

    showNotSubscribedUI(): boolean {
      return this.isLoggedIn && this.isLoaded == 'yes' && !this.isSubscribedToWerk
    },

    showRedirectText(): boolean {
      return !this.isLoggedIn
    },
  },

  watch: {
    pageTitle(): void {
      document.title = this.pageTitle
    },

    showNotSubscribedUI(): void {
      if (this.showNotSubscribedUI) {
        this.$store.commit(mt.CORE_SET_PAGE_TITLE, 'NOT SUBSCRIBED')
      }
    }
  },
});
