
import { getColorNameForEntityType, getIconNameForEntityType } from '@/util/iconHelper';
import EntityLink from '@/models/core/entities/EntityLink';
import { handleEvent } from '@/util/FunctionalComponentEventDispatcher';
import pluralize from 'pluralize';
import { PropValidator } from 'vue/types/options';

export default {
  props: {
    allowUnlink: Boolean,

    clientCode: {
      type: String,
      default: '',
    },

    link: {
      type: Object,
      default: null,
    } as PropValidator<EntityLink>,

    loading: Boolean,
  },

  methods: {
    getColorForLink(targetType: string): string {
      return getColorNameForEntityType(targetType);
    },

    getIconForLink(targetType: string): string {
      return getIconNameForEntityType(targetType);
    },

    getUrlForLink(link: EntityLink, clientCode: string): string {
      const pluralEntity = pluralize(link.type.toLowerCase());
      const useDescriptionInUrl = link.type == 'Quote' || link.type == 'WorkOrder' || link.type == "Ticket";
      const entityIdentifierForUrl = useDescriptionInUrl ? link.description ?? '' : link.entityId;
      return `/${clientCode}/${pluralEntity}/${entityIdentifierForUrl}`
    },

    handleEvent,
  }
}
