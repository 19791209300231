/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from '../routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { clientCodeExpr } from './_typesAndConstants'
import { InsightAuth } from 'insight-auth'
import { VueConstructor } from 'vue'

const AssignmentManager = (): Promise<VueConstructor<any>> =>
  import(/* webpackChunkName: "assignment" */ '@/components/assignments/AssignmentManager.vue')
  .then(m => m.default) as any
const AssignmentEditor = (): Promise<VueConstructor<any>> =>
  import(/* webpackChunkName: "assignment" */ '@/components/assignments/AssignmentEditor.vue')
  .then(m => m.default) as any

const assignmentKey = 'assignments:clientCode';

export const AssignmentRoutes = [
  {
    name: rt.ASSIGNMENTS,
    path: '/' + clientCodeExpr + '/assignments',
    component: AssignmentManager,
    props: true,
    meta: {
      animationKey: assignmentKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1013100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1013110")) &&
          auth.hasClientAccess(params.clientCode) &&
          auth.hasFeatureAccess(params.clientCode, "1000000", "1013000")
      }
    }
  },

  {
    name: rt.ASSIGNMENT_EDITOR,
    path: '/' + clientCodeExpr + '/assignments/:id([0-9]+)',
    component: AssignmentEditor,
    props: true,
    meta: {
      animationKey: assignmentKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1013100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1013110")) &&
          auth.hasClientAccess(params.clientCode) &&
          auth.hasFeatureAccess(params.clientCode, "1000000", "1013000")
      }
    }
  },
] as RouteConfig[];