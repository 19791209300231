import * as gt from '../store/getterNames'
import factories from './tiles'
import { InsightAuth } from 'insight-auth'
import { NavTileOptions } from '@/models/core/NavTiles'
import RootState from '@/store/RootState'
import { Store } from 'vuex'

const generateNavTiles = ({ getters }: Store<RootState>, auth: InsightAuth): NavTileOptions[] => {
  const client = getters[gt.CORE_CLIENT_CURRENT].code

  const tiles: NavTileOptions[] = [];

  factories.forEach(factory => {
    tiles.push(factory(client, getters, auth))
  });

  return tiles;
}

export default generateNavTiles
