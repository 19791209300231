
import { typeColors, typeForegroundColors } from '@/models/workOrders/WorkOrderStepTypeColors';

export default {
  props: {
    small: Boolean,

    value: {
      type: String,
      default: '',
    }
  },

  methods: {
    typeColors,
    typeForegroundColors,
  }
}
