export const ENTITY_MANAGER_REF = "entityManager"
export const ENTITY_MANAGER_AS_CHOOSER_REF = "entityManagerAsChooser"
export const ENTITY_EDITOR_LINK_REF_STEM = "entityEditorLink_"

export const ASSIGNMENT_WIDGET_REF = "assignmentWidgetRef"
export const ASSIGNMENT_KANBAN_REF = 'assignmentKanbanRef'

export const COMMENT_WIDGET_REF = 'commentWidgetRef'

export const CONTACT_MANAGER_PREVIEW_REF = 'contactManagerPreviewRef'
export const CONTACTS_FOR_CUSTOMER_REF_STEM = 'contactsForCustomer_'

export const CUSTOMER_CHOOSER_REF = "customerChooserRef"

export const FILE_CHOOSER_REF = "fileChooser"

export const ITEM_CHOOSER_REF = "itemChooserRef"

export const ITEM_CATEGORY_FIELD_REF = "itemCategoryField"

export const MAILBOX_GLOBAL_REF = "mailboxGlobalRef"
export const MSG_MGR_MAILBOX_REF = "msgMgrMailboxRef"

export const MESSAGE_CHOOSER_REF = 'messageChooserRef'
export const MESSAGE_MANAGER_PREVIEW_REF = 'messageManagerPreview'
export const MESSAGE_WIDGET_REF = 'messageWidgetRef'

export const QUOTE_TO_WORK_ORDER_REF = 'quoteToWorkOrderRef'

export const TICKET_TYPE_FIELD_REF = 'ticketTypeFieldRef'

export const WORK_ORDER_CATEGORY_FIELD_REF = 'workOrderCategoryFieldRef'
export const WORK_ORDER_KANBAN_REF = 'workOrderKanbanRef'
export const WORK_ORDER_NEW_REF = 'workOrderNewRef'
