import dateFormat from 'dateformat'

export const BLANK_DATETIME_OFFSET = "0001-01-01T00:00:00+00:00"
export const BLANK_DATETIME = "0001-01-01T00:00:00";
export const BLANK_BLAME_AT = BLANK_DATETIME;

function format(diff: number, divisor: number, unit: string): string {
  const val = Math.round(Math.abs(diff) / divisor);
  return `${val} ${unit}`;
}

const units = [
  { max: 2760000, value: 60000, name: 'min'}, // max: 46 minutes
  { max: 72000000, value: 3600000, name: 'hr'}, // max: 20 hours
  { max: 518400000, value: 86400000, name: 'day'}, // max: 6 days
  { max: 2419200000, value: 604800000, name: 'wk'}, // max: 28 days
  { max: 28512000000, value: 2592000000, name: 'mo'} // max: 11 months
];

const formatRelative = function ago(date: Date, max?: string): string {
  const diff = Date.now() - date.getTime();

  // less than a minute
  if (Math.abs(diff) < 60000) return 'now';

  for (let i = 0; i < units.length; i++) {
    if (Math.abs(diff) < units[i].max || (max && units[i].name === max)) {
      return format(diff, units[i].value, units[i].name);
    }
  }

  return format(diff, 31536000000, 'yr');
};

export const ensureDateObject = (inputDate: Date|string|undefined|null, ignoreTimeComponent = false): Date|null => {
  let safeDate: Date | null = null;
  const timezoneSuffix = ignoreTimeComponent ? "" : "Z";

  if (inputDate instanceof Date) {
    safeDate = inputDate
  } else if (typeof inputDate === 'string') {
    if (inputDate == '') {
      return null;
    } else if (inputDate == '0001-01-01T00:00:00') {
      return null;
    } else if (inputDate == '0001-01-01T00:00:00+00:00') {
      return null;
    } else if (/^[0-9]{2,4}-[0-9]{1,2}-[0-9]{1,2}$/.test(inputDate)) {
      safeDate = new Date(inputDate + 'T12:00:00' + timezoneSuffix)
    } else if (/^[0-9]{2,4}-[0-9]{1,2}-[0-9]{1,2}T[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}[+-][0-9]{1,2}:?[0-9]{1,2}$/.test(inputDate)) {
      safeDate = new Date(inputDate)
    } else if (inputDate[inputDate.length - 1] == 'Z') {
      safeDate = new Date(inputDate)
    } else {
      safeDate = new Date(inputDate + timezoneSuffix)
    }
  }

  return safeDate;
}

export default function (inputDate: Date|string|undefined|null, formatString: string, relativeTime = false, ignoreTimeComponent=false): string {
  const safeDate = ensureDateObject(inputDate, ignoreTimeComponent);
  
  if (safeDate) {
    if (relativeTime) {
      return formatRelative(safeDate)
      
    } else {
      return dateFormat(safeDate, formatString)
    }
  } else {
    return ''
  }
}

export function getCurrentTimezoneOffsetString(): string {
  const offset = new Date().getTimezoneOffset() * -1; //Make the sign match what the offset string will say

  const magnitude = Math.abs(offset);
  const sign = offset < 0 ? "-" : "+";
  const numHours = Math.round(magnitude / 60);
  const numMinutes = magnitude % 60;

  const hours = `00${numHours}`.slice(-2);
  const minutes = `00${numMinutes}`.slice(-2);

  return `${sign}${hours}:${minutes}`

}
