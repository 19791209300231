
import { maxLength, requiredIf, Validator } from '../../../util/validators'
import { AbstractField } from './AbstractField';
import Address from '../../../models/core/Address';
import { CompositeFieldConflictMixin } from './CompositeFieldConflictMixin'
import { countryList } from "@/models/core/countryList";
import StateField from './StateField.vue'

const maxChars = 255

export default AbstractField.extend({
  components: {
    StateField,
  },

  mixins: [CompositeFieldConflictMixin],

  props: {
    value: {
      type: Object,
      default: (): Address => new Address()
    },
  },

  data() {
    return {
      address: new Address(),
      cityRules: [
        requiredIf(() => this.required, () => this.label),
        maxLength(maxChars, () => this.label)
      ] as Validator[],
      countryList,
      line1Rules: [
        requiredIf(() => this.required, () => this.label),
        maxLength(maxChars, () => this.label)
      ] as Validator[],
      line2Rules: [
        requiredIf(() => this.required, () => this.label),
        maxLength(maxChars, () => this.label)
      ] as Validator[],
      maxChars: maxChars,
    }
  },

  computed: {
    cityLabel(): string {
      return 'City'
    },

    line1Label(): string {
      return 'Line 1';
    },

    line2Label(): string {
      return 'Line 2'
    },
  },

  watch: {
    value: {
      handler(): void {
        this.address.line1 = this.value.line1 || ''
        this.address.line2 = this.value.line2 || ''
        this.address.city = this.value.city || ''
        this.address.state = this.value.state || ''
        this.address.zip = this.value.zip || ''
        this.address.country = this.value.country || ''
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    handleInput(): void {
      this.$emit('input', JSON.parse(JSON.stringify(this.address)))
    },
  }
})
