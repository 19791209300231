import * as gt from '../../store/getterNames'
import { NavTileChildOptions, NavTileFactory, NavTileOptions, } from '@/models/core/NavTiles'
import Customer from '@/models/customers/Customer'
import CustomerListModel from '@/models/customers/CustomerListModel'

const getCustomerObject: NavTileFactory = (client, getters, auth) => {
  const customerUrl = '/' + client + '/customers'
  const customersAreLoaded = (getters[gt.CUSTOMERS_FOR_CURRENT_CLIENT] as CustomerListModel[]).length > 0
  const openCustomers: Customer[] = getters[gt.CUSTOMERS_OPEN_FOR_CURRENT_CLIENT] as Customer[]
  const customerChildren: NavTileChildOptions[] = []

  if (customersAreLoaded) {
    customerChildren.push({ name: 'Customer List', url: customerUrl })
  }

  openCustomers.forEach(customer => {
    customerChildren.push({ name: customer.name, url: customerUrl + '/' + customer.id })
  })

  const tile: NavTileOptions = {
    title: 'Customers',
    url: customerUrl,
    icon: 'mdi-account-tie',
    isExact: false,
    showBadge: customerChildren.length > 1,
    badgeCount: customerChildren.length,
    enableMenu: customerChildren.length > 1,
    loaded: customersAreLoaded,
    children: customerChildren,
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }

  return tile;
}

export default getCustomerObject;
