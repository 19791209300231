import * as mt from '../store/mutationNames'
import { CloseReasonParams } from '@/models/core/actionParams/CloseReasonParams'
import DocumentChooserRequest from '@/models/core/DocumentChooserRequest'
import DocumentCloseRequest from '@/models/core/DocumentCloseRequest'
import EntityLinkRequest from '@/models/core/entities/EntityLinkRequest'
import RootState from '@/store/RootState'
import { Store } from 'vuex'

const requestLink = (
  store: Store<RootState>,
  clientCode: string,
  hideQuotes = false,
  hideTickets = false,
  hideWorkOrders = false,
): Promise<EntityLinkRequest|null> => {
  return new Promise((resolve, reject) => {
    const request = new DocumentChooserRequest(resolve, reject, clientCode, hideQuotes, hideTickets, hideWorkOrders)
    store.commit(mt.CORE_ADD_DOC_LINK_REQUEST, request)
  });
}

const requestReasonToClose = (
  store: Store<RootState>,
  reasons: string[],
  documentType: string,
  showAssignmentWarning = true,
): Promise<CloseReasonParams|null> => {
  return new Promise((resolve, reject) => {
    const request = new DocumentCloseRequest(resolve, reject, reasons, documentType, showAssignmentWarning)
    store.commit(mt.CORE_ADD_DOC_CLOSE_REQUEST, request)
  });
}

export default {
  requestLink,
  requestReasonToClose,
}
