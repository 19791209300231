import { NavTileFactory } from '@/models/core/NavTiles'

const getDashboardObject: NavTileFactory = (client, getters, auth) => {
  return {
    title: 'Dashboard',
    url: '/' + client,
    icon: 'mdi-home',
    isExact: true,
    showBadge: false,
    badgeCount: 0,
    enableMenu: false,
    loaded: false,
    children: [],
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }
}

export default getDashboardObject;