import { MAILBOX_TOTAL_UNREAD_COUNT } from '@/store/getterNames';
import { NavTileFactory } from '@/models/core/NavTiles'

const getMessagesObject: NavTileFactory = (client, getters, auth) => {
  const unreadCount = getters[MAILBOX_TOTAL_UNREAD_COUNT] as number;

  return {
    title: 'Messages',
    url: '/' + client + '/messages',
    icon: 'mdi-email-outline',
    isExact: true,
    showBadge: unreadCount > 0,
    badgeCount: unreadCount,
    enableMenu: false,
    loaded: false,
    children: [],
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }
}

export default getMessagesObject;