
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import api from '@/util/api';
import { Bar } from 'vue-chartjs/legacy'
import { CORE_CLIENT_CURRENT } from '@/store/getterNames';
import { EstimatorPerformanceModel } from "../../models/quotes/EstimatorPerformanceData";
import IconButton from '../common/IconButton.vue'
import notifications from '@/util/notifications';
import Vue from 'vue'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default Vue.extend({
  components: {
    Bar,
    IconButton,
  },

  data() {
    return {
      chartOptions: {
        maxBarThickness: 50,
        minBarLength: 2,
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            }
          }
        }
      },
      dataFieldName: 'sum' as 'sum'|'average'|'count',
      loaded: false,
      loading: false,
      performanceData: null as null|EstimatorPerformanceModel,
      queryPreset: 'Last30Days' as 'Last30Days'|'Ytd'|'AllTime',
      showCondensedUI: false,
    }
  },

  computed: {
    chartData(): unknown {
      const data = this.performanceData?.entries ?? [];
      const labels = data.map(x => x.label);
      const values = data.map(x => x[this.dataFieldName]);

      return {
        labels,
        datasets: [ {
          label: this.dataLegendLabel, 
          data: values,
          backgroundColor: ["#37a7e5","#b8e0f6", "#693010"],
        } ]
      }
    },

    clientCode(): string {
      return this.$store.getters[CORE_CLIENT_CURRENT].code;
    },

    dataLegendLabel(): string {
      if (this.dataFieldName == 'sum') {
        return "Total Value ($)";
      } else if (this.dataFieldName == 'average') {
        return "Average Value ($)";
      } else {
        return "Quote Count";
      }
    },

    showChart(): boolean {
      return this.loaded && this.performanceData != null;
    }
  },

  async mounted(): Promise<void> {
    await this.loadData();
    this.loaded = true;
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;

      const queryVars = [['period', this.queryPreset]] as [string, string][]
      const result = await api.get(`${this.clientCode}/quotes/charts/estimatorperformance`, queryVars);

      if (result) {
        if (!result.error) {
          this.performanceData = result.data as EstimatorPerformanceModel;
        } else {
          notifications.warn(this.$store, `There was an error while loading the performance data. Message: ${result.data || result.errorCause || "(No Message)"}`)
        }
      } else {
        notifications.warn(this.$store, "Unable to load estimator performance chart data");
      }

      this.loading = false;
    },

    onResize(): void {
      const width = this.$el.getElementsByClassName("v-toolbar")[0].clientWidth;

      this.showCondensedUI = width < 760;
    },
  }
});
