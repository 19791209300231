import * as gt from '../../store/getterNames'
import Item from '@/models/items/Item'
import ItemListModel from '@/models/items/ItemListModel'
import { NavTileFactory } from '@/models/core/NavTiles'

const getItemObject: NavTileFactory = (client, getters, auth) => {
  const url = '/' + client + '/items'
  const itemListIsLoaded = (getters[gt.ITEMS_FOR_CURRENT_CLIENT] as ItemListModel[]).length > 0
  const openItems: Item[] = getters[gt.ITEMS_OPEN_FOR_CURRENT_CLIENT] as Item[]

  const menuLinks = []

  if (itemListIsLoaded) {
    menuLinks.push({ name: 'Item List', url: url })
  }

  openItems.forEach(i => {
    menuLinks.push({ name: `Item: ${i.itemCode}`, url: url + '/' + i.id })
  })

  return {
    title: 'Items',
    url: url,
    icon: 'mdi-cube-outline',
    isExact: false,
    showBadge: menuLinks.length > 1,
    badgeCount: menuLinks.length,
    enableMenu: menuLinks.length > 1,
    loaded: itemListIsLoaded,
    children: menuLinks,
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }
}

export default getItemObject;
