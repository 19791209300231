import { DashboardRow } from "./DashboardRow";
import { DashboardWidget } from "./DashboardWidget";

export class DashboardLayout {
  constructor(
    public rows: DashboardRow[],
  ){}

  public static CreateLayout(widgets: DashboardWidget[]): DashboardLayout {
    const rows: DashboardRow[] = [];
    let row: DashboardWidget[] = [];
    let colCount = 0;

    widgets.sort((a,b) => a.order - b.order);
    
    for (let i = 0; i < widgets.length; i++) {
      const w = widgets[i];
      
      if (w.vueCols + colCount > 12) {
        rows.push(new DashboardRow(row));
        row = [];
        colCount = 0;
      }

      row.push(w);
      colCount += w.vueCols;
    }

    if (colCount > 0) {
      rows.push(new DashboardRow(row));
    }

    return new DashboardLayout(rows);
  }
}