import { NavTileFactory } from "@/models/core/NavTiles"

const getSettingsObject: NavTileFactory = (client, getters, auth) => {
  return {
    title: 'Settings',
    url: '/' + client + '/settings',
    icon: 'mdi-cog',
    isExact: false,
    showBadge: false,
    badgeCount: 0,
    enableMenu: false,
    loaded: false,
    children: [],
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }
}

export default getSettingsObject;
