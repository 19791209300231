export const ASSIGNMENTS = 'assignments'
export const ASSIGNMENT_EDITOR = 'assignmentEditor'
export const ASSIGNMENT_KANBAN = 'assignmentKanban'

export const CONTACTS = 'contacts'

export const CUSTOMERS = 'customers'
export const CUSTOMER_EDITOR = 'customerEditor'

export const DASHBOARD = 'dashboard'

export const FILES = 'files'
export const FILE_VIEWER = 'fileViewer'

export const ITEMS = 'items'
export const ITEM_EDITOR = 'itemEditor'

export const MESSAGES = 'messages'

export const QUOTES = 'quotes'
export const QUOTE_EDITOR = 'quoteEditor'

export const SETTINGS = 'settings'
export const SETTINGS_EMAIL_ADDRESSES = 'settingsEmailAddresses'
export const SETTINGS_ITEMS = 'settingsItems'
export const SETTINGS_QUOTES = 'settingsQuotes'
export const SETTINGS_TICKETS = 'settingsTickets'
export const SETTINGS_WORK_ORDERS = 'settingsWorkOrders'
export const SETTINGS_IMPORT = "settingsImport"
export const SETTINGS_IMPORT_JOBS = 'settingsImportJobs'
export const SETTINGS_IMPORT_JOB_DETAILS = 'settingsImportJobDetails'

export const TICKETS = 'tickets'
export const TICKET_EDITOR = 'ticketEditor'

export const WORK_ORDERS = 'workOrders'
export const WORK_ORDER_EDITOR = 'workOrderEditor'
export const WORK_ORDER_KANBAN_CURRENT = 'workOrderKanbanCurrent'
