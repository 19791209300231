/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from '../routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { clientCodeExpr } from './_typesAndConstants'
import { InsightAuth } from 'insight-auth'
import { VueConstructor } from 'vue'

const CustomerManager = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "customer" */ '@/components/customers/CustomerManager.vue')
  .then(m => m.default) as any

  const CustomerEditor = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "customer" */ '@/components/customers/CustomerEditor.vue')
  .then(m => m.default) as any

const customerKey = 'customers:clientCode'

export const CustomerRoutes = [
  {
    name: rt.CUSTOMERS,
    path: '/' + clientCodeExpr + '/customers',
    component: CustomerManager,
    props: true,
    meta: {
      animationKey: customerKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1001100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1001110")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },


  {
    name: rt.CUSTOMER_EDITOR,
    path: '/' + clientCodeExpr + '/customers/:id([0-9]+)',
    component: CustomerEditor,
    props: true,
    meta: {
      animationKey: customerKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1001300") ||
          auth.hasPrivilegeForClient(params.clientCode, "1001110")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },
] as RouteConfig[];