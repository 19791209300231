
import reformatDate, { ensureDateObject } from '@/util/reformatDate'
import { PropValidator } from 'vue/types/options';
import Vue from 'vue'

export default Vue.extend({
  props: {
    date: {
      type: String,
      default: '',
    },

    sameDayOnly: {
      type: Boolean,
      default: false,
    },

    referenceDate: {
      type: Date,
      default: (): Date => new Date(),
    } as PropValidator<Date>
  },

  computed: {
    formattedDate(): string {
      if (this.referenceDate) { //This is just to force the prop to refresh if the reference date is updated!
        return reformatDate(this.date, this.formatMask, this.sameDayOnly && this.isWithin24hrs);
      } else {
        return '';
      }
    },

    millisecondsFromReference(): number {
      const dt = ensureDateObject(this.date);

      if (dt) {
        return this.referenceDate.getTime() - dt.getTime();
      } else {
        return 0;
      }
    },

    isWithin24hrs(): boolean {
      return this.millisecondsFromReference <= 86400000
    },

    isWithin365days(): boolean {
      return this.millisecondsFromReference <= 31536000000
    },

    formatMask(): string {
      if (this.isWithin24hrs) {
        return 'h:MM tt';

      } else if (this.isWithin365days) { //One year (except leap years)
        return 'mmm d';

      } else {
        return 'm/d/yy';
      }
    }
  }
})
