
import { requiredIf, Validator } from '../../../util/validators'
import { AbstractTextField } from './AbstractTextField'
import { stateList } from "@/models/core/stateList";

export default AbstractTextField.extend({
  
  data() {
    return {
      rules: [requiredIf(() => this.required, () => this.label)] as Validator[],
      stateList,
    }
  }, 
})
