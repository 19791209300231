import PromiseCompleter, { PromiseRejectFunction, PromiseResolveFunction } from "./PromiseCompleter";
import EntityLinkRequest from "./entities/EntityLinkRequest";

export default class DocumentChooserRequest extends PromiseCompleter<EntityLinkRequest|null> {
  constructor(
    resolve: PromiseResolveFunction<EntityLinkRequest|null>,
    reject: PromiseRejectFunction,
    public clientCode: string,
    public hideQuotes: boolean,
    public hideTickets: boolean,
    public hideWorkOrders: boolean,
  ) {
    super(resolve, reject);
  }
}