
import { Format, ReportProps } from '../../models/core/reports/ReportProps'
import { SearchUIFieldDef, SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption'
import { PropValidator } from 'vue/types/options'
import ReportDlg from './ReportDlg.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    ReportDlg,
  },

  props: {
    entityTypePlural: {
      type: String,
      default: '',
      required: true,
    },

    reports: {
      type: Array,
      default: () => [],
      required: true,
    } as PropValidator<ReportProps[]>,

    searchFieldDefs: {
      type: Array,
      default: () => [],
      required: true,
    } as PropValidator<SearchUIFieldDef[]>,
  },

  data() {
    return {
      reportDlgWidth: '13in',
      reportQueryValues: [] as SearchUIFieldValue[],
      selectedReport: null as ReportProps|null,
      showReportDlg: false,
    }
  },

  computed: {
    autoLoadPdf(): boolean {
      return this.selectedReport?.autoLoadPdf ?? false;
    },
    
    reportFileName(): string {
      return this.selectedReport?.fileName ?? "";
    },

    reportName(): string {
      return this.selectedReport?.title ?? "";
    },

    reportUrl(): string {
      return this.selectedReport?.urlStem ?? ""
    },

    supoprtedFormats(): Format {
      return this.selectedReport?.supportedFormats ?? Format.ExcelAndPdf;
    }
  },

  methods: {
    showReport(report: ReportProps): void {
      this.selectedReport = report;
      this.reportQueryValues = report.defaultSearch;

      const screenInches = window.innerWidth / 96;

      if (screenInches > 14) {
        this.reportDlgWidth = "13in";
      } else {
        this.reportDlgWidth = (Math.round(screenInches) - 1) + "in";
      }

      this.showReportDlg = true;
    }
  },
})
