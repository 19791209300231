import { AssignmentTemplate, AssignmentType, WorkType } from "./AssignmentTemplate";
import { compileList } from "@/util/naturalLanguage";
import { EntityLinkDescription } from "../core/entities/EntityLink";
import InsightUser from "../core/InsightUser";
import PrivateEntity from "../core/entities/PrivateEntity";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";
import wordify from '../../util/wordifier'

export const ASSIGNMENT_STATUSES = ["New","Assigned","In Progress","Paused","Done","Skipped"] as const;
export type AssignmentStatus = typeof ASSIGNMENT_STATUSES[number]

export class ChecklistItem {
  constructor(
    public position = 0,
    public text = '',
    public done = false,
  ) {}
}

export class Assignment extends PrivateEntity {
  constructor(
    public template: AssignmentTemplate = new AssignmentTemplate(),
    public associatedEntityId = '',
    public assignedBy = '',
    public assignedAt: string|undefined = undefined,
    public startedBy = '',
    public startedAt: string|undefined = undefined,
    public pausedBy = '',
    public pausedAt: string|undefined = undefined,
    public finishedBy = '',
    public finishedAt: string|undefined = undefined,
    public skippedBy = '',
    public skippedAt: string|undefined = undefined,
    public assignedToUser: InsightUser = new InsightUser(),
    public dueAt: string|undefined = undefined,
    public checklist: ChecklistItem[]|undefined = [],
    public notes = '',
    public status: AssignmentStatus = 'New',
    public position = 0,
    public sortIndex = 0,
  ) {
    super();
  }

  public associatedEntity?: EntityLinkDescription;
}

export class AssignmentListModel extends PrivateEntityListModel<Assignment> {
  constructor(
    public name = '',
    public assignmentType: AssignmentType = "Start/Pause/Finish",
    public workType: WorkType = "Out of App",
    public assignedToUser: InsightUser = new InsightUser(),
    public dueAt = '',
    public status: AssignmentStatus = "New",
    public createdAt = '',
    public finishedAt = '',
    public finishedBy = '',
    public skippedAt = '',
    public hasNotes = false,
    public hasChecklist = false,
    public checklistComplete = false,
    public nextTriggerDescription = '',
    public position = 0,
    public sortIndex = 0,
  ) {
    super()
  }

  public associatedEntity?: EntityLinkDescription;

  protected populateCore(model: Assignment): void {
    this.name = model.template.name;
    this.assignmentType = model.template.assignmentType;
    this.workType = model.template.workType;
    this.assignedToUser = model.assignedToUser;
    this.dueAt = model.dueAt ?? '';
    this.status = model.status;
    this.createdAt = model.createdAt ?? '';
    this.finishedAt = model.finishedAt ?? '';
    this.finishedBy = model.finishedBy;
    this.skippedAt = model.skippedAt ?? '';
    this.hasNotes = model.notes?.length > 0;
    this.hasChecklist = (model.checklist?.length ?? 0) > 0;
    this.checklistComplete = model.checklist?.reduce<boolean>((b, x) => b && x.done, true) ?? true;
    this.nextTriggerDescription = this._describeNextTrigger(model);
    this.position = model.position;
    this.sortIndex = model.sortIndex;
    
    if (model.associatedEntity) {
      this.associatedEntity = model.associatedEntity;
    }
  }

  private _describeNextTrigger(a: Assignment): string {
    if (a.template.behaviors.length == 0) return '';

    if (a.finishedBy > '' || a.skippedBy > '') return '';

    const entityType = wordify.fromCamelCase(a.template.entityType).toLowerCase();
    let assignmentAction = '';
    let actionList = '';

    if (a.startedBy == '' || !a.startedAt) {
      const startTriggers = a.template.behaviors
        .filter(x => x.behaviorType == "Trigger Start from Action")
        .map(x => x.actionName.toLowerCase());

      if (startTriggers.length > 0) {
        actionList = compileList(startTriggers, 'or');
        assignmentAction = 'started';
      }
    }

    if (actionList == '') {
      const finishTriggers = a.template.behaviors
        .filter(x => x.behaviorType == "Trigger Finish from Action")
        .map(x => x.actionName.toLowerCase());

      if (finishTriggers.length > 0) {
        actionList = compileList(finishTriggers, 'or');
        assignmentAction = 'finished';
      }
    }

    if (actionList > '') {
      return `This assignment will be ${assignmentAction} when you ${actionList} the ${entityType}`;
    } else {
      return '';
    }
  }
}

export const getActionIconForStatus = (status: string): string => {
  if (status == 'New') {
    return 'mdi-account-plus'
  } else if (status == 'Assigned') {
    return 'mdi-play'
  } else if (status == 'In Progress') {
    return 'mdi-check'
  } else if (status == 'Paused') {
    return 'mdi-play'
  } else {
    return ''
  }
}

export const getActionTextForStatus = (status: string): string => {
  if (status == 'New') {
    return 'Assign User'
  } else if (status == 'Assigned') {
    return 'Start'
  } else if (status == 'In Progress') {
    return 'Finish'
  } else if (status == 'Paused') {
    return 'Resume'
  } else {
    return ''
  }
}
