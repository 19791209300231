
import { isDarkColor } from "../../util/colorUtils";
import { LifecycleHash } from "../../models/lifecycles/LifecycleHash";
import { PropValidator } from 'vue/types/options';

export default {
  props: {
    lifecycleHash: {
      type: Object,
      default: null,
      required: true,
    } as PropValidator<LifecycleHash>,

    preventOverlap: Boolean,

    small: Boolean,

    value: {
      type: String,
      default: '',
    },
  },

  methods: {
    getChipColor(status: string, hash: LifecycleHash): string {
      return hash.statuses[status]?.color ?? ""
    },

    getIcon(status: string, hash: LifecycleHash): string {
      return hash.statuses[status]?.icon ?? ""
    },

    getTextColor(status: string, hash: LifecycleHash): string {
      return isDarkColor(hash.statuses[status]?.color ?? "") ? "#FFFFFF" : "#000000";
    },
  }
}
