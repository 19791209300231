/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from '../routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { clientCodeExpr } from './_typesAndConstants'
import { InsightAuth } from 'insight-auth'
import { VueConstructor } from 'vue'

const WorkOrderKanban = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "workorder" */ '@/components/workOrders/kanban/WorkOrderKanban.vue')
  .then(m => m.default) as any

  const AssignmentKanban = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "assignment" */ '@/components/assignments/kanban/AssignmentKanban.vue')
  .then(m => m.default) as any

const boardUrlStem = '/' + clientCodeExpr + '/boards/'

export const BoardRoutes = [
  {
    name: rt.WORK_ORDER_KANBAN_CURRENT,
    path: boardUrlStem + 'workOrders/:mode(current|steps)',
    component: WorkOrderKanban,
    props: true,
    meta: {
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1009100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1009120")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },

  {
    name: rt.ASSIGNMENT_KANBAN,
    path: boardUrlStem + 'assignments',
    component: AssignmentKanban,
    props: true,
    meta: {
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1013100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1013120")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },
] as RouteConfig[];