
import reformatDate from '../../../util/reformatDate';

export default {
  methods: {
    formattedAt(unformatted: string): string {
      return reformatDate(unformatted, 'hh:MM TT');
    },

    formattedOn(unformatted: string): string{
      return reformatDate(unformatted, 'mm/dd/yyyy');
    },
  }
}
