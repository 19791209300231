
import EntityLinkRequest from '@/models/core/entities/EntityLinkRequest';
import { EntityManagerInterface } from '@/models/core/entityManager/EntityManagerInterface';
import { EntityType } from '@/models/assignments/AssignmentTemplate';
import { getIconNameForEntityType } from '@/util/iconHelper';
import PrivateEntity from '@/models/core/entities/PrivateEntity';
import PrivateEntityListModel from '@/models/core/entities/PrivateEntityListModel';
import QuoteListModel from '@/models/quotes/QuoteListModel';
import QuoteManager from '../quotes/QuoteManager.vue'
import { TicketListModel } from '@/models/tickets/Ticket';
import TicketManager from '../tickets/TicketManager.vue';
import Vue from 'vue'
import { WorkOrderListModel } from '@/models/workOrders/WorkOrderListModel';
import WorkOrderManager from '../workOrders/WorkOrderManager.vue'

export default Vue.extend({
  components: {
    QuoteManager,
    TicketManager,
    WorkOrderManager,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
      required: true,
    },

    hideQuotes: Boolean,

    hideTickets: Boolean,

    hideWorkOrders: Boolean,

    show: Boolean,
  },

  data() {
    return {
      selectedEntity: null as PrivateEntityListModel<PrivateEntity>|null,
      selectedEntityDescription: '',
      selectedEntityType: 'Quote' as EntityType,
      selectedTab: 0,
    }
  },

  computed: {
    canChooseQuotes(): boolean {
      return this.$auth.hasPrivilegeForClient(this.clientCode, "1003110")
        && this.$auth.hasFeatureForClient(this.clientCode, "1001000")
        && !this.hideQuotes;
    },

    canChooseTickets(): boolean {
      return this.$auth.hasPrivilegeForClient(this.clientCode, "1014110")
        && this.$auth.hasFeatureForClient(this.clientCode, "1003000")
        && !this.hideTickets;
    },

    canChooseWorkOrders(): boolean {
      return this.$auth.hasPrivilegeForClient(this.clientCode, "1009120")
        && this.$auth.hasFeatureForClient(this.clientCode, "1002000")
        && !this.hideWorkOrders;
    },

    quoteIcon(): string {
      return getIconNameForEntityType("Quote");
    },

    ticketIcon(): string {
      return getIconNameForEntityType("Ticket");
    },

    workOrderIcon(): string {
      return getIconNameForEntityType("WorkOrder");
    },
  },

  beforeDestroy() {
    (this.$refs["quoteMgr"] as unknown as EntityManagerInterface)?.unloadEntities(true);
    (this.$refs["ticketMgr"] as unknown as EntityManagerInterface)?.unloadEntities(true);
    (this.$refs["workOrderMgr"] as unknown as EntityManagerInterface)?.unloadEntities(true);
  },

  methods: {
    async close(mode: 'cancel'|'save'): Promise<void> {
      if (mode == 'save' && this.selectedEntity) {
        const linkRequest = new EntityLinkRequest(
          this.selectedEntity.id,
          this.selectedEntityType,
          this.selectedEntityDescription,
        );

        this.$emit('entity-selected', linkRequest);
      }

      this.selectedEntity = null;
      this.$emit('update:show', false);
    },

    handleQuoteSelected(quote: QuoteListModel, saveNow = false): void {
      this.selectedEntity = quote;
      this.selectedEntityDescription = quote.quoteNumber;
      this.selectedEntityType = "Quote";
      
      if (saveNow) {
        this.close('save');
      }
    },

    handleTicketSelected(ticket: TicketListModel, saveNow = false): void {
      this.selectedEntity = ticket;
      this.selectedEntityDescription = ticket.ticketNumber;
      this.selectedEntityType = "Ticket";
      
      if (saveNow) {
        this.close('save');
      }
    },

    handleWorkOrderSelected(workOrder: WorkOrderListModel, saveNow = false): void {
      this.selectedEntity = workOrder;
      this.selectedEntityDescription = workOrder.workOrderNumber;
      this.selectedEntityType = "WorkOrder";
      
      if (saveNow) {
        this.close('save');
      }
    }
  }
})
