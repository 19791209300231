
import * as gt from '../../store/getterNames'
import * as mt from '../../store/mutationNames'
import AppSidebarNavTile from './AppSidebarNavTile.vue'
import AppSidebarOverflowMenu from "./AppSidebarOverflowMenu.vue"
import Client from '../../models/core/Client'
import environment from '../../util/environment'
import InsightClientSelector from './InsightClientSelector.vue'
import { NavTileOptions } from '../../models/core/NavTiles'
import navTiles from '../../nav/navTiles'
import RootState from '@/store/RootState'
import Vue from 'vue'

export default Vue.extend({
  components: {
    AppSidebarNavTile,
    AppSidebarOverflowMenu,
    InsightClientSelector,
  },

  data() {
    return {
      loggingOut: false,
      mainNavAreaHeight: 0,
    }
  },

  computed: {
    allNavTiles(): NavTileOptions[] {
      return navTiles(this.$store, this.$auth).filter(x => x.enableTile);
    },
    
    clientList(): Client[] {
      return this.rootState.clients;
    },

    currentClientCode(): string {
        return (this.$store.getters[gt.CORE_CLIENT_CURRENT] || {}).code || ''
    },

    currentClientIdx(): number {
      return this.rootState.selectedClientIndex;
    },

    footerTextColor(): string {
      if (environment == 'dev') {
        return 'warning--text'
      } else if (environment == 'staging') {
        return 'error--text'
      } else {
        return ''
      }
    },

    isLoggedIn(): boolean {
        return (this.$store.state as RootState).loggedIn
    },

    mainMenuItems(): NavTileOptions[] {
      if (this.maxNavTiles == 0) {
        return [];
      } else if (this.allNavTiles.length <= this.maxNavTiles) {
        return this.allNavTiles;
      } else {
        return this.allNavTiles.slice(0, this.maxNavTiles-1);
      }
    },

    maxNavTiles(): number {
      return Math.floor(this.mainNavAreaHeight / this.navTileHeight);
    },

    navTileHeight(): number {
      return 48;
    },

    overflowItems(): NavTileOptions[] {
      if (this.maxNavTiles == 0) {
        return this.allNavTiles;
      } else if (this.allNavTiles.length <= this.maxNavTiles) {
        return [];
      } else {
        return this.allNavTiles.slice(this.maxNavTiles-1);
      }
    },

    rootState(): RootState {
      return this.$store.state as RootState;
    },

    sideNav(): { visible: boolean; minified: boolean } {
      return this.rootState.sideNav;
    },
  },

  mounted() {
    this.calcMainNavAreaHeight();

    setTimeout(() => {
      this.calcMainNavAreaHeight();
    }, 100);
  },

  methods: {
    calcMainNavAreaHeight(): void {
      const headerSection = document.getElementsByClassName("side-nav-header")[0] as HTMLElement;
      const footerSection = document.getElementsByClassName("side-nav-footer")[0] as HTMLElement;

      let height = window.innerHeight;

      height -= headerSection.offsetHeight;
      height -= footerSection.offsetHeight;

      this.mainNavAreaHeight = height;
    },

    toggleSideNav(): void {
      this.$store.commit(mt.CORE_TOGGLE_SIDENAV_MINI)
    },

    logMeOut(): void {
      this.loggingOut = true
      this.$auth.logout()
    },
  }
});
