export const getColorForStatus = (status: string): string => {
  if (status == 'New') {
    return 'pink lighten-4'
  } else if (status == 'Preparing') {
    return 'purple lighten-3'
  } else if (status == 'Prepared') {
    return 'purple'
  } else if (status == 'In Progress') {
    return 'primary lighten-2'
  } else if (status == 'Complete') {
    return 'primary'
  } else if (status == 'Shipping') {
    return 'yellow lighten-2'
  } else if (status == 'Shipped') {
    return 'yellow darken-2'
  } else if (status == 'Installing') {
    return 'info lighten-2'
  } else if (status == 'Installed') {
    return 'info'
  } else if (status == 'Closing') {
    return 'success lighten-2'
  } else if (status == 'Closed') {
    return 'success'
  } else if (status == 'Canceled') {
    return 'grey'
  } else if (status == 'On Hold') {
    return 'orange'
  } else {
    return ''
  }
}

export const colors: Record<string, string> = {
  'New': 'pink lighten-4',
  'Preparing': 'purple lighten-3',
  'Prepared': 'purple',
  'In Progress': 'primary lighten-2',
  'Complete': 'primary',
  'Shipping': 'yellow lighten-2',
  'Shipped': 'yellow darken-2',
  'Installing': 'info lighten-2',
  'Installed': 'info',
  'Closing': 'success lighten-2',
  'Closed': 'success',
  'Canceled': 'grey',
  'On Hold': 'orange',
}

export const getForegroundColorForStatus = (status: string): string => {
  if (status == 'New' || status == 'Shipping' || status == 'Shipped' || status == 'In Progress' || status == 'Closing') { //pink, yellow, light blue, light green
    return "black";
  } else {
    return "white";
  }
}

export const foregroundColors: Record<string, string> = {
  'New': 'black',
  'Preparing': 'white',
  'Prepared': 'white',
  'In Progress': 'black',
  'Complete': 'white',
  'Shipping': 'black',
  'Shipped': 'black',
  'Installing': 'white',
  'Installed': 'white',
  'Closing': 'black',
  'Closed': 'white',
  'Canceled': 'white',
  'On Hold': 'white',
}

export const getIconForStatus = (status: string): string => {
  if (status == 'New') {
    return 'mdi-timer-sand'
  } else if (status == 'Preparing') {
    return 'mdi-android-studio'
  } else if (status == 'Prepared') {
    return 'mdi-file-cad'
  } else if (status == 'In Progress') {
    return 'mdi-progress-check'
  } else if (status == 'Complete') {
    return 'mdi-check'
  } else if (status == 'Shipping') {
    return 'mdi-package-variant-closed'
  } else if (status == 'Shipped') {
    return 'mdi-truck-check'
  } else if (status == 'Installing') {
    return 'mdi-wrench'
  } else if (status == 'Installed') {
    return 'mdi-wrench-check'
  } else if (status == 'Closing') {
    return 'mdi-lock-open-variant-outline'
  } else if (status == 'Closed') {
    return 'mdi-lock-outline'
  } else if (status == 'Canceled') {
    return 'mdi-close'
  } else if (status == 'On Hold') {
    return 'mdi-clock-outline'
  } else {
    return ''
  }
}

export const icons: Record<string, string> = {
  'New': 'mdi-timer-sand',
  'Preparing': 'mdi-android-studio',
  'Prepared': 'mdi-file-cad',
  'In Progress': 'mdi-progress-check',
  'Complete': 'mdi-check',
  'Shipping': 'mdi-package-variant-closed',
  'Shipped': 'mdi-truck-check',
  'Installing': 'mdi-wrench',
  'Installed': 'mdi-wrench-check',
  'Closing': 'mdi-lock-open-variant-outline',
  'Closed': 'mdi-lock-outline',
  'Canceled': 'mdi-close',
  'On Hold': 'mdi-clock-outline',
}