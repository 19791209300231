
import { SearchUIFieldDef, SearchUIFieldValue } from "@/models/core/searchQueries/SearchUIOption";
import api from "@/util/api";
import { buildQueryVarsForSearch } from "@/models/core/searchQueries/ApiQueryVarsBuilder";
import CustomerListModel from '@/models/customers/CustomerListModel';
import { describeQuery } from "@/models/core/searchQueries/QueryDescriptionGenerator";
import EntityManagerSearchDialog from './EntityManager/EntityManagerSearchDialog.vue';
import { Format } from "@/models/core/reports/ReportProps";
import notifications from '@/util/notifications';
import PdfViewer from './PdfViewer.vue'
import { PropValidator } from "vue/types/options";
import Vue from 'vue'

export default Vue.extend({
  components: {
    EntityManagerSearchDialog,
    PdfViewer,
  },

  props: {
    autoLoadPdf: Boolean,

    entityTypePlural: {
      type: String,
      default: '',
      required: true,
    },

    maxWidth: {
      type: String,
      default: "9in"
    },

    reportFileName: {
      type: String,
      default: '',
      required: true,
    },

    reportName: {
      type: String,
      default: '',
      required: true,
    },

    searchFieldDefs: {
      type: Array,
      default: () => [],
    } as PropValidator<SearchUIFieldDef[]>,

    searchValues: {
      type: Array,
      default: () => [],
    } as PropValidator<SearchUIFieldValue[]>,

    show: Boolean,

    supportedFormats: {
      type: Number,
      default: 2,
    } as PropValidator<Format>,

    urlStem: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      customer: null as CustomerListModel|null,
      downloadingExcel: false,
      loadingPdf: false,
      postData: null as unknown,
      selectedUsers: [] as string[],
      showPdf: false,
      showSearchDialog: false,
    }
  },

  computed: {
    customerName(): string {
      return this.customer?.name ?? "(No customer selected)";
    },

    pdfButtonText(): string {
      if (this.showPdf) {
        return "Refresh PDF Report";
      } else {
        return "View PDF Report"
      }
    },

    queryDescription(): string {
      return "Current Query: " + describeQuery(this.searchValues, this.searchFieldDefs, this.entityTypePlural);
    },

    reportPostData(): Record<string, unknown> {
      return buildQueryVarsForSearch(this.searchValues)
    },

    supportsExcel(): boolean {
      return this.supportedFormats != Format.PdfOnly
    },

    supportsPdf(): boolean {
      return this.supportedFormats != Format.ExcelOnly
    },

    urlInternal(): string|undefined {
      return this.showPdf ? this.urlPdf : undefined;
    },

    urlExcel(): string {
      return `${this.urlStem}/excel/${this.reportFileName}`
    },

    urlPdf(): string {
      return `${this.urlStem}/pdf/${this.reportFileName}`
    },
  },

  watch: {
    show: {
      handler(): void {
        if (this.show && this.autoLoadPdf) {
          this.showPdf = true;
        }
      },
      
      immediate: true,
    }
  },

  methods: {
    closeDialog(): void {
      this.showPdf = false;
      this.$emit('update:show', false);
    },

    async downloadReportExcel(): Promise<void> {
      this.downloadingExcel = true;
      const response = await api.create(this.urlExcel, this.reportPostData, undefined, false, 'blob');
      this.downloadingExcel = false;
      
      if (response) {
        if (!response.error) {
          this.saveToLocalDevice(`${this.reportFileName}.xlsx`, response.data as Blob);
          return;
        }
      }

      //If we get this far, there was a problem
      notifications.fail(this.$store, 'There was a problem downloading this file.');
    },

    handleLoadError(msg: string): void {
      notifications.warn(this.$store, msg);
      this.closeDialog();
    },

    handleSearchValuesUpdated(vals: SearchUIFieldValue[]): void {
      this.$emit('update:searchValues', vals);
      this.showPdf = false;
    },

    async loadReportPDF(): Promise<void> {
      if (this.showPdf) {
        this.showPdf = false;
        await this.$nextTick();
      }

      this.showPdf = true;
    },

    saveToLocalDevice(filename: string, data: Blob): void {
      if(window.navigator.msSaveBlob !== undefined) {
        window.navigator.msSaveBlob(data, filename);
      } else{
        const elem = window.document.createElement('a');
        const url = window.URL.createObjectURL(data);
        elem.href = url;
        elem.download = filename;        
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        window.URL.revokeObjectURL(url);
      }
    },
  }
})
