import { SearchUIFieldValue } from "../searchQueries/SearchUIOption";

export enum Format {
  PdfOnly,
  ExcelOnly,
  ExcelAndPdf,
}

export class ReportProps {
  public constructor (
    public title: string,
    public icon: string,
    public fileName: string,
    public urlStem: string,
    public defaultSearch: SearchUIFieldValue[],
    public supportedFormats: Format = Format.ExcelAndPdf,
    public autoLoadPdf = false,
  ) {
    if (supportedFormats == Format.ExcelOnly && autoLoadPdf == true) {
      throw new Error("Cannot set autoload PDF property to true when PDF format is not supported!");
    }
  }
}