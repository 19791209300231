import { Assignment } from "./Assignment";
import { Spec } from "../core/spec/Spec";

export class AssignedToSpec extends Spec<Assignment> {
  constructor(userId: string) {
    super();
    this._userId = userId;
  }

  private _userId: string;

  public isSatisfiedBy(entity: Assignment): boolean {
    return (entity.assignedToUser?.id ?? '') == this._userId;
  }
}

export class AssociatedWithSpec extends Spec<Assignment> {
  constructor(associatedEntityId: string) {
    super();
    this._associatedEntityId = associatedEntityId;
  }

  private _associatedEntityId: string;

  public isSatisfiedBy(entity: Assignment): boolean {
    return entity.associatedEntityId == this._associatedEntityId;
  }
}

export class DueBeforeSpec extends Spec<Assignment> {
  constructor(isoDateTimeOffsetString: string) {
    super();
    this._isoDateTimeString = isoDateTimeOffsetString;
  }

  private _isoDateTimeString: string;

  public isSatisfiedBy(entity: Assignment): boolean {
    return (entity.dueAt ?? "0001-01-01T00:00:00-00:00") < this._isoDateTimeString;
  }
}

export class FinishedSpec extends Spec<Assignment> {
  constructor() {
    super();
  }

  public isSatisfiedBy(entity: Assignment): boolean {
    return entity.finishedBy > '';
  }
}

export class SkippedSpec extends Spec<Assignment> {
  constructor() {
    super();
  }

  public isSatisfiedBy(entity: Assignment): boolean {
    return entity.skippedBy > '';
  }
}