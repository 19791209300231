
import { CORE_CLEAR_EDITOR_BUFFER, CORE_GENERIC_PAGER_CREATE, CORE_GENERIC_PAGER_DESTROY, MESSAGE_ADD_REFERRER, MESSAGE_DROP_REFERRER, MESSAGE_REMOVE_ENTITY_FROM_REF, MESSAGE_STORE_ENTITY_FOR_REF } from '@/store/mutationNames'
import { CORE_CLIENT_CURRENT, CORE_GET_GENERIC_PAGER, MAILBOX_SENDING_ADDRESSES, MAILBOXES_FOR_CURRENT_USER, MESSAGE_REFERRER_EXISTS, MESSAGES_LISTED_FOR_REF, MESSAGES_OPEN_FOR_REF } from '@/store/getterNames'
import { MessageContactType, MessageState, MessageType } from '@/models/messages/MessageEnums'
import MessageListModel, { MessageListModelWithPaging } from '@/models/messages/MessageListModel'
import reformatDate, { ensureDateObject } from '@/util/reformatDate'
import api from '@/util/api'
import { BelongsToMailboxSpec } from "@/models/messages/BelongsToMailboxSpec";
import { DeletedSpec } from '@/models/core/spec/DeletedSpec'
import { GenericPager } from '@/models/core/entities/EntityPagingData'
import IdGenerator from '@/util/IdGenerator'
import MailboxListModel from '@/models/mailboxes/MailboxListModel'
import { MailboxType } from '@/models/mailboxes/MailboxType'
import Message from '@/models/messages/Message'
import { MESSAGE_FETCH_FOR_MAILBOX } from '@/store/actionNames'
import { MESSAGE_WIDGET_REF } from '@/store/refNames'
import MessageContact from '@/models/messages/MessageContact'
import MessageEditor from './MessageEditor.vue'
import MessageViewer from './MessageViewer.vue'
import notifications from '@/util/notifications'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import RelativeDate from '../common/RelativeDate.vue'
import { Spec } from '@/models/core/spec/Spec'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest'

export default Vue.extend({
  components: {
    MessageEditor,
    MessageViewer,
    RelativeDate,
  },

  props: {
  },

  data() {
    return {
      currentMailboxAddress: '',
      draftState: MessageState.Draft,
      loadingMessageList: false,
      loadingMessagePreview: false,
      messageId: "0",
      newMessage: null as Message|null,
      refDate: new Date(),
      refDateIntervalHandle: null as NodeJS.Timeout|null,
      sendingState: MessageState.Sending,
      showMessagePreview: false,
    }
  },

  computed: {
    canCompose(): boolean {
      return this.fromAddresses.includes(this.currentMailboxAddress);
    },

    currentClient(): string {
      return this.$store.getters[CORE_CLIENT_CURRENT]?.code ?? ''
    },

    currentMailboxId(): string {
      return this.inboxMailboxes.find(x => x.address == this.currentMailboxAddress)?.id ?? "0";
    },

    fromAddresses(): string[] {
      return this.$store.getters[MAILBOX_SENDING_ADDRESSES]
    },

    inboxes(): string[] {
      return this.inboxMailboxes.map(x => x.address);
    },

    inboxMailboxes(): MailboxListModel[] {
      return (this.$store.getters[MAILBOXES_FOR_CURRENT_USER] as MailboxListModel[])
        .filter(x => x.type == MailboxType.Inbox)
    },

    lastMessageInList(): number {
      return (this.pageNumber * this.pageSize) + this.messages.length;
    },

    mailboxType(): MailboxType {
      return MailboxType.Inbox;
    },

    messages(): MessageListModel[] {
      return this.$store.getters[MESSAGES_LISTED_FOR_REF](this.currentClient, this.refName);
    },

    messagePreview(): Message|null {
      return this.$store.getters[MESSAGES_OPEN_FOR_REF](this.currentClient, this.refName)[0] as Message || null;
    },

    msgEditorId(): string {
      const msg = (this.messagePreview || {}) as Message;
      return `MessageEditor_MessageWidget_${msg.tempId ?? msg.id}`
    },

    pageNumber(): number {
      return this.paginationData?.serverPageNumber ?? 0;
    },

    pageSize(): number {
      return this.paginationData?.serverPageSize ?? 50;
    },

    paginationData(): GenericPager|undefined {
      return this.$store.getters[CORE_GET_GENERIC_PAGER](this.refName)
    },

    referrerExists(): boolean {
      return this.$store.getters[MESSAGE_REFERRER_EXISTS](this.refName);
    },

    refName(): string {
      return MESSAGE_WIDGET_REF
    },

    refSpec(): Spec<Message> {
      return new BelongsToMailboxSpec(this.currentMailboxId).and(new DeletedSpec().not());
    },

    sortedMessages(): MessageListModel[] {
      const messages: MessageListModelWithPaging[] = Array.from(this.messages).sort((a, b): number => {
        const aDate = ensureDateObject(a.messageDate);
        const bDate = ensureDateObject(b.messageDate);

        return (bDate?.getTime() || 0) - (aDate?.getTime() || 0);
      })

      if (this.pageNumber > 0) {
        messages.splice(0, 0, new MessageListModelWithPaging(true, this.pageNumber - 1));
      }

      if (this.lastMessageInList < this.totalMessageCount) {
        messages.push(new MessageListModelWithPaging(true, this.pageNumber + 1));
      }

     return messages
    },

    totalMessageCount(): number {
      return this.paginationData?.serverSideEntityCount ?? 0;
    }
  },

  created() {
    if (this.currentMailboxAddress == '' && this.inboxes.length > 0) {
      this.currentMailboxAddress = this.inboxes[0];
    }

    this.configurePager();

    if (!this.referrerExists) {
      this.configureEntityReferrer();
      this.loadMessages();
    }
  },

  mounted() {
    this.refDateRefreshStart();
  },

  beforeDestroy() {
    this.refDateRefreshStop();
  },

  methods: {
    configureEntityReferrer(): void {
      const referrer = new VuexEntityReferer(
        this.currentClient,
        this.refName,
        (entity, mode) =>  {
          if (mode == 'listModel'){
            const msg = entity as Message;

            return this.refSpec.isSatisfiedBy(msg) ? 'yes' : 'no';
          }

          return 'noChange';
        }
      );

      this.$store.commit(MESSAGE_ADD_REFERRER, referrer)
    },

    configurePager(): void {
      if (this.paginationData == null) {
        const pager = new GenericPager(this.refName, 'createdAt', false);
        this.$store.commit(CORE_GENERIC_PAGER_CREATE, pager);
      }
    },

    dropEntityReferrer(): void {
      this.$store.commit(MESSAGE_DROP_REFERRER, {client: this.currentClient, name: this.refName});
      this.$store.commit(CORE_GENERIC_PAGER_DESTROY, this.refName);
    },

    formatMessageDate(messageDate: Date|string|null|undefined): string {
      return reformatDate(messageDate, 'mm/dd/yyyy hh:MM tt');
    },

    getMessageBufferId(msg: Message): string {
      return `MessageEditor_MessageWidget_${msg.tempId ?? msg.id}`
    },

    handleNewMessageRequest(): void {
      if (!this.canCompose) {
        notifications.warn(this.$store, "You do not have permission to send messages from this address!");
        return;
      }

      if (this.currentMailboxAddress) {
        const currentContact = new MessageContact();
        currentContact.name = null;
        currentContact.address = this.currentMailboxAddress;
        currentContact.type = MessageContactType.From;

        const newMsg = new Message();
        newMsg.contacts.push(currentContact);
        newMsg.id = IdGenerator.CreateLocalId();
        newMsg.messageDate = new Date().toISOString();
        newMsg.client = this.currentClient;
        newMsg.type = MessageType.ExternalAdHoc;
        
        this.setMessagePreview(newMsg);
        this.showMessagePreview = true;
      } else {
        notifications.warn(this.$store, "Please select a mailbox before composing messages.");
      }
    },

    handleForwardRequest(): void {
      if (!this.canCompose) {
        notifications.warn(this.$store, "You do not have permission to send messages from this address!");
        return;
      }

      if (!this.currentMailboxAddress) {
        notifications.warn(this.$store, "Please select a mailbox before composing messages.");
        return;
      }

      const msg = this.messagePreview;
      if (!msg) {
        notifications.warn(this.$store, "Please preview a message before forwarding.");
        return;
      }

      let currentContact = msg.contacts.find(x => x.address == this.currentMailboxAddress);

      if (!currentContact) {
        currentContact = new MessageContact();
        currentContact.address = this.currentMailboxAddress;
        currentContact.type = MessageContactType.From;
      }

      const newMsg = Message.GenerateForward(msg, currentContact)

      newMsg.id = IdGenerator.CreateLocalId();

      this.setMessagePreview(newMsg);
    },

    handleMessageSent(): void {
      this.showMessagePreview = false;
      this.$store.commit(CORE_CLEAR_EDITOR_BUFFER, this.msgEditorId);
      this.setMessagePreview(null);
    },

    handlePagingButtonClick(requestedPage: number): void {
      const scroller = document.getElementById("messageWidgetScroller")
      
      if (scroller) {
        scroller.scrollTop = 0;
      }
  
      this.loadMessages(requestedPage);
    },

    handleReplyRequest(mode: 'single'|'all'): void {
      if (!this.canCompose) {
        notifications.warn(this.$store, "You do not have permission to send messages from this address!");
        return;
      }

      if (!this.currentMailboxAddress) {
        notifications.warn(this.$store, "Please select a mailbox before composing messages.");
        return;
      }

      const msg = this.messagePreview;
      if (!msg) {
        notifications.warn(this.$store, "Please preview a message before replying.");
        return;
      }

      let currentContact = msg.contacts.find(x => x.address == this.currentMailboxAddress);

      if (!currentContact) {
        currentContact = new MessageContact();
        currentContact.address = this.currentMailboxAddress;
        currentContact.type = MessageContactType.From;
      }

      const newMsg = Message.GenerateReply(mode, msg, currentContact)

      newMsg.id = IdGenerator.CreateLocalId();

      this.setMessagePreview(newMsg);
    },

    async loadMessages(pageNumber = 0): Promise<void> {
      this.loadingMessageList = true;

      const requestedMailboxId = this.currentMailboxId;
      const requestedRefName = this.refName;

      if (requestedMailboxId != "0") {
        await this.$store.dispatch(
          MESSAGE_FETCH_FOR_MAILBOX,
          {
            requestedMailboxId,
            requestedRefName,
            pageNumber,
            pageSize: 50
          });
      }

      this.loadingMessageList = false;
    },

    messageIcon(state: MessageState): string {
      if (state == MessageState.Draft) {
        return 'mdi-email-edit-outline';

      } else if (state == MessageState.Sending ||
                 state == MessageState.SentPendingTemplatePopulation ||
                 state == MessageState.Sent)
      {
        return 'mdi-send';

      } else if (state == MessageState.Recieved) {
        return 'mdi-inbox';

      } else {
        return '';
      }
    },

    messageStateText(state: MessageState): string {
      if (state == MessageState.Draft) {
        return 'Draft';

      } else if (state == MessageState.Sending ||
                 state == MessageState.SentPendingTemplatePopulation ||
                 state == MessageState.Sent)
      {
        return 'Sent';

      } else if (state == MessageState.Recieved) {
        return 'Received';

      } else {
        return 'Status Unknown';
      }
    },

    async previewMessage(message: MessageListModelWithPaging): Promise<void> {
      if (message?.isPageControlItem) {
        return;
      }

      this.messageId = message.id;
      this.loadingMessagePreview = true;

      const url = `${this.currentClient}/messages/${message.id}`
      const apiResponse = await api.get(url)

      if (apiResponse) {
        if (!apiResponse.error) {
          this.setMessagePreview(apiResponse.data as Message);
          this.showMessagePreview = true;
        } else {
          const e = apiResponse;
          notifications.warn(this.$store, `There was an error loading this message. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.loadingMessagePreview = false;
    },

    refDateRefreshStart(): void {
      this.refDateIntervalHandle = setInterval(this.refDateRefreshDo, 60000);
    },

    refDateRefreshDo(): void {
      this.refDate = new Date();
    },

    refDateRefreshStop(): void {
      if (this.refDateIntervalHandle != null) {
        clearInterval(this.refDateIntervalHandle);
      }
    },

    setMessagePreview(newMsg: Message|null): void {
      if (this.messagePreview) {
        const spec: VuexEntityRefSpec ={
          refName: this.refName,
          spec: this.messagePreview as PrivateEntitySpec,
        }

        this.$store.commit(MESSAGE_REMOVE_ENTITY_FROM_REF, spec);
      }

      if (newMsg) {
        const request: VuexEntityStorageRequest<Message> = {
          refName: this.refName,
          entity: newMsg
        }

        this.$store.commit(MESSAGE_STORE_ENTITY_FOR_REF, request)
      }
    },
  }
})
