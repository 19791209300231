import PrivateEntity from "../entities/PrivateEntity";
import RootState from "@/store/RootState";
import { Spec } from "../spec/Spec";
import { Store } from "vuex";

export type VuexEntityRefererClaimFunction<E extends PrivateEntity> = 
  (entity: E, mode: 'entity'|'listModel', state: unknown) => 'yes'|'no'|'noChange'

export default class VuexEntityReferer<E extends PrivateEntity> {
  constructor(
    public client: string,
    public name: string,
    public claim: VuexEntityRefererClaimFunction<E>,
    public useCounter = false
  ) {
    this.counter = useCounter ? 1 : 0;
  }

  public counter: number;
}

export function defaultClaimFunctionFactory<E extends PrivateEntity>(
  entityResponse: 'yes'|'no'|'noChange',
  listModelSpec: Spec<E>,
  clientCode: string
): VuexEntityRefererClaimFunction<E> {
  return (entity, mode): 'yes'|'no'|'noChange' =>  {
    if (mode == 'entity') {
      if (entity.client == clientCode) {
        return entityResponse;
      } else {
        return 'no';
      }

    } else {
      if (listModelSpec.isSatisfiedBy(entity)) {
        return 'yes'
      } else {
        return 'no'
      }
    }
  }
}

export function addReferrer<E extends PrivateEntity>(
  clientCode: string,
  refName: string,
  entityResponse: 'yes'|'no'|'noChange',
  listModelSpec: Spec<E>,
  mutationName: string,
  store: Store<RootState>,
): void {
  const referrer = new VuexEntityReferer(
    clientCode,
    refName,
    defaultClaimFunctionFactory(entityResponse, listModelSpec, clientCode),
  );

  store.commit(mutationName, referrer);
}