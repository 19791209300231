import { render, staticRenderFns } from "./StatusChip.vue?vue&type=template&id=d026dd20&functional=true&"
import script from "./StatusChip.vue?vue&type=script&lang=ts&"
export * from "./StatusChip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports