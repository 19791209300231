import Message from "./Message";
import { Spec } from "../core/spec/Spec";

export class BelongsToMailboxSpec extends Spec<Message> {
  constructor(mailboxId: string) {
    super();
    this._mailboxId = mailboxId;
  }

  private _mailboxId: string;

  public isSatisfiedBy(entity: Message): boolean {
    if (entity.mailboxes) {
      return (entity.mailboxes.findIndex(x => x.id == this._mailboxId) > -1);
    } else {
      return false;
    }
  }
}