
import { CORE_DOC_CLOSE_REQUEST_COUNT, CORE_NEXT_DOC_CLOSE_REQUEST } from '@/store/getterNames'
import { CloseReasonParams } from '@/models/core/actionParams/CloseReasonParams';
import { CORE_REMOVE_DOC_CLOSE_REQUEST } from '@/store/mutationNames';
import delay from '@/util/delay';
import DocumentCloseRequest from '@/models/core/DocumentCloseRequest';
import Vue from 'vue'

export default Vue.extend({
  components: {
  },

  data() {
    return {
      forceClose: false,
      reasonToClose: null as string|null,
      renderChooserComponent: false,
      show: false,
    }
  },

  computed: {
    docRequestCount(): number {
      return this.$store.getters[CORE_DOC_CLOSE_REQUEST_COUNT];
    },

    documentType(): string {
      return this.nextDocRequest?.documentType ?? 'Document'
    },

    nextDocRequest(): DocumentCloseRequest | null {
      return this.$store.getters[CORE_NEXT_DOC_CLOSE_REQUEST];
    },

    reasonsToClose(): string[] {
      return this.nextDocRequest?.reasonsToClose ?? [];
    },

    showAssignmentWarningOnForce(): boolean {
      return this.nextDocRequest?.showAssignmentWarningOnForce ?? true;
    },
  },

  watch: {
    docRequestCount(): void {
      if (this.docRequestCount > 0 && this.show == false) {
        
        this.renderChooserComponent = true;
        this.$nextTick(() => this.show = true)
      }
    }
  },

  methods: {
    async close(mode: 'cancel'|'save'): Promise<void> {
      if (mode == 'save' && this.reasonToClose && this.nextDocRequest) {
        this.nextDocRequest.resolve(
          new CloseReasonParams(this.reasonToClose, this.forceClose)
        );
      }

      this.forceClose = false;
      this.reasonToClose = null;
      this.show = false;

      if (this.nextDocRequest) {
        await delay(200);
        this.$store.commit(CORE_REMOVE_DOC_CLOSE_REQUEST, 0);
      }
    },
  }
})
