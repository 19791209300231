import { SETTINGS, SETTINGS_EMAIL_ADDRESSES, SETTINGS_IMPORT, SETTINGS_IMPORT_JOB_DETAILS, SETTINGS_IMPORT_JOBS, SETTINGS_ITEMS, SETTINGS_QUOTES, SETTINGS_TICKETS, SETTINGS_WORK_ORDERS } from "../routeNames";
import Vue, { VueConstructor } from "vue";
import { clientCodeExpr } from "./_typesAndConstants";
import { Dictionary } from "vue-router/types/router";
import { InsightAuth } from "insight-auth";
import { RouteConfig } from "vue-router"

const Settings = (): Promise<VueConstructor<Vue>> => 
  import(/* webpackChunkName: "settings" */ '@/components/core/Settings.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const SettingsGeneral = (): Promise<VueConstructor<Vue>> => 
  import(/* webpackChunkName: "settings" */ '@/components/core/SettingsGeneral.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const MailboxAddressManager = (): Promise<VueConstructor<Vue>> => 
  import(/* webpackChunkName: "settings" */ '@/components/mailboxes/MailboxAddressManager.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const QuoteSettings = (): Promise<VueConstructor<Vue>> => 
  import(/* webpackChunkName: "settings" */ '@/components/quotes/settings/QuoteSettings.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

  const TicketSettings = (): Promise<VueConstructor<Vue>> => 
  import(/* webpackChunkName: "settings" */ '@/components/tickets/settings/TicketSettings.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

  const WorkOrderSettings = (): Promise<VueConstructor<Vue>> => 
  import(/* webpackChunkName: "settings" */ '@/components/workOrders/settings/WorkOrderSettings.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const ItemSettings = (): Promise<VueConstructor<Vue>> => 
  import(/* webpackChunkName: "settings" */ '@/components/items/settings/ItemSettings.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const ImportManager = (): Promise<VueConstructor<Vue>> =>
  import(/* webpackChunkName: "settings" */ '@/components/imports/ImportJobManager.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const ImportJobSectionMessage = (): Promise<VueConstructor<Vue>> =>
  import(/* webpackChunkName: "settings" */ '@/components/imports/ImportJobSectionMessage.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const ImportJobList = (): Promise<VueConstructor<Vue>> =>
  import(/* webpackChunkName: "settings" */ '@/components/imports/ImportJobList.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>

const ImportJobDetails = (): Promise<VueConstructor<Vue>> =>
  import(/* webpackChunkName: "settings" */ '@/components/imports/ImportJobDetails.vue')
  .then(m => m.default) as Promise<VueConstructor<Vue>>


const settingsKey = 'settings:clientCode';

const settingsComponents = {
  default: SettingsGeneral,
  emailAddresses: MailboxAddressManager,
  items: ItemSettings,
  quotes: QuoteSettings,
  tickets: TicketSettings,
  workOrders: WorkOrderSettings,
}

const propsConfig = {
  defaut: false,
  emailAddresses: false,
  items: true,
  quotes: true,
  tickets: true,
  workOrders: true,
}

export const SettingsRoutes = [
  {
    name: SETTINGS,
    path: '/' + clientCodeExpr + '/settings',
    component: Settings,
    redirect: '/' + clientCodeExpr + '/settings/general',
    meta: {
      animationKey: settingsKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        if (params.clientCode) {
          return auth.hasClientAccess(params.clientCode)
        } else {
          return true
        }
      }
    },
    children: [
      {
        path: 'general',
        components: settingsComponents,
        props: propsConfig,
        meta: {
          authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
            if (params.clientCode) {
              return auth.hasClientAccess(params.clientCode)
            } else {
              return true
            }
          }
        },
      },

      {
        name: SETTINGS_EMAIL_ADDRESSES,
        path: 'emailAddresses',
        components: settingsComponents,
        props: propsConfig,
        meta: {
          authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
            return (auth.hasPrivilegeAnyClient("1006500") ||
              auth.hasPrivilegeForClient(params.clientCode, "1006510")) &&
              auth.hasClientAccess(params.clientCode)
          }
        }
      },

      {
        name: SETTINGS_ITEMS,
        path: 'items',
        components: settingsComponents,
        props: propsConfig,
        meta: {
          authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
            return (auth.hasPrivilegeAnyClient("1002000") ||
              auth.hasPrivilegeForClient(params.clientCode, "1002210")) &&
              auth.hasClientAccess(params.clientCode)
          }
        }
      },

      {
        name: SETTINGS_QUOTES,
        path: 'quotes',
        components: settingsComponents,
        props: propsConfig,
        meta: {
          authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
            return (auth.hasPrivilegeAnyClient("1012200") ||
              auth.hasPrivilegeForClient(params.clientCode, "1012220")) &&
              auth.hasClientAccess(params.clientCode)
          }
        }
      },

      {
        name: SETTINGS_TICKETS,
        path: 'tickets',
        components: settingsComponents,
        props: propsConfig,
        meta: {
          authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
            return (auth.hasPrivilegeAnyClient("1012200") ||
              auth.hasPrivilegeForClient(params.clientCode, "1012240")) &&
              auth.hasClientAccess(params.clientCode)
          }
        }
      },

      {
        name: SETTINGS_WORK_ORDERS,
        path: 'workOrders',
        components: settingsComponents,
        props: propsConfig,
        meta: {
          authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
            return (auth.hasPrivilegeAnyClient("1012200") ||
              auth.hasPrivilegeForClient(params.clientCode, "1012230")) &&
              auth.hasClientAccess(params.clientCode)
          }
        }
      },
    ]
  },
  {
    path: '/' + clientCodeExpr + '/settings/import',
    component: ImportManager,
    props: true,
    meta: {
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1011100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1011110")) &&
          auth.hasClientAccess(params.clientCode)
      }
    },
    children: [
      {
        name: SETTINGS_IMPORT,
        path: '',
        component: ImportJobSectionMessage,
        props: true,
      },
      {
        name: SETTINGS_IMPORT_JOBS,
        path: ':importType(items|itemcategories|customers)',
        component: ImportJobList,
        props: true,
      },
      {
        name: SETTINGS_IMPORT_JOB_DETAILS,
        path: ':importType(items|itemcategories|customers)/:jobId([0-9]+)',
        component: ImportJobDetails,
        props: true,
      },
    ]
  }
] as RouteConfig[];