export function getIconNameForEntityType(entityType: string): string {
  const lowerEntity = entityType.toLowerCase();

  if (lowerEntity == 'assignment') {
    return 'mdi-clipboard-account-outline';
  } else if (lowerEntity == 'comment') {
    return 'mdi-comment-outline';
  } else if (lowerEntity == 'customer') {
    return 'mdi-account-tie';
  } else if (lowerEntity == 'file') {
    return 'mdi-file-outline';
  } else if (lowerEntity == 'item') {
    return 'mdi-cube-outline';
  } else if (lowerEntity == 'message') {
    return 'mdi-email-outline';
  } else if (lowerEntity == 'quote') {
    return 'mdi-calculator';
  } else if (lowerEntity == 'ticket') {
    return 'mdi-tag-text-outline';
  } else if (lowerEntity == 'workorder') {
    return 'mdi-hammer-wrench';
  } else {
    return 'mdi-help-circle-outline';
  }
}

export function getColorNameForEntityType(entityType: string): string {
  const lowerEntity = entityType.toLowerCase();

  if (lowerEntity == 'comment') {
    return '';
  } else if (lowerEntity == 'customer') {
    return '';
  } else if (lowerEntity == 'file') {
    return '';
  } else if (lowerEntity == 'item') {
    return '';
  } else if (lowerEntity == 'message') {
    return '';
  } else if (lowerEntity == 'quote') {
    return 'primary';
  } else if (lowerEntity == 'ticket') {
    return 'info';
  } else if (lowerEntity == 'workorder') {
    return 'success';
  } else {
    return '';
  }
}