
import * as gt from '../../store/getterNames'
import { DefaultWorkOrderQuery, WorkOrderRevenueSummaryQuery, WorkOrderSearchFieldDefs, WorkOrderStatusReportQuery } from "./WorkOrderSearchFieldDefs";
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames';
import { fetchSetting, SettingName } from '@/util/settingsApi';
import { Format, ReportProps } from '@/models/core/reports/ReportProps';
import { DataTableHeader } from 'vuetify'
import EntityManager from '../common/EntityManager.vue'
import { EntityManagerInterface } from '@/models/core/entityManager/EntityManagerInterface';
import { moneyFormatter } from '@/util/ValueFormatter'
import { PropValidator } from 'vue/types/options';
import reformatDate from '@/util/reformatDate'
import ReportMenu from "../common/ReportMenu.vue"
import { SearchUIFieldDef } from '@/models/core/searchQueries/SearchUIOption';
import Vue from 'vue'
import { WORK_ORDER_EDITOR } from '@/router/routeNames';
import { WorkOrder } from '../../models/workOrders/WorkOrder'
import { WorkOrderListModel } from "@/models/workOrders/WorkOrderListModel";
import WorkOrderNew from './WorkOrderNew.vue'
import WorkOrderStatusChip from './WorkOrderStatusChip.vue'

export default Vue.extend({
  components: {
    EntityManager,
    ReportMenu,
    WorkOrderNew,
    WorkOrderStatusChip,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    useAsChooser: Boolean,
    
    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<WorkOrderListModel|null>,
  },

  data () {
    return {
      defaultQuery: DefaultWorkOrderQuery,
      linkedQuoteIsRequired: true,
      showNewWorkOrderDlg: false,
    }
  },

  computed: {
    canCreateWorkOrders(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1009200") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1009210")
    },

    canEditWorkOrders(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1009300") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1009310")
    },

    canViewWorkOrders(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1009100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1009120")
    },

    canViewWorkOrderReports(): boolean {
      return this.canViewWorkOrderValue && !this.useAsChooser;
    },

    canViewWorkOrderValue(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1009100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1009110")
    },

    editorRoute(): string {
      return WORK_ORDER_EDITOR
    },

    hideNewWorkOrderButton(): boolean {
      return this.linkedQuoteIsRequired || this.useAsChooser;
    },
    
    openWorkOrders(): WorkOrder[] {
      return this.$store.getters[gt.WORK_ORDERS_OPEN_FOR_REF](this.clientCode, this.refName)
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    },

    searchFieldDefs(): SearchUIFieldDef[] {
      const searchDefs = WorkOrderSearchFieldDefs;

      if (!this.canViewWorkOrderValue) {
        let idx = searchDefs.findIndex(x => x.fieldName == 'quotedPrice');
        searchDefs.splice(idx, 1);

        idx = searchDefs.findIndex(x => x.fieldName == 'discountableAmount');
        searchDefs.splice(idx, 1);

        idx = searchDefs.findIndex(x => x.fieldName == 'primaryDiscountPercent');
        searchDefs.splice(idx, 1);

        idx = searchDefs.findIndex(x => x.fieldName == 'secondaryDiscountPercent');
        searchDefs.splice(idx, 1);

        idx = searchDefs.findIndex(x => x.fieldName == 'effectiveDiscountPercent');
        searchDefs.splice(idx, 1);

        idx = searchDefs.findIndex(x => x.fieldName == 'effectiveDiscountAmt');
        searchDefs.splice(idx, 1);

        idx = searchDefs.findIndex(x => x.fieldName == 'salesValue');
        searchDefs.splice(idx, 1);
      }

      return searchDefs;
    },

    tableHeaders(): DataTableHeader[] {
      const headers = [
        { text: 'Work Order #', value: 'workOrderNumber', width: '130px' },
        { text: 'Customer', value: 'customer.name'},
        { text: 'Name', value: 'name' },
        { text: 'Entered', value: 'createdAt', width: '120px' },
        { text: 'Closed', value: 'closedAt', width: '120px' },
        { text: 'Target', value: 'targetDate', width: '120px' },
        { text: 'Category', value: 'category.name', width: '150px' },
        { text: 'Value', value: 'salesValue', align: 'end', width: '120px' },
        { text: 'Priority', value: 'priority', width: '100px' },
        { text: 'Status', value: 'status', width: '100px' }
      ] as DataTableHeader[];

      if (this.useAsChooser) {
        return headers.filter(x => x.value == 'workOrderNumber'
          || x.value == 'customer.name'
          || x.value == 'name'
          || x.value == 'status'
          || x.value == 'createdAt')
      } else {
        return headers;
      }
    },

    workOrderReportList(): ReportProps[] {
      return [
        new ReportProps(
          "Work Order Status Report",
          "mdi-tag-outline",
          "WorkOrderStatusReport",
          `${this.clientCode}/workorders/reports/status`,
          WorkOrderStatusReportQuery
        ),

        new ReportProps(
          "Scheduled Revenue Summary",
          "mdi-cash",
          "ScheduledRevenueSummary",
          `${this.clientCode}/workorders/reports/revenue`,
          WorkOrderRevenueSummaryQuery,
          Format.PdfOnly,
          true
        ),
      ]
    },

    workOrders(): WorkOrderListModel[] {
      return this.$store.getters[gt.WORK_ORDERS_LISTED_FOR_REF](this.clientCode, this.refName)
    },
  },

  async created(): Promise<void> {
    this.linkedQuoteIsRequired = await fetchSetting<boolean>(this.clientCode, SettingName.WorkOrderRequireLinkedQuote, true);
  },

  methods: {
    formatDate(input: Date|string|null|undefined): string {
      return reformatDate(input, 'mm/dd/yyyy');
    },

    formatDateOnly(input: Date|string|null|undefined): string {
      return reformatDate(input, 'mm/dd/yyyy', false, true);
    },

    formatMoney(input: string|null|undefined): string {
      return moneyFormatter(input || 0);
    },

    async unloadEntities(skipRedirect = false): Promise<boolean> {
      return await (this.$refs["mgr"] as unknown as EntityManagerInterface)?.unloadEntities(skipRedirect);
    },
  }
})
