import InsightUser from "../core/InsightUser";
import UpdatableEntity from "../core/entities/UpdatableEntity";
import { WorkOrderStepConfig } from "./WorkOrderStepConfig";

export class WorkOrderStep extends UpdatableEntity {
  public type = '';
  public name = '';
  public assignedBy = '';
  public assignedAt?: string;
  public startedBy = '';
  public startedAt?: string;
  public pausedBy = '';
  public pausedAt?: string;
  public finishedBy = '';
  public finishedAt?: string;
  public skippedBy = '';
  public skippedAt?: string;
  public assignedToUser = new InsightUser();
  public status: 'New'|'Assigned'|'In Progress'|'Paused'|'Done'|'Skipped' = 'New';
  public position = 0;
  public sortIndex = 0;

  public populateFromWorkOrderStepConfig(c: WorkOrderStepConfig): void {
    this.type = c.type;
    this.name = c.name;
    this.position = c.position;
  }
}