
import { ChecklistItem } from '@/models/assignments/Assignment';
import { PropValidator } from 'vue/types/options';
import Vue from 'vue';

export default Vue.extend({
  components: {
  },

  props: {
    readonly: Boolean,

    value: {
      type: Array,
      default: null,
    } as PropValidator<ChecklistItem[]>,
  },

  methods: {
    handleValueChanged(newValue: boolean, idx: number): void {
      var oldItem = this.value[idx];
      const items = [...this.value];
      items.splice(idx, 1, new ChecklistItem(oldItem.position, oldItem.text, newValue));
      this.$emit('input', items);
    },
  }
})
