/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from '../routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { clientCodeExpr } from './_typesAndConstants'
import { InsightAuth } from 'insight-auth'
import { VueConstructor } from 'vue'

const FileManager = (): Promise<VueConstructor<any>> =>
  import(/* webpackChunkName: "file" */ '@/components/files/FileManager.vue')
  .then(m => m.default) as any

const FileViewer = (): Promise<VueConstructor<any>> => 
  import(/* webpackChunkName: "file" */ '@/components/files/FileViewerStandalone.vue')
  .then(m => m.default) as any

const fileKey = 'files:clientCode';

export const FileRoutes = [
  {
    name: rt.FILES,
    path: '/' + clientCodeExpr + '/files',
    component: FileManager,
    props: true,
    meta: {
      animationKey: fileKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1004100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1004110")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },

  {
    name: rt.FILE_VIEWER,
    path: '/' + clientCodeExpr + '/files/:id([0-9]+)',
    component: FileViewer,
    props: true,
    meta: {
      animationKey: fileKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1004100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1004110")) &&
          auth.hasClientAccess(params.clientCode)
      }
    }
  },
] as RouteConfig[];