
import { CORE_CLIENT_CURRENT, CORE_DOC_LINK_REQUEST_COUNT, CORE_NEXT_DOC_LINK_REQUEST } from '@/store/getterNames'
import { CORE_REMOVE_DOC_LINK_REQUEST } from '@/store/mutationNames';
import delay from '@/util/delay';
import DocumentChooser from '../common/DocumentChooser.vue'
import DocumentChooserRequest from '@/models/core/DocumentChooserRequest';
import EntityLinkRequest from '@/models/core/entities/EntityLinkRequest';
import Vue from 'vue'

export default Vue.extend({
  components: {
    DocumentChooser,
  },

  data() {
    return {
      linkRequest: null as EntityLinkRequest|null,
      renderChooserComponent: false,
      show: false,
      unloaderTimeout: null as NodeJS.Timeout|null,
    }
  },

  computed: {
    docRequestCount(): number {
      return this.$store.getters[CORE_DOC_LINK_REQUEST_COUNT];
    },

    nextDocRequest(): DocumentChooserRequest | null {
      return this.$store.getters[CORE_NEXT_DOC_LINK_REQUEST];
    },

    clientCode(): string {
      const currentClientCode = (this.$store.getters[CORE_CLIENT_CURRENT] || {}).code ?? ''
      return this.nextDocRequest?.clientCode || currentClientCode;
    },

    hideQuotes(): boolean {
      return this.nextDocRequest?.hideQuotes || false;
    },

    hideTickets(): boolean {
      return this.nextDocRequest?.hideTickets || false;
    },

    hideWorkOrders(): boolean {
      return this.nextDocRequest?.hideWorkOrders || false;
    },
  },

  watch: {
    docRequestCount(): void {
      if (this.docRequestCount > 0 && this.show == false) {
        if (this.unloaderTimeout != null) {
          clearTimeout(this.unloaderTimeout);
          this.unloaderTimeout == null;
        }

        this.renderChooserComponent = true;
        this.$nextTick(() => this.show = true)
      }
    }
  },

  methods: {
    async dismiss(): Promise<void> {
      if (this.nextDocRequest) {
        this.nextDocRequest.resolve(this.linkRequest);

        this.linkRequest = null;
        await delay(200);
        this.$store.commit(CORE_REMOVE_DOC_LINK_REQUEST, 0);
        this.unloaderTimeout = setTimeout(
          () => {this.renderChooserComponent = false; this.unloaderTimeout = null},
          120000
        );
      }
    },

    handleDocumentLinkRequested(doc: EntityLinkRequest) {
      this.linkRequest = doc;
    },
  }
})
