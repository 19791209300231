import '@mdi/font/css/materialdesignicons.css'
import '@uublue/vue-excel-viewer/lib/vue-excel-viewer.css'
import './plugins/auth'
import './plugins/router'
import 'vue-file-agent/dist/vue-file-agent.css';

import { VAlert, VBadge, VBtn, VCard, VCardActions, VCardText, VChip, VCol, VDialog, VExpansionPanel, VExpansionPanelContent, VExpansionPanelHeader,
  VExpansionPanels, VIcon, VList, VListItem, VListItemContent, VListItemIcon, VListItemTitle,
  VMenu, VRow, VSheet, VSpacer, VSwitch, VSystemBar, VTextarea, VTextField, VTooltip } from 'vuetify/lib'
import App from './components/core/App.vue'
import AssignmentRowIcons from './components/assignments/AssignmentRowIcons.vue'
import IconButton from './components/common/IconButton.vue'
import IconWithTooltip from './components/common/IconWithTooltip.vue'
import LinkedEntityChip from './components/common/LinkedEntityChip.vue'
import NumberField from './components/common/forms/NumberField.vue'
import panZoom from 'vue-panzoom'
import router from './router/router'
import StatusKanbanCard from './components/common/StatusKanban/StatusKanbanCard.vue'
import store from './store'
import TextHighlighter from './components/common/TextHighlighter.vue'
import UserAvatar from './components/common/UserAvatar.vue'
import { VNode } from 'vue/types/umd'
import Vue from 'vue'
import VueExcelViewer from '@uublue/vue-excel-viewer'
import VueFileAgent from 'vue-file-agent';
import vuetify from './plugins/vuetify'
import WorkOrderStepTypeChip from './components/workOrders/WorkOrderStepTypeChip.vue'

Vue.config.productionTip = false

Vue.component('VAlert', VAlert);
Vue.component('VBadge', VBadge);
Vue.component('VBtn', VBtn);
Vue.component('VCard', VCard);
Vue.component('VCardActions', VCardActions);
Vue.component('VCardText', VCardText);
Vue.component('VChip', VChip);
Vue.component('VCol', VCol);
Vue.component('VDialog', VDialog);
Vue.component('VIcon', VIcon);
Vue.component('VExpansionPanel', VExpansionPanel);
Vue.component('VExpansionPanelContent', VExpansionPanelContent);
Vue.component('VExpansionPanelHeader', VExpansionPanelHeader);
Vue.component('VExpansionPanels', VExpansionPanels);
Vue.component('VList', VList);
Vue.component('VListItem', VListItem);
Vue.component('VListItemContent', VListItemContent);
Vue.component('VListItemIcon', VListItemIcon);
Vue.component('VListItemTitle', VListItemTitle);
Vue.component('VMenu', VMenu);
Vue.component('VRow', VRow);
Vue.component('VSheet', VSheet);
Vue.component('VSpacer', VSpacer);
Vue.component('VSwitch', VSwitch);
Vue.component('VSystemBar', VSystemBar);
Vue.component('VTextField', VTextField);
Vue.component('VTextarea', VTextarea);
Vue.component('VTooltip', VTooltip);

Vue.component('AssignmentRowIcons', AssignmentRowIcons);
Vue.component('IconButton', IconButton);
Vue.component('IconWithTooltip', IconWithTooltip);
Vue.component('LinkedEntityChip', LinkedEntityChip);
Vue.component('NumberField', NumberField);
Vue.component('StatusKanbanCard', StatusKanbanCard);
Vue.component('TextHighlighter', TextHighlighter);
Vue.component('UserAvatar', UserAvatar);
Vue.component('WorkOrderStepTypeChip', WorkOrderStepTypeChip);

Vue.use(panZoom);
Vue.use(VueExcelViewer);
Vue.use(VueFileAgent);

new Vue({
  store,
  router,
  vuetify,
  render: (h): VNode => h(App)
}).$mount('#app')
