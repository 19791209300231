import * as gt from '../../store/getterNames'
import { NavTileFactory } from '@/models/core/NavTiles'
import {WorkOrder} from '@/models/workOrders/WorkOrder'
import { WorkOrderListModel } from '@/models/workOrders/WorkOrderListModel'

const getWorkOrderObject: NavTileFactory = (client, getters, auth) => {
  const workOrderUrl = '/' + client + '/workOrders'
  const workOrdersAreLoaded = (getters[gt.WORK_ORDERS_FOR_CURRENT_CLIENT] as WorkOrderListModel[]).length > 0
  const openWorkOrders: WorkOrder[] = getters[gt.WORK_ORDERS_OPEN_FOR_CURRENT_CLIENT] as WorkOrder[]
  const workOrderChildren = []

  if (workOrdersAreLoaded) {
    workOrderChildren.push({ name: 'Work Order List', url: workOrderUrl })
  }

  openWorkOrders.forEach(workOrder => {
    workOrderChildren.push({ name: workOrder.workOrderNumber, url: workOrderUrl + '/' + workOrder.workOrderNumber })
  })

  return {
    title: 'Work Orders',
    url: workOrderUrl,
    icon: 'mdi-hammer-wrench',
    isExact: false,
    showBadge: workOrderChildren.length > 1,
    badgeCount: workOrderChildren.length,
    enableMenu: workOrderChildren.length > 1,
    loaded: workOrdersAreLoaded,
    children: workOrderChildren,
    enableTile: auth.hasFeatureForClient(client, "1002000")
  }
}

export default getWorkOrderObject;