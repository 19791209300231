import * as gt from '../../store/getterNames'
import { NavTileFactory } from '@/models/core/NavTiles'
import { WORK_ORDER_KANBAN_REF } from '@/store/refNames'
import { WorkOrderListModel } from '@/models/workOrders/WorkOrderListModel'

const getWorkOrderKanbanObject: NavTileFactory = (client, getters, auth) => {
  const workOrderUrl = '/' + client + '/boards/workOrders/current'
  const workOrderGetter = getters[gt.WORK_ORDERS_LISTED_FOR_REF] as (client: string, ref: string) => WorkOrderListModel[]
  const workOrdersAreLoaded = workOrderGetter(client, WORK_ORDER_KANBAN_REF).length > 0

  

  return {
    title: 'Work Orders Kanban',
    url: workOrderUrl,
    icon: 'mdi-view-week',
    isExact: false,
    showBadge: false,
    badgeCount: 0,
    enableMenu: false,
    loaded: workOrdersAreLoaded,
    children: [],
    enableTile: auth.hasFeatureForClient(client, "1002000")
  }
}

export default getWorkOrderKanbanObject;