
import { AssignedToSpec, FinishedSpec, SkippedSpec } from '../../models/assignments/Specs'
import { Assignment, AssignmentListModel } from '@/models/assignments/Assignment'
import { ASSIGNMENT_ADD_REFERRER, ASSIGNMENT_DROP_REFERRER, ASSIGNMENT_REMOVE_ENTITY_FROM_REF, ASSIGNMENT_STORE_ENTITY_FOR_REF, CORE_GENERIC_PAGER_CREATE, CORE_GENERIC_PAGER_DESTROY, CORE_GENERIC_PAGER_PAGE_DATA_SET } from '@/store/mutationNames'
import {
  ASSIGNMENT_REFERRER_EXISTS,
  ASSIGNMENTS_LISTED_FOR_REF,
  ASSIGNMENTS_OPEN_FOR_REF,
  CORE_CLIENT_CURRENT,
  CORE_GET_CURRENT_USER,
  CORE_GET_GENERIC_PAGER,
  CORE_GET_LIFECYCLE_FOR_TYPE,
  CORE_GET_LIFECYCLE_HASH_FOR_TYPE,
} from '@/store/getterNames'
import { ASSIGNMENT_SEARCH, CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED } from '@/store/actionNames'
import { SearchUIFieldType, SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption'
import api from '@/util/api'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import { ASSIGNMENT_KANBAN } from '@/router/routeNames'
import { ASSIGNMENT_WIDGET_REF } from '@/store/refNames'
import AssignmentPreviewer from './AssignmentPreviewer.vue'
import AssignmentRowActionUi from './AssignmentRowActionUI.vue'
import AssignmentRowDueDate from './AssignmentRowDueDate.vue'
import AssignmentRowIcons from './AssignmentRowIcons.vue'
import Client from '@/models/core/Client'
import { DataTableHeader } from 'vuetify'
import { DeletedSpec } from '@/models/core/spec/DeletedSpec'
import { GenericPager } from '@/models/core/entities/EntityPagingData'
import { getValFromObjectOrChild } from '@/util/getVal'
import InsightUser from '@/models/core/InsightUser'
import { Lifecycle } from '@/models/lifecycles/Lifecycle'
import { LifecycleHash } from '@/models/lifecycles/LifecycleHash'
import LinkedEntityChip from '../common/LinkedEntityChip.vue'
import notifications from '@/util/notifications'
import PrivateEntitySpec from '@/models/core/entities/PrivateEntitySpec'
import PromiseCompleter from '@/models/core/PromiseCompleter'
import reformatDate from '@/util/reformatDate'
import StatusChip from '../lifecycles/StatusChip.vue'
import UserChooser from '../common/UserChooser.vue'
import { UserIdListQueryVarSet } from '@/models/core/searchQueries/UserIdListQueryVarSet'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'
import VuexEntityRefSpec from '@/models/core/vuex/VuexEntityRefSpec'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest'
import { VuexSearchRequest } from '@/models/core/vuex/VuexSearchRequest'

export default Vue.extend({
  components: {
    AssignmentPreviewer,
    AssignmentRowActionUi,
    AssignmentRowDueDate,
    AssignmentRowIcons,
    LinkedEntityChip,
    StatusChip,
    UserChooser,
  },

  props: {

  },

  data() {
    return {
      headers: [
        {
          value: 'associatedEntity',
          text: 'Document',
          sortable: false,
          width: 120,
        },
        {
          value: 'name',
          text: 'Assignment',
        },
        {
          value: 'dueAt',
          text: 'Due At',
        },
        {
          value: 'checklistComplete',
          text: 'Info',
          width: 100,
          sortable: false,
        },
        {
          value: 'status',
          text: 'Status',
          width: 120,
        },
        {
          value: 'id',
          text: 'Actions',
          width: 100,
          sortable: false,
        },
      ] as DataTableHeader[],
      loadingEntityList: false,
      loadingEntityPreview: false,
      referenceDate: new Date(),
      referenceDateTimer: null as NodeJS.Timer|null,
      showUserChooser: false,
      tryFinishActionAfterPreviewPromise: null as PromiseCompleter<boolean>|null,
      userChooserPromise: null as PromiseCompleter<InsightUser|null>|null,
    }
  },

  computed: {
    canCreateAssignments(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013200") ||
             this.$auth.hasPrivilegeForClient(this.clientCode, "1013210")
    },

    canViewAssignments(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013100") ||
             this.$auth.hasPrivilegeForClient(this.clientCode, "1013110")
    },

    clientCode(): string {
      const client = this.$store.getters[CORE_CLIENT_CURRENT] as Client;
      if (client) {
        return client.code;
      } else {
        return ''
      }
    },

    clientPageCount(): number {
      return Math.ceil((this.pager?.serverSideEntityCount ?? this.entities.length) / this.clientPageSize);
    },
    
    clientPageNumber: {
      get(): number {
        return this.pager?.clientPageNumber ?? 1
      },

       set(value: number) {
          if (this.pager) {
            const newPager = Object.assign({}, this.pager);
            newPager.clientPageNumber = value;
            this.$store.commit(CORE_GENERIC_PAGER_CREATE, newPager);
          }
       }
    },

    clientPageSize(): number{
      return 5;
    },
    
    currentEntityPage(): AssignmentListModel[] {
      const clientPageIdx = this.clientPageNumber - 1;
      const clientPageFirstRecord = (clientPageIdx * this.clientPageSize);
      const clientPageLastRecord = (clientPageIdx + 1) * this.clientPageSize;

      const offset = (this.pager?.serverPageNumber ?? 0) * (this.pager?.serverPageSize ?? 50);

      const startIdx = Math.max(clientPageFirstRecord - offset, 0);
      const endIdx = Math.max(clientPageLastRecord - offset, 0);

      return this.sortedEntities.slice(startIdx, endIdx);
    },

    currentSortDesc: {
      get(): boolean {
        return this.pager?.sortByDesc ?? false
      },

       set(value: boolean) {
          if (this.pager) {
            const newPager = Object.assign({}, this.pager);
            newPager.sortByDesc = value;
            this.$store.commit(CORE_GENERIC_PAGER_CREATE, newPager);
          }
       }
    },

    currentSortField: {
      get(): string {
        return this.pager?.sortBy ?? 'dueAt'
      },

      set(value: string) {
        if (this.pager) {
          const newPager = Object.assign({}, this.pager);
          newPager.sortBy = value;
          this.$store.commit(CORE_GENERIC_PAGER_CREATE, newPager);
        }
      }
    },

    currentUserId(): string {
      return (this.$store.getters[CORE_GET_CURRENT_USER] as InsightUser).id;
    },

    entities(): AssignmentListModel[] {
      return this.$store.getters[ASSIGNMENTS_LISTED_FOR_REF](this.clientCode, this.refName);
    },

    entityCountForQuery(): number {
      return this.pager?.serverSideEntityCount ?? 0;
    },
    
    entityPreview(): Assignment|null {
      return this.$store.getters[ASSIGNMENTS_OPEN_FOR_REF](this.clientCode, this.refName)[0] as Assignment || null;
    },

    firstRowNumberInList(): number {
      return ((this.pager?.serverPageNumber ?? 0) * (this.pager?.serverPageSize ?? 50)) + 1;
    },

    firstRowNumberOnScreen(): number {
      return this.entityCountForQuery == 0 ? 0 : (this.clientPageSize * (this.clientPageNumber - 1)) + 1;
    },

    kanbanLink(): unknown {
      return {
        name: ASSIGNMENT_KANBAN,
        params: {
          clientCode: this.clientCode,
        }
      }
    },

    lastRowNumberInList(): number {
      const lastInPage = ((this.pager?.serverPageNumber ?? 0) + 1) * (this.pager?.serverPageSize ?? 50);
      const realLastRowNum = Math.min(lastInPage, this.entityCountForQuery);
      return realLastRowNum;
    },

    lastRowNumberOnScreen(): number {
      const lastInPage = (this.clientPageNumber) * this.clientPageSize;
      return Math.min(lastInPage, this.entityCountForQuery);
    },

    lifecycle(): Lifecycle {
      return this.$store.getters[CORE_GET_LIFECYCLE_FOR_TYPE]("Assignment") ?? new Lifecycle();
    },

    lifecycleHash(): LifecycleHash {
      return this.$store.getters[CORE_GET_LIFECYCLE_HASH_FOR_TYPE]("Assignment") ?? {stages: {}, statuses: {}}
    },

    pager(): GenericPager|null {
      return this.$store.getters[CORE_GET_GENERIC_PAGER](this.pagerName) ?? null
    },

    pagerName(): string {
      return "AssignmentWidgetPager"
    },

    referrerExists(): boolean {
      return this.$store.getters[ASSIGNMENT_REFERRER_EXISTS](this.refName);
    },

    refName(): string {
      return ASSIGNMENT_WIDGET_REF
    },

    showEntityPreview(): boolean {
      return !!this.entityPreview;
    },

    sortedEntities(): AssignmentListModel[] {
      return this.entities.slice().sort((a, b) => {
        const aVal = getValFromObjectOrChild<string|number>(a, this.currentSortField) ?? "";
        const bVal = getValFromObjectOrChild<string|number>(b, this.currentSortField) ?? "";

        if (aVal > bVal) {
          return this.currentSortDesc ? -1 : 1;
        } else if (aVal < bVal) {
          return this.currentSortDesc ? 1 : -1;
        } else {
          return 0;
        }
      })
    },
  },

  created() {
    if (!this.referrerExists) {
      this.configurePager();
      this.configureEntityReferrer();
      this.loadEntities();
    }

    this.referenceDateTimer = setInterval(() => this.referenceDate = new Date(), 30000)

    this.$store.dispatch(CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED, "Assignment");
  },

  beforeDestroy() {
    if (this.referenceDateTimer != null) {
      clearInterval(this.referenceDateTimer);
    }
  },

  methods: {
    checkFetchServerPage(newClientPageNumber: number): void {
      const firstRequestedRecord = ((newClientPageNumber -1) * this.clientPageSize) + 1;
      const firstLoadedRecord = this.firstRowNumberInList;
      const lastLoadedRecord = this.lastRowNumberInList;

      if (firstRequestedRecord > lastLoadedRecord || firstRequestedRecord < firstLoadedRecord) {
        const requiredServerPage = Math.floor(firstRequestedRecord / (this.pager?.serverPageSize ?? 50));
        this.loadEntities(requiredServerPage);
      }
    },

    chooseUser(): Promise<InsightUser|null> {
      return new Promise<InsightUser | null>((resolve, reject) => {
        this.userChooserPromise = new PromiseCompleter<InsightUser | null>(resolve, reject);

        this.showUserChooser = true;
      });
    },

    async closeEntityPreviewer(): Promise<void> {
      if (this.tryFinishActionAfterPreviewPromise != null) {
        const assignment = this.entities.find(x => x.id == this.entityPreview?.id);

        this.tryFinishActionAfterPreviewPromise.resolve(assignment?.checklistComplete ?? false);
      }

      this.tryFinishActionAfterPreviewPromise = null;

      this.setEntityPreview(null);
    },

    configureEntityReferrer(): void {
      if (!this.canViewAssignments) return;

      const spec = new AssignedToSpec(this.currentUserId)
        .and(new DeletedSpec().not())
        .and(new FinishedSpec().not())
        .and(new SkippedSpec().not());

      const referrer = new VuexEntityReferer(
        this.clientCode,
        this.refName,
        (entity, mode) =>  {
          if (mode == 'listModel'){
            const assignment = entity as Assignment;

            return spec.isSatisfiedBy(assignment) ? 'yes' : 'no';
          }

          return 'noChange';
        }
      );

      this.$store.commit(ASSIGNMENT_ADD_REFERRER, referrer)
    },

    configurePager(): void {
      if (this.pager == null) {
        const pager = new GenericPager(this.pagerName, this.currentSortField, this.currentSortDesc);
        this.$store.commit(CORE_GENERIC_PAGER_CREATE, pager);
      }
    },

    dropEntityReferrer(): void {
      this.$store.commit(ASSIGNMENT_DROP_REFERRER, {client: this.clientCode, name: this.refName});
      this.$store.commit(CORE_GENERIC_PAGER_DESTROY, this.pagerName);
    },

    formatDateTimeOffset(createdDate: Date|string|null|undefined): string {
      return reformatDate(createdDate, 'mm/dd/yyyy hh:MM tt');
    },
    
    handleSortClick(): void {
      this.loadEntities(0);
    },

    handleUserSelected(user: InsightUser|null): void {
      if (this.userChooserPromise) {
        this.userChooserPromise.resolve(user);
        this.userChooserPromise = null;
      }
    },

    async loadEntities(requiredPage: false|number = false): Promise<void> {
      if (this.canViewAssignments == false) return;

      this.loadingEntityList = true;

      const query = [
        new SearchUIFieldValue(
          'status',
          SearchUIFieldType.StringSet,
          ["Assigned","In Progress","Paused"]
        ),
        new SearchUIFieldValue(
          'assignedToUser',
          SearchUIFieldType.UserIdSet,
          new UserIdListQueryVarSet([{id: this.currentUserId} as InsightUser], false, false)
        )
      ] as SearchUIFieldValue[];

      const pageSize = this.pager?.serverPageSize ?? 50;
      let pageNumber = 0;

      if (requiredPage === false) {
        pageNumber = 0;
        this.clientPageNumber = 1;
      } else {
        pageNumber = requiredPage;
      }

      const request = new VuexSearchRequest(
        this.refName,
        query,
        false,
        this.clientCode,
        this.pagerName,
        pageSize,
        pageNumber,
        CORE_GENERIC_PAGER_PAGE_DATA_SET,
        this.currentSortField,
        this.currentSortDesc,
      )

      try {
        await this.$store.dispatch(ASSIGNMENT_SEARCH, request);
      } catch (error) {
        const e = error as ApiResponse;
        notifications.warn(this.$store, `There was an error while loading the assignments. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
      }

      this.loadingEntityList = false;
    },

    async previewEntity(assignment: AssignmentListModel): Promise<void> {
      this.loadingEntityPreview = true;

      const url = `${this.clientCode}/assignments/${assignment.id}`
      const apiResponse = await api.get(url)

      if (apiResponse) {
        if (!apiResponse.error) {
          this.setEntityPreview(apiResponse.data as Assignment);
        } else {
          const e = apiResponse;
          notifications.warn(this.$store, `Failed to load assignment. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
        }
      }

      this.loadingEntityPreview = false;
    },

    setEntityPreview(newEntity: Assignment|null): void {
      if (this.entityPreview) {
        const spec: VuexEntityRefSpec ={
          refName: this.refName,
          spec: this.entityPreview as PrivateEntitySpec,
        }

        this.$store.commit(ASSIGNMENT_REMOVE_ENTITY_FROM_REF, spec);
      }

      if (newEntity) {
        const request: VuexEntityStorageRequest<Assignment> = {
          refName: this.refName,
          entity: newEntity
        }

        this.$store.commit(ASSIGNMENT_STORE_ENTITY_FOR_REF, request)
      }
    },

    showChecklistForFinish(assignment: AssignmentListModel): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        this.tryFinishActionAfterPreviewPromise = new PromiseCompleter<boolean>(resolve, reject);

        this.previewEntity(assignment);
      });
    },

    unload(): void {
      this.dropEntityReferrer();
      this.$store.commit(CORE_GENERIC_PAGER_DESTROY, this.pagerName);
    }
  }
})
