export default class Address {
    public line1 = '';
    public line2 = '';
    public city = '';
    public state = '';
    public zip = '';
    public country = '';

    public static clone(a: Address): Address {
        const b = new Address();

        b.line1 = a.line1;
        b.line2 = a.line2;
        b.city = a.city;
        b.state = a.state;
        b.zip = a.zip;
        b.country = a.country;

        return b;
    }
}