
import * as ac from '../../store/actionNames'
import * as rt from '../../router/routeNames'
import EntityNew from '../common/EntityNew.vue'
import QuoteNewFieldSet from './QuoteNewFieldSet.vue'
import Vue from 'vue'

export default Vue.extend({

  components: {
    EntityNew,
    QuoteNewFieldSet
  },

  props: {
    client: {
      type: String,
      default: ''
    },

    show: Boolean,
  },

  data () {
    return {
      newQuote: {
        name: '',
        customer: {
          id: '',
          name: '',
        },
        salesRep: {
          name: '',
          address: '',
        },
        customerRep: {
          name: '',
          address: '',
        },
        probability: "Normal",
      }
    }
  },

  computed: {
    routeName(): string {
      return rt.QUOTE_EDITOR
    },

    saveAction(): string {
      return ac.QUOTE_SAVE
    }
  }
})
