
import AppSidebarNavTile from './AppSidebarNavTile.vue'
import { NavTileOptions } from '@/models/core/NavTiles'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  components: {
    AppSidebarNavTile,
  },

  props: {
    overflowItems: {
      type: Array,
      default: [] as NavTileOptions[],
    } as PropValidator<NavTileOptions[]>,

    minified: Boolean,
  },
  
  data() {
    return {
      menuActivator: null as HTMLElement | null,
      menuX: 0,
      menuY: 0,
      showMenu: false,
    }
  },

  computed: {
    id(): string {
        return 'navTile_overflow'
    },

    isOverflowActive(): boolean {
      return this.overflowItems.reduce<boolean>(
        (v, x) => (x.isExact ? this.$route.path.toLowerCase() == x.url.toLowerCase() :
          this.$route.path.toLowerCase().startsWith(x.url.toLowerCase())) || v, false)
    },

    loaded(): boolean {
      return this.overflowItems.reduce<boolean>((v, x) => x.loaded || v, false);
    },

    badgeCount(): number {
      return this.overflowItems.reduce<number>((v, x) => x.badgeCount + v, 0);
    },

    showBadge(): boolean {
      return this.badgeCount > 0;
    }
  },

  watch: {
    overflowItems: {
      handler(): void {
        this.updateActivator()
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    getBottomYValue(ele: HTMLElement): number {
      let myVal = ele.offsetTop + ele.offsetHeight;

      if (ele.offsetParent) {
        return myVal + this.getBottomYValue(ele.offsetParent as HTMLElement);
      } else {
        return myVal;
      }
    },

    handleClick(): void {
      this.menuX = this.menuActivator?.offsetWidth ?? 0;

      if (this.menuActivator) {
        this.menuY = this.getBottomYValue(this.menuActivator);
      } else {
        this.menuY = 0;
      }

      this.showMenu = !this.showMenu;
    },

    updateActivator(): void {
        this.menuActivator = null
        this.$nextTick(() => { this.menuActivator = document.getElementById(this.id) })
    }
  }
})
