
import { getColorForQuoteStatus, getForegroundColorForStatus } from '@/models/quotes/QuoteStatusColors';

export default {
  props: {
    value: {
      type: String,
      default: '',
    }
  },

  methods: {
    getStatusColor(status: string): string {
      return getColorForQuoteStatus(status)
    },

    getStatusForeground(status: string): string {
      return getForegroundColorForStatus(status);
    }
  }
}
