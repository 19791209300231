import { CORE_INCREMENT_NAV_COUNT } from '@/store/mutationNames'
import { navGuard } from './NavGuard'
import Router from 'vue-router'
import routes from './routes'

const router = new Router({
  mode: 'history',
  routes
})

// Ensure we're authorized before navigating to a route
router.beforeEach((to, from, next) => {
  router.app.$store.commit(CORE_INCREMENT_NAV_COUNT);
  navGuard(router.app.$auth, to, from, next)
})

export default router
