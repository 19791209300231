/* eslint-disable @typescript-eslint/no-explicit-any */
import * as rt from '../routeNames'
import { Dictionary, RouteConfig } from 'vue-router/types/router'
import { clientCodeExpr } from './_typesAndConstants'
import { InsightAuth } from 'insight-auth'
import { VueConstructor } from 'vue'

const QuoteManager = (): Promise<VueConstructor<any>> =>
  import(/* webpackChunkName: "quote" */ '@/components/quotes/QuoteManager.vue')
  .then(m => m.default) as any
const QuoteEditor = (): Promise<VueConstructor<any>> =>
  import(/* webpackChunkName: "quote" */ '@/components/quotes/QuoteEditor.vue')
  .then(m => m.default) as any

const quoteKey = 'quotes:clientCode';

export const QuoteRoutes = [
  {
    name: rt.QUOTES,
    path: '/' + clientCodeExpr + '/quotes',
    component: QuoteManager,
    props: true,
    meta: {
      animationKey: quoteKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1003100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1003110")) &&
          auth.hasClientAccess(params.clientCode) &&
          auth.hasFeatureAccess(params.clientCode, "1001000", "1003000")
      }
    }
  },

  {
    name: rt.QUOTE_EDITOR,
    path: '/' + clientCodeExpr + '/quotes/:quoteNumber([a-zA-Z0-9]+)',
    component: QuoteEditor,
    props: true,
    meta: {
      animationKey: quoteKey,
      authCheck (auth: InsightAuth, params: Dictionary<string>): boolean {
        return (auth.hasPrivilegeAnyClient("1003100") ||
          auth.hasPrivilegeForClient(params.clientCode, "1003110")) &&
          auth.hasClientAccess(params.clientCode) &&
          auth.hasFeatureAccess(params.clientCode, "1001000", "1003000")
      }
    }
  },
] as RouteConfig[];