import * as gt from '../../store/getterNames'
import { NavTileChildOptions, NavTileFactory, NavTileOptions } from '@/models/core/NavTiles'
import WerkFile from '@/models/files/WerkFile'
import WerkFileListModel from '@/models/files/WerkFileListModel'

const getWerkFileObject: NavTileFactory = (client, getters, auth) => {
  const fileUrl = '/' + client + '/files'
  const filesAreLoaded = (getters[gt.FILES_FOR_CURRENT_CLIENT] as WerkFileListModel[]).length > 0
  const openWerkFiles: WerkFile[] = getters[gt.FILES_OPEN_FOR_CURRENT_CLIENT] as WerkFile[]
  const fileChildren: NavTileChildOptions[] = []

  if (filesAreLoaded) {
    fileChildren.push({ name: 'File List', url: fileUrl })
  }

  openWerkFiles.forEach(file => {
    fileChildren.push({ name: file.name, url: fileUrl + '/' + file.id })
  })

  const tile: NavTileOptions = {
    title: 'Files',
    url: fileUrl,
    icon: 'mdi-file-multiple-outline',
    isExact: false,
    showBadge: fileChildren.length > 1,
    badgeCount: fileChildren.length,
    enableMenu: fileChildren.length > 1,
    loaded: filesAreLoaded,
    children: fileChildren,
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }

  return tile;
}

export default getWerkFileObject;
