
import { COMMENT_ADD_REFERRER, COMMENT_DROP_REFERRER } from '@/store/mutationNames'
import { COMMENT_FETCH_LINK_DESCRIPTIONS, COMMENT_FETCH_UNREAD_FOR_USER, COMMENT_MARK_AS_READ } from '@/store/actionNames'
import { COMMENT_REFERRER_EXISTS, COMMENTS_LIST_FOR_REF, CORE_CLIENT_CURRENT } from '@/store/getterNames'
import { Comment } from '@/models/comments/Comment'
import { COMMENT_WIDGET_REF } from '@/store/refNames'
import CommentDisplayFormatter from './CommentDisplayFormatter.vue'
import { CommentListModel } from '@/models/comments/CommentListModel'
import { DataTableHeader } from 'vuetify'
import { DeletedSpec } from '@/models/core/spec/DeletedSpec'
import LinkedEntityChip from '../common/LinkedEntityChip.vue'
import reformatDate from '@/util/reformatDate'
import RootState from '@/store/RootState'
import { UnreadMentionFetchRequest } from '@/models/comments/UnreadMentionFetchRequest'
import { UnreadMentionForUserSpec } from '@/models/comments/UnreadMentionForUserSpec'
import Vue from 'vue'
import VuexEntityReferer from '@/models/core/vuex/VuexEntityReferer'

export default Vue.extend({
  components: { 
    CommentDisplayFormatter,
    LinkedEntityChip,
  },

  data() {
    return {
      currentSortDesc: true,
      currentSortField: 'createdAt',
      headers: [
        {
          value: 'createdAt',
          text: 'From/At',
          width: 190,
        },
        {
          value: 'text',
          text: 'Comment',
        },
        {
          value: 'links',
          text: 'Linked To',
          width: 100,
        },
        {
          value: 'id',
          text: '',
          width: 50,
        },
      ] as DataTableHeader[],
      loadingDescriptionsFor: [] as string[],
      loadingEntityList: false,
      loadingEntityPreview: false,
    }
  },

  computed: {
    currentClient(): string {
      return (this.$store.getters[CORE_CLIENT_CURRENT] || {}).code ?? ''
    },

    entities(): CommentListModel[] {
      return this.$store.getters[COMMENTS_LIST_FOR_REF](this.currentClient, COMMENT_WIDGET_REF);
    },

    referrerExists(): boolean {
      return this.$store.getters[COMMENT_REFERRER_EXISTS](COMMENT_WIDGET_REF);
    },

    userId(): string {
      return (this.$store.state as RootState).userId;
    },
  },

  watch: {
    ['entities.length']: {
      handler(): void {
        this.fetchLinkDescriptionsIfNeeded();
      },
    }
  },

  created() {
    if (!this.referrerExists) {
      this.configureEntityReferrer();
      this.loadEntities();
    } else {
      this.fetchLinkDescriptionsIfNeeded();
    }
  },

  methods: {
    configureEntityReferrer(): void {
      const targeUserId = this.userId;

      const spec = new UnreadMentionForUserSpec(targeUserId).and(new DeletedSpec().not());

      const referrer = new VuexEntityReferer(
        this.currentClient,
        COMMENT_WIDGET_REF,
        (entity, mode) =>  {
          if (mode == 'listModel'){
            const comment = entity as Comment;

            return spec.isSatisfiedBy(comment) ? 'yes' : 'no';
          }

          return 'noChange';
        }
      );

      this.$store.commit(COMMENT_ADD_REFERRER, referrer)
    },

    async dismissComment(comment: CommentListModel): Promise<void> {
      this.loadingEntityList = true;

      await this.$store.dispatch(COMMENT_MARK_AS_READ, comment);

      this.loadingEntityList = false;
    },

    dropEntityReferrer(): void {
      this.$store.commit(COMMENT_DROP_REFERRER, {client: this.currentClient, name: COMMENT_WIDGET_REF});
    },

    fetchLinkDescriptionsIfNeeded(): void {
      this.entities.forEach(c => {
        if (c.links.find(x => x.description == undefined) && this.loadingDescriptionsFor.indexOf(c.id) == -1) {
          this.requestLinkDescriptionsFor(c);
        }
      });
    },

    formatDateCreated(createdDate: Date|string|null|undefined): string {
      return reformatDate(createdDate, 'mm/dd/yyyy hh:MM tt');
    },

    async loadEntities(): Promise<void> {
      this.loadingEntityList = true;

      const req = new UnreadMentionFetchRequest(
        this.currentClient,
        this.userId,
        COMMENT_WIDGET_REF,
      );

      await this.$store.dispatch(COMMENT_FETCH_UNREAD_FOR_USER, req);

      this.fetchLinkDescriptionsIfNeeded();

      this.loadingEntityList = false;
    },

    async requestLinkDescriptionsFor(comment: CommentListModel): Promise<void> {
      this.loadingDescriptionsFor.push(comment.id);

      await this.$store.dispatch(COMMENT_FETCH_LINK_DESCRIPTIONS, comment);

      const idx = this.loadingDescriptionsFor.findIndex(x => x == comment.id);

      if (idx > -1 ) {
        this.loadingDescriptionsFor.splice(idx, 1);
      }
    },
  }
})
