import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue, SearchUIRangeValue } from "@/models/core/searchQueries/SearchUIOption";
import reformatDate from "@/util/reformatDate";
import { UpdatableEntitySearchFieldDefs } from "@/models/core/searchQueries/UpdatableEntitySearchFieldDefs";

export const QuoteSearchFieldDefs = [
  ...UpdatableEntitySearchFieldDefs,

  {fieldName: "quoteNumber", displayName: "Quote Number", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "customerName", displayName: "Customer Name", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "name", displayName: "Name", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "salesRep", displayName: "Sales Rep", type: SearchUIFieldType.ContactSet},
  {fieldName: "customerRep", displayName: "Customer Rep", type: SearchUIFieldType.ContactSet},
  {fieldName: "targetDate", displayName: "Target Date", type: SearchUIFieldType.DateTime},

  {fieldName: "claimedAt", displayName: "Claimed On", type: SearchUIFieldType.DateTime, notNoun: true},
  {fieldName: "preparedAt", displayName: "Prepared On", type: SearchUIFieldType.DateTime, notNoun: true},
  {fieldName: "approvedAt", displayName: "Approved On", type: SearchUIFieldType.DateTime, notNoun: true},
  {fieldName: "submittedAt", displayName: "Submitted On", type: SearchUIFieldType.DateTime, notNoun: true},
  {fieldName: "revisionRequestedAt", displayName: "Revision Requested On", type: SearchUIFieldType.DateTime, notNoun: true},
  {fieldName: "closedAt", displayName: "Closed On", type: SearchUIFieldType.DateTime, notNoun: true},

  {fieldName: "claimedBy", displayName: "Claimed By", type: SearchUIFieldType.UserSet, notNoun: true},
  {fieldName: "preparedBy", displayName: "Prepared By", type: SearchUIFieldType.UserSet, notNoun: true},
  {fieldName: "approvedBy", displayName: "Approved By", type: SearchUIFieldType.UserSet, notNoun: true},
  {fieldName: "submittedBy", displayName: "Submitted By", type: SearchUIFieldType.UserSet, notNoun: true},
  {fieldName: "revisionRequestedBy", displayName: "Revision Requested By", type: SearchUIFieldType.UserSet, notNoun: true},
  {fieldName: "closedBy", displayName: "Closed By", type: SearchUIFieldType.UserSet, notNoun: true},

  {fieldName: "sellingPrice", displayName: "Selling Price", type: SearchUIFieldType.NumberRange},

  {
    fieldName: "status",
    displayName: "Status",
    type: SearchUIFieldType.StringSet,
    options: [
      "New", "In Progress", "Prepared", "Approved", "Submitted", "Revision Requested",
      "In Revision", "Revised", "Re-approved", "Re-submitted", "Discarded",
      "Accepted", "Declined"
    ]
  },
] as SearchUIFieldDef[];

const thirtyDaysAgoDate = new Date(new Date().getTime() - (1000*3600*24*30));

const thirtyDaysAgo = reformatDate(thirtyDaysAgoDate, 'yyyy-mm-dd');

export const DefaultQuoteQuery = [
  new SearchUIFieldValue(
    'createdAt',
    SearchUIFieldType.DateTime,
    new SearchUIRangeValue<string>('gte', thirtyDaysAgo)
  )
] as SearchUIFieldValue[]

export const OpenQuoteReportQuery = [
  new SearchUIFieldValue(
    "status",
    SearchUIFieldType.StringSet,
    ["New", "In Progress", "Prepared", "Approved", "Submitted", "Revision Requested",
    "In Revision", "Revised", "Re-approved", "Re-submitted"]
  )
]

export const QuoteControlReportQuery = [
  new SearchUIFieldValue(
    'createdAt',
    SearchUIFieldType.DateTime,
    new SearchUIRangeValue<string>('gte', thirtyDaysAgo)
  )
]

const today = new Date();
const lastOfPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
const firstOfPrevMonth = new Date(today.getFullYear(), today.getMonth()-1, 1);

const qlrqStart = reformatDate(firstOfPrevMonth, "yyyy-mm-dd");
const qlrqEnd = reformatDate(lastOfPrevMonth, "yyyy-mm-dd");

export const QuoteLifecycleReportQuery = [
  new SearchUIFieldValue(
    'createdAt',
    SearchUIFieldType.DateTime,
    new SearchUIRangeValue<string>('btwi', qlrqStart, qlrqEnd)
  ),
  new SearchUIFieldValue(
    "status",
    SearchUIFieldType.StringSet,
    ["Accepted"]
  )
]