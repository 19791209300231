import * as gt from '../../store/getterNames'
import { NavTileChildOptions, NavTileFactory, NavTileOptions } from '@/models/core/NavTiles'
import { Contact } from '@/models/contacts/Contact'
import { ContactListModel } from '@/models/contacts/ContactListModel'

const getContactObject: NavTileFactory = (client, getters, auth) => {
  const contactUrl = '/' + client + '/contacts'
  const contactsAreLoaded = (getters[gt.CONTACTS_FOR_CURRENT_CLIENT] as ContactListModel[]).length > 0
  const openContacts: Contact[] = getters[gt.CONTACTS_OPEN_FOR_CURRENT_CLIENT] as Contact[]
  const contactChildren: NavTileChildOptions[] = []

  if (contactsAreLoaded) {
    contactChildren.push({ name: 'Contact List', url: contactUrl })
  }

  openContacts.forEach(contact => {
    contactChildren.push({ name: contact.displayName ?? 'Unknown Name', url: contactUrl + '/' + contact.id })
  })

  const tile: NavTileOptions = {
    title: 'Contacts',
    url: contactUrl,
    icon: 'mdi-contacts-outline',
    isExact: false,
    showBadge: contactChildren.length > 1,
    badgeCount: contactChildren.length,
    enableMenu: contactChildren.length > 1,
    loaded: contactsAreLoaded,
    children: contactChildren,
    enableTile: auth.hasFeatureForClient(client, "1000000")
  }

  return tile;
}

export default getContactObject;