import Address from '../core/Address';
import { CustomerNameOnly } from '../customers/CustomerNameOnly';
import { EmailContact } from '../core/EmailContact';
import EntityActionType from '../core/entities/EntityActionType';
import PrivateEntity from '../core/entities/PrivateEntity';
import QuoteSection from './QuoteSection';

export default class Quote extends PrivateEntity {
    public quoteNumber = '';
    public customer: CustomerNameOnly = {
        id : "0",
        name : ''
    };
    public contractor = '';
    public name = '';
    public address: Address = new Address();

    public salesRep = new EmailContact();
    public customerRep = new EmailContact();
    public notes = '';
    public estimatingNotes = '';
    public probability = 'Normal' as 'High'|'Normal'|'Low';
    public targetDate? = '';

    public claimedBy = '';
    public claimedAt = '';
    public preparedBy = '';
    public preparedAt = '';
    public approvedBy = '';
    public approvedAt = '';
    public submittedBy = '';
    public submittedAt = '';
    public revisionRequestedBy = '';
    public revisionRequestedAt = '';
    public closedBy = '';
    public closedAt = '';
    public reasonClosed = '';
    public reasonClosedDesc = '';
    public reasonDeclined = '';
    public status = '';
    
    public subtotal = 0;
    public overheadFactor = 0;
    public overheadAmount = 0;
    public breakEvenPrice = 0;
    public profitFactor = 0;
    public profitAmount = 0;
    public sellingPrice = 0;
    public discountableAmount = 0;
    public nonDiscountableItemCount = 0;
    public grossMargin = 0;

    public externalId = "";

    public sections: QuoteSection[] = [];

    public readyActions: EntityActionType[] = [];
}