
import { Assignment, AssignmentListModel } from '@/models/assignments/Assignment';
import reformatDate, { ensureDateObject, getCurrentTimezoneOffsetString } from '@/util/reformatDate';
import api from '@/util/api';
import { ASSIGNMENT_STORE_ENTITY_FOR_REF } from '@/store/mutationNames';
import EntityActionResult from '@/models/core/api/EntityActionResult';
import notifications from '@/util/notifications';
import { PropValidator } from 'vue/types/options';
import Vue from 'vue'
import VuexEntityStorageRequest from '@/models/core/vuex/VuexEntityStorageRequest';
import { VuexEntityStorageRequestMode } from '@/models/core/vuex/VuexEntityStorageRequestMode';

export default Vue.extend({
  components: {
  },

  props: {
    assignment: {
      type: Object,
      default: null,
    } as PropValidator<AssignmentListModel>,

    canCreateAssignments: Boolean,

    clientCode: {
      type: String,
      default: null,
    },

    defaultTime24hr: {
      type: String,
      default: "17:00"
    },

    now: {
      type: Date,
      default: null,
      required: true,
    } as PropValidator<Date>,

    refName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      executingAction: false,
      internalDate: '',
      internalTime: '',
      localTimeZoneOffset: '',
      showDateEditorDlg: false,
    }
  },

  computed: {
    canChange(): boolean {
      return this.canCreateAssignments && !this.isLocked
    },

    combinedEditedDateTimeOffsetString(): string {
      return `${this.selectedDate}T${this.selectedTime}${this.localTimeZoneOffset}`
    },

    dirty(): boolean {
      return this.canChange &&
        this.internalDate > '' &&
        this.internalTime > '' &&
        this.combinedEditedDateTimeOffsetString != this.assignment.dueAt;
    },

    formatedDueDate(): string {
      return reformatDate(this.assignment?.dueAt, 'mm/dd/yyyy hh:MM tt');
    },

    isLocked(): boolean {
      return this.assignment.status == "Done" || this.assignment.status == "Skipped"
    },

    selectedDate(): string {
      return this.internalDate == '' ? '0001-01-01' : this.internalDate;
    },

    selectedTime(): string {
      return this.internalTime == '' ? '00:00:00' : `${this.internalTime}:00`
    },
    
    isOverdue(): boolean {
      if (this.isLocked) return false;

      const due = ensureDateObject(this.assignment.dueAt);

      if (due) {
        return this.now > due;
      } else {
        return false;
      }
    }
  },

  created(): void {
    this.localTimeZoneOffset = getCurrentTimezoneOffsetString();
  },

  methods: {
    async close(mode: 'save'|'cancel'): Promise<void> {
      if (mode == 'save') {
        const data = Object.assign({}, this.assignment);
        data.dueAt = this.combinedEditedDateTimeOffsetString;
        await this.executeAssignmentAction('updateDueDate', data);
      }

      this.internalDate = '';
      this.internalTime = '';
      this.showDateEditorDlg = false;
    },

    async executeAssignmentAction(assignmentActionName: string, assignmentActionParams: unknown): Promise<void> {
      this.executingAction  = true;

      try {
        const response = await api.create(
          `${this.clientCode}/assignments/${this.assignment.id}/actions/${assignmentActionName}`,
          assignmentActionParams
        );

        if (response) {
          if (response.error) {
            notifications.fail(this.$store, `Failed to execute assignment action. Message: ${response.data || response.errorCause || response || "(No Message)"}`);
          } else {
            const result = response.data as EntityActionResult<Assignment>;

            const data: VuexEntityStorageRequest<Assignment> = {
              entity: result.entity,
              refName: this.refName,
              mode: VuexEntityStorageRequestMode.ListModelOnly,
            }

            this.$store.commit(ASSIGNMENT_STORE_ENTITY_FOR_REF, data)
          }
        }
      } catch(e) {
        notifications.fail(this.$store, 'Requested action failed.')
      }

      this.executingAction = false;
    },

    showChangeDueAt(evt: MouseEvent): void {
      if (!this.canChange) return;

      evt.preventDefault();
      evt.stopPropagation();
      evt.stopImmediatePropagation();

      this.internalDate = this.assignment.dueAt == '0001-01-01T00:00:00+00:00' ? '' : reformatDate(this.assignment.dueAt, 'yyyy-mm-dd');
      this.internalTime = this.assignment.dueAt == '0001-01-01T00:00:00+00:00' ? this.defaultTime24hr : reformatDate(this.assignment.dueAt, 'HH:MM');
      this.showDateEditorDlg = true;
    },
  }
})
