
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'
import { WorkOrderWorkflow } from '@/models/workOrders/WorkOrderWorkflow'

export default Vue.extend({
  props: {
    workflows: {
      type: Array,
      default: null,
    } as PropValidator<WorkOrderWorkflow[]>,

    currentWorkflow: {
      type: Object,
      default: null,
    } as PropValidator<WorkOrderWorkflow|null>
  },

  data() {
    return {
    }
  },

  computed: {
    loaded(): boolean {
      return !!this.workflows
    },

    selectedKey(): string {
      if (this.currentWorkflow) {
        const idx = this.workflows.findIndex(x => x === this.currentWorkflow)

        return `${idx}_${this.currentWorkflow.name}`
      } else {
        return '';
      }
    }
  },

  methods: {
    handleWorkflowClick(workflow: WorkOrderWorkflow): void {
      if (this.currentWorkflow == workflow) {
        this.$emit('update:currentWorkflow', null);
      } else {
        this.$emit('update:currentWorkflow', workflow);
      }
    }
  }
})
