import { AssignmentWorkflowListModel } from "../assignments/AssignmentWorkflow";
import { CustomerNameOnly } from "../customers/CustomerNameOnly";
import EntityActionType from "../core/entities/EntityActionType";
import EntityLink from "../core/entities/EntityLink";
import { LinkableEntity } from "../core/entities/LinkableEntity";
import PrivateEntityListModel from "../core/entities/PrivateEntityListModel";
import { TicketTypeListModel } from "./TicketType";

export class Ticket extends LinkableEntity {
  constructor(
    public ticketNumber = '',
    public customer: CustomerNameOnly = {id: '', name: ''},
    public type = new TicketTypeListModel(),
    public name = '',
    public issueDescription = '',
    public internalNotes = '',
    public targetDate: string|undefined = undefined,
    public closedBy = '',
    public closedAt: string|undefined = undefined,
    public reasonClosed = '',
    public status = '',
    public sortIndex = 0,
    public owners = [] as string[],
    public readyActions = [] as EntityActionType[],
  ) {
    super();
  }

  public initialWorkflow?: AssignmentWorkflowListModel;
}

export class TicketListModel extends PrivateEntityListModel<Ticket> {
  constructor(
    public ticketNumber = '',
    public customer: CustomerNameOnly = {id: '', name: ''},
    public type = '',
    public name = '',
    public status = '',
    public createdAt = '',
    public closedAt = '',
    public targetDate: string|undefined = undefined,
    public owners = [] as string[],
    public sortIndex = 0,
    public links: EntityLink[] = [],
  ) {
    super();
  }

  protected populateCore(model: Ticket): void {
    this.ticketNumber = model.ticketNumber;
    this.customer = model.customer;
    this.type = model.type.name;
    this.name = model.name;
    this.status = model.status;
    this.createdAt = model.createdAt ?? '';
    this.closedAt = model.closedAt ?? '';
    this.targetDate = model.targetDate;
    this.owners = [...model.owners];
    this.sortIndex = model.sortIndex;
    this.links = model.links ?? []
  }
}