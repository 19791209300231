
import * as gt from '../../store/getterNames'
import { DefaultQuoteQuery, OpenQuoteReportQuery, QuoteControlReportQuery, QuoteLifecycleReportQuery, QuoteSearchFieldDefs } from "./QuoteSearchFieldDefs";
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames';
import { Format, ReportProps } from '@/models/core/reports/ReportProps';
import { DataTableHeader } from 'vuetify'
import EntityManager from '../common/EntityManager.vue'
import { EntityManagerInterface } from '@/models/core/entityManager/EntityManagerInterface';
import { EntityManagerState } from '@/models/core/entityManager/EntityManagerState';
import { moneyFormatter } from '@/util/ValueFormatter'
import { PropValidator } from 'vue/types/options';
import Quote from '../../models/quotes/Quote'
import { QUOTE_EDITOR } from '@/router/routeNames';
import QuoteListModel from '../../models/quotes/QuoteListModel'
import QuoteNew from './QuoteNew.vue'
import QuoteStatusChip from './QuoteStatusChip.vue'
import reformatDate from '@/util/reformatDate'
import ReportMenu from "../common/ReportMenu.vue"
import { SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption';
import Vue from 'vue'

export default Vue.extend({

  components: {
    EntityManager,
    QuoteNew,
    QuoteStatusChip,
    ReportMenu,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    quoteNumber: {
      type: String,
      default: '',
      required: false,
    },

    useAsChooser: Boolean,
    
    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<QuoteListModel|null>,
  },

  data () {
    return {
      defaultQuery: DefaultQuoteQuery,
      searchFieldDefs: QuoteSearchFieldDefs,
      showNewQuoteDlg: false,
    }
  },

  computed: {
    canCreateQuotes(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1003200") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1003210")
    },
    
    canViewQuotes(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1003100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1003110")
    },

    editorRoute(): string {
      return QUOTE_EDITOR
    },

    entityManagerState(): EntityManagerState|undefined {
      return this.$store.getters[gt.CORE_GET_ENTITY_MANAGER_STATE]("quote", this.refName);
    },

    kpiQuery(): SearchUIFieldValue[] {
      return this.entityManagerState?.searchValues ?? this.defaultQuery;
    },
    
    openQuotes(): Quote[] {
      return this.$store.getters[gt.QUOTES_OPEN_FOR_REF](this.clientCode, this.refName)
    },

    quoteReportList(): ReportProps[] {
      return [
        new ReportProps(
          "Quote Status Report",
          "mdi-tag-outline",
          "QuoteStatusReport",
          `${this.clientCode}/quotes/reports/status`,
          OpenQuoteReportQuery
        ),

        new ReportProps(
          "Quote Control Report",
          "mdi-account-tie-hat",
          "QuoteControlReport",
          `${this.clientCode}/quotes/reports/control`,
          QuoteControlReportQuery
        ),

        new ReportProps(
          "Quote Lifecycle Report",
          "mdi-timer-outline",
          "QuoteLifecycleReport",
          `${this.clientCode}/quotes/reports/lifecycle`,
          QuoteLifecycleReportQuery
        ),

        new ReportProps(
          "Quote Performance Report",
          "mdi-finance",
          "QuoteKPI",
          `${this.clientCode}/quotes/reports/kpi`,
          this.kpiQuery,
          Format.PdfOnly,
          true
        ),
      ]
    },

    quotes(): QuoteListModel[] {
      return this.$store.getters[gt.QUOTES_LISTED_FOR_REF](this.clientCode, this.refName)
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    },

    tableHeaders(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        { text: 'Quote #', value: 'quoteNumber' },
        { text: 'Customer', value: 'customer.name'},
        { text: 'Name', value: 'name' },
        { text: 'Sales Rep', value: 'salesRep' },
        { text: 'Claimed By', value: 'claimedBy' },
        { text: 'Entered', value: 'createdAt'},
        { text: 'Submitted', value: 'submittedAt'},
        { text: 'Closed', value: 'closedAt'},
        { text: 'Target', value: 'targetDate'},
        { text: 'Value', value: 'sellingPrice', align: 'end', },
        { text: 'Status', value: 'status' }
      ]

      if (this.useAsChooser) {
        return headers.filter(x => x.value == 'quoteNumber'
          || x.value == 'customer.name'
          || x.value == 'name'
          || x.value == 'salesRep'
          || x.value == 'createdAt')
      } else {
        return headers;
      }
    },
  },

  methods: {
    formatDateTime(input: Date|string|null|undefined): string {
      return reformatDate(input, 'mm/dd/yyyy');
    },

    formatMoney(input: string|null|undefined): string {
      return moneyFormatter(input || 0);
    },

    formatDateOnly(input: Date|string|null|undefined): string {
      return reformatDate(input, 'mm/dd/yyyy', false, true);
    },

    async unloadEntities(skipRedirect = false): Promise<boolean> {
      return await (this.$refs["mgr"] as unknown as EntityManagerInterface)?.unloadEntities(skipRedirect);
    },
  },
})
