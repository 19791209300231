import { SearchUIFieldDef, SearchUIFieldType, SearchUIFieldValue, SearchUIRangeValue } from "@/models/core/searchQueries/SearchUIOption";
import reformatDate from "@/util/reformatDate";
import { UpdatableEntitySearchFieldDefs } from "@/models/core/searchQueries/UpdatableEntitySearchFieldDefs";

export const TicketSearchFieldDefs = [
  ...UpdatableEntitySearchFieldDefs,

  {fieldName: "ticketNumber", displayName: "Ticket Number", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "type", displayName: "Type", type: SearchUIFieldType.TicketTypeSet},
  {fieldName: "customerName", displayName: "Customer Name", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "name", displayName: "Name", type: SearchUIFieldType.StringContainsOrEquals},
  {fieldName: "targetDate", displayName: "Target Date", type: SearchUIFieldType.DateTime},
  {fieldName: "closedAt", displayName: "Closed On", type: SearchUIFieldType.DateTime, notNoun: true},
  {fieldName: "closedBy", displayName: "Closed By", type: SearchUIFieldType.UserSet, notNoun: true},
  {fieldName: "reasonClosed", displayName: "Reason Closed", type: SearchUIFieldType.LifecycleCloseReasonSet, options: "Ticket"},
  {fieldName: "status", displayName: "Status", type: SearchUIFieldType.LifecycleStatusSet, options: "Ticket"},
  {fieldName: "owners", displayName: "Owner", type: SearchUIFieldType.UserIdSet},
] as SearchUIFieldDef[];

const thirtyDaysAgoDate = new Date(new Date().getTime() - (1000*3600*24*30));

const thirtyDaysAgo = reformatDate(thirtyDaysAgoDate, 'yyyy-mm-dd');

export const DefaultTicketQuery = [
  new SearchUIFieldValue(
    'createdAt',
    SearchUIFieldType.DateTime,
    new SearchUIRangeValue<string>('gte', thirtyDaysAgo)
  )
] as SearchUIFieldValue[]