
import Client from '@/models/core/Client';
import environment from '@/util/environment';
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'
export default Vue.extend({
  props: {
    clients: {
      type: Array,
      default: null,
      required: true,
    } as PropValidator<Client[]>,

    code: {
      type: String,
      default: "",
      required: true,
    }
  },

  data () {
    return {
      colors: [
        "cyan darken-4",
        "green darken-4",
        "red darken-4",
        "purple darken-3",
        "orange darken-4",
        "brown darken-2",
        "grey darken-2",
      ],
    }
  },

  computed: {
    background(): string {
      const idx = this.clientIndex;

      const client = this.client;

      if (!client) return "transparent";

      if (client.logo ?? "" > "") {
        return "white"
      } else {

        if (idx == -1) {
          return "transparent"
        } else {
          return this.colors[idx % this.colors.length]
        }
      }
    },

    client(): Client|null {
      if (this.clientIndex == -1) {
        return null;
      } else {
        return this.clients[this.clientIndex];
      }
    },

    clientIndex(): number {
      return this.clients.findIndex(c => {return c.code == this.code})
    },

    logoUrl(): string {
      if (!this.client) return "";

      if (!this.client.logo) return "";

      if (environment == 'dev') {
        return "http://localhost:5000" + this.client.logo;
      } else {
        return "https://appcenter.insightgroup.co" + this.client.logo;
      }
    },
  }
})
