import { render, staticRenderFns } from "./MessageWidget.vue?vue&type=template&id=51a2a91f&"
import script from "./MessageWidget.vue?vue&type=script&lang=ts&"
export * from "./MessageWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports