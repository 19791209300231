
import InsightUser from '@/models/core/InsightUser'
import RootState from '@/store/RootState'
import UserAvatar from '../UserAvatar.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    UserAvatar,
  },

  props: {
    allowJustMe: Boolean,
    
    allowNullUser: Boolean,

    label: {
      type: String,
      default: '',
    },

    nullUserText: {
      type: String,
      default: 'Blank User'
    },

    showNullUser: Boolean,

    value: {
      type: Array,
      default: (): string[] => [],
    },
  },

  computed: {
    justMeIcon(): string {
      if (this.value.length == 1 && this.value[0] == this.me?.id) {
        return 'mdi-checkbox-marked'
      } else {
        return 'mdi-checkbox-blank-outline'
      }
    },

    me(): InsightUser|undefined {
      const myId = (this.$store.state as RootState).userId;
      return this.userList.find(x => x.id == myId);
    },

    nullUserIcon(): string {
      if (this.showNullUser) {
        return 'mdi-checkbox-marked'
      } else {
        return 'mdi-checkbox-blank-outline'
      }
    },

    nullUserHint(): string {
      if (this.showNullUser)
        return 'Showing ' + this.nullUserText;
      else
        return '';
    },

    selectAllIcon(): string {
      if (this.value.length == this.userList.length) {
        return 'mdi-checkbox-marked'
      } else if (this.value.length > 0) {
        return 'mdi-minus-box'
      } else {
        return 'mdi-checkbox-blank-outline'
      }
    },

    userList(): InsightUser[] {
      return (this.$store.state as RootState).users;
    }
  },

  methods: {
    handleInput(evt: unknown): void {
      this.$emit('input', evt);
    },

    selectAll(): void {
      if (this.value.length < this.userList.length) {
        this.handleInput(this.userList.map(x => x.id));
      } else {
        this.handleInput([]);
      }
    },

    selectJustMe(): void {
      if (this.me) {
        if (this.value.length == 1 && this.value[0] == this.me.id) {
          this.handleInput([]);
        } else {
          this.handleInput([this.me.id]);
        }
      }
    },

    selectNullUser(): void {
      this.$emit('update:showNullUser', !this.showNullUser);
    }
  }
})
