
import { CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED, TICKET_LINK_TO_ENTITY, TICKET_UNLINK_FROM_ENTITY } from '@/store/actionNames'
import { CUSTOMER_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import AssignmentWorkflowChooser from '../assignments/workflows/AssignmentWorkflowChooser.vue'
import { AssignmentWorkflowListModel } from '@/models/assignments/AssignmentWorkflow'
import BlameDisplayField from '../common/forms/BlameDisplayField.vue'
import { CORE_GET_LIFECYCLE_HASH_FOR_TYPE } from '@/store/getterNames'
import { CUSTOMER_DROP_REFERRER } from '@/store/mutationNames'
import CustomerChooser from '../customers/CustomerChooser.vue'
import DateField from '../common/forms/DateField.vue'
import DescriptionField from '../common/forms/DescriptionField.vue'
import docUtil from '../../util/docUtil'
import { doSomethingOnEnter } from '@/util/keystrokes'
import EntityLink from '@/models/core/entities/EntityLink'
import EntityLinkParameters from '@/models/core/entities/EntityLinkParameters'
import EntityLinkRequest from '@/models/core/entities/EntityLinkRequest'
import { FieldSetConflictMixin } from '../common/FieldSetConflictMixin'
import { LifecycleHash } from '@/models/lifecycles/LifecycleHash'
import LinkedEntityChip from '../common/LinkedEntityChip.vue'
import NameField from '../common/forms/NameField.vue'
import notifications from '@/util/notifications'
import NumberField from '@/components/common/forms/NumberField.vue'
import popup from '@/util/popup'
import { PropValidator } from 'vue/types/options'
import ReadOnlyField from '../common/forms/ReadOnlyField.vue'
import reformatDate from '@/util/reformatDate'
import StatusChip from '../lifecycles/StatusChip.vue'
import { Ticket } from '@/models/tickets/Ticket'
import TicketTypeChooser from './types/TicketTypeChooser.vue'
import { TicketTypeListModel } from '@/models/tickets/TicketType'
import UserSelectField from '../common/forms/UserSelectField.vue'

export default FieldSetConflictMixin.extend({
  components: {
    AssignmentWorkflowChooser,
    BlameDisplayField,
    CustomerChooser,
    DateField,
    DescriptionField,
    LinkedEntityChip,
    NameField,
    NumberField,
    ReadOnlyField,
    StatusChip,
    TicketTypeChooser,
    UserSelectField,
  },

  props: {
    entity: {
      type: Object,
      default: (): Ticket => new Ticket()
    } as PropValidator<Ticket>,

    isNew: Boolean,

    readonly: Boolean,

    refName: {
      type: String,
      default: ENTITY_MANAGER_REF,
    },
  },

  data() {
    return {
      headerColCount : 2,
      showCustomerChooser: false,
      showTicketTypeChooser: false,
      showWorkflowChooser: false,
      unlinkingEntityId: '',
    }
  },

  computed: {
    customerName(): string {
      return (this.entity.customer || {}).name || '';
    },

    lifecycleHash(): LifecycleHash {
      return this.$store.getters[CORE_GET_LIFECYCLE_HASH_FOR_TYPE]("Ticket") ?? {stages: {}, statuses: {}}
    },

    linkedDocs(): (EntityLink|EntityLinkRequest)[] {
      return [
        ...this.entity?.links ?? [],
        ...this.entity?.requestedLinks ?? [],
      ]
    },

    targetDateIsAutomatic(): boolean {
      return (this.entity?.targetDate == '0001-01-01T00:00:00') && this.isNew;
    },

    typeName(): string {
      return this.entity?.type?.name ?? '';
    },

    workflowName(): string {
      return this.entity?.initialWorkflow?.name ?? '';
    },
  },

  created() {
    this.$store.dispatch(CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED, "Ticket");
  },

  beforeDestroy() {
    this.$store.commit(CUSTOMER_DROP_REFERRER, {client: this.entity.client, name: CUSTOMER_CHOOSER_REF});
  },

  methods: {
    dateFormatter(v: string): string {
      return reformatDate(v, 'mm/dd/yyyy');
    },
    
    handleCustomerFieldClick(): void {
      if (this.isNew) {
        this.showCustomerChooser = true;
      }
    },

    handleCustomerFieldKeypress(evt: KeyboardEvent): void {
      if (this.isNew) {
        doSomethingOnEnter(evt, () => this.showCustomerChooser = true)
      }
    },

    handleTicketTypeFieldClick(): void {
      if (this.isNew) {
        this.showTicketTypeChooser = true
      }
    },

    handleTicketTypeFieldKeypress(evt: KeyboardEvent): void {
      if (this.isNew) {
        doSomethingOnEnter(evt, () => this.showTicketTypeChooser = true)
      }
    },

    handleTicketTypeSelected(type: TicketTypeListModel): void {
      this.emitEditEventForField('type', type);

      const workflow = new AssignmentWorkflowListModel(type.defaultWorkflow)
      workflow.id = type.defaultWorkflowId;
      this.emitEditEventForField('initialWorkflow', workflow);
    },

    handleWorkflowKeypress(evt: KeyboardEvent): void {
      doSomethingOnEnter(evt, () => this.showWorkflowChooser = true)
    },
    
    async handleUnlinkDoc(link: EntityLink): Promise<void> {
      const confirmed = await popup.confirm(
        this.$store,
        "Confirm Unlink",
        `Are you sure you want to break the link between this ticket and ${link.description}?`);

      if (!confirmed) return;

      if (this.isNew) {
        const requestedLinks = [...this.entity?.requestedLinks ?? []];
        const idx = requestedLinks.findIndex(x => x.id == (link as unknown as EntityLinkRequest).id);

        if (idx > -1) {
          requestedLinks.splice(idx, 1);
          this.emitEditEventForField("requestedLinks", requestedLinks);
        }
      } else {
        this.unlinkingEntityId = link.entityId;

        var req = {
          target: {id: link.entityId, client: this.entity?.client},
          targetType: link.type,
          linkOwner: {id: this.entity?.id, client: this.entity?.client}
        } as EntityLinkParameters

        try {
          const result = await this.$store.dispatch(TICKET_UNLINK_FROM_ENTITY, req) as Ticket|null;

          if (result) {
            this.emitEditEventForField("links", result.links);
          }
        } catch (error) {
          const e = error as ApiResponse;
          notifications.warn(this.$store, `Failed to un-link ticket. Message: ${e.data || e.errorCause || e}`);
        }

        this.unlinkingEntityId = '';
      }
    },

    async linkDocument(): Promise<void> {
      const doc = await docUtil.requestLink(this.$store, this.entity?.client ?? '')

      if (doc == null) return;

      if (this.isNew) {
        this.emitEditEventForField("requestedLinks", [
          ...this.entity?.requestedLinks ?? [],
          doc
        ])
      } else {
        var req = {
          refName: this.refName,
          link: {
            target: {id: doc.id, client: this.entity?.client},
            targetType: doc.type,
            linkOwner: {id: this.entity?.id, client: this.entity?.client}
          } as EntityLinkParameters,
        }

        try {
          const result = await this.$store.dispatch(TICKET_LINK_TO_ENTITY, req) as Ticket|null;

          if (result) {
            this.emitEditEventForField("links", result.links);
          }
        } catch (error) {
          const e = error as ApiResponse;
          notifications.warn(this.$store, `Failed to link ticket. Message: ${e.data || e.errorCause || e}`);
        }
      }
    }
  }
})
