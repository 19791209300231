
import { Assignment, ChecklistItem } from '@/models/assignments/Assignment'
import { ApiResponse } from '@/models/core/api/ApiResponse'
import { ASSIGNMENT_STORE_ENTITY_FOR_REF } from '@/store/mutationNames'
import BlameDisplayField from '../common/forms/BlameDisplayField.vue'
import ChecklistEditor from './ChecklistEditor.vue'
import { CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED } from '@/store/actionNames'
import { CORE_GET_LIFECYCLE_HASH_FOR_TYPE } from '@/store/getterNames'
import DescriptionField from '../common/forms/DescriptionField.vue'
import { executeActionOnEntity } from '@/store/_actionHelpers'
import { LifecycleHash } from '@/models/lifecycles/LifecycleHash'
import notifications from '@/util/notifications'
import popup from '@/util/popup'
import { PropValidator } from 'vue/types/options'
import ReadOnlyField from '../common/forms/ReadOnlyField.vue'
import StatusChip from '../lifecycles/StatusChip.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    BlameDisplayField,
    ChecklistEditor,
    DescriptionField,
    ReadOnlyField,
    StatusChip,
  },

  props: {
    entity: {
      type: Object,
      default: (): Assignment => new Assignment()
    } as PropValidator<Assignment>,

    show: Boolean,

    tryingToFinish: Boolean,
  },

  data() {
    return {
      dirty: false,
      editableNotes: '',
      editableChecklist: [] as ChecklistItem[],
      saving: false,
    }
  },

  computed: {
    canEditAssignments(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1013300") ||
             this.$auth.hasPrivilegeForClient(this.entity.client, "1013310")
    },

    checklistComplete(): boolean {
      return this.editableChecklist.reduce<boolean>((v, x) => v && x.done, true);
    },

    lifecycleHash(): LifecycleHash {
      return this.$store.getters[CORE_GET_LIFECYCLE_HASH_FOR_TYPE]("Assignment") ?? {stages: {}, statuses: {}}
    },

    okButtonText(): string {
      if (!this.tryingToFinish) {
        return "OK"
      } else if (this.checklistComplete) {
        return 'Save and Finish'
      } else if (this.dirty) {
        return 'Save without Finishing'
      } else {
        return "Save and Finish"
      }
    },

    readonly(): boolean {
      return this.entity?.finishedBy > '' || this.entity?.skippedBy > '' || !this.canEditAssignments;
    },
  },

  watch: {
    'entity.id': {
      handler() {
        if ((this.entity?.id ?? '') > '') {
          this.editableNotes = this.entity.notes;
          this.editableChecklist = this.entity?.checklist?.map(x => new ChecklistItem(x.position, x.text, x.done)) ?? [];
          this.dirty = false;
        }
      },
    }
  },

  created() {
    this.$store.dispatch(CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED, "Assignment");
  },

  methods: {
    async close(mode: 'cancel'|'save'): Promise<void> {
      if (this.dirty) {
        if (mode == 'save') {
          try {
            await this.saveNotesAndChecklist();
          } catch (error) {
            const e = error as ApiResponse;
            notifications.warn(this.$store, `There was an error while saving your changes. Message: ${e.data || e.errorCause || e || "(No Message)"}`)
            return;
          }
        } else if (mode == 'cancel') {
          const confirmed = await popup.confirm(
            this.$store,
            "Confirm Close",
            "If you close now, your changes will be lost. Are you sure you want to close now?"
          );

          if (!confirmed) return;
        }
      }
      this.$emit('update:show', false);
    },

    async saveNotesAndChecklist(): Promise<void> {
      this.saving = true;

      const data = Object.assign({} as Assignment, this.entity)
      data.checklist = this.editableChecklist;
      data.notes = this.editableNotes;

      await executeActionOnEntity(
        this.entity,
        `${this.entity.client}/assignments`,
        'UpdateChecklistAndNotes',
        ASSIGNMENT_STORE_ENTITY_FOR_REF,
        this.$store.commit,
        data
      );

      this.saving = false;
    },
  }
})
