
import { NavTileOptions } from '@/models/core/NavTiles'
import { PropValidator } from 'vue/types/options'
import Vue from 'vue'

export default Vue.extend({
  props: {
    item: {
      type: Object,
      default: null,
    } as PropValidator<NavTileOptions>,

    minified: Boolean,
  },
  
  data() {
    return {
      mainUrl: '',
      menuActivator: null as HTMLElement | null,
    }
  },

  computed: {
    id(): string {
        return 'navTile_' + this.item.title.replace(' ', '')
    },
  },

  watch: {
    item: {
      handler(): void {
        this.updateActivator()
      },
      deep: true,
      immediate: true
    },

    $route: {
      handler(): void {
        const selectedChild = this.item.children.find(c =>
            c.url.toLowerCase() == this.$route.path.toLowerCase())

        if (selectedChild) {
            this.mainUrl = selectedChild.url
        } else {
            this.mainUrl = this.item.url
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    updateActivator(): void {
        this.menuActivator = null
        this.$nextTick(() => { this.menuActivator = document.getElementById(this.id) })
    },

    handleClick(evt: never): void {
      this.$emit('click', evt);
    }
  }
})
