
import * as gt from '../../store/getterNames'
import { DefaultTicketQuery, TicketSearchFieldDefs } from "./TicketSearchFieldDefs";
import { ENTITY_MANAGER_AS_CHOOSER_REF, ENTITY_MANAGER_REF } from '@/store/refNames';
import { Ticket, TicketListModel } from '@/models/tickets/Ticket'
import { CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED } from '@/store/actionNames';
import { DataTableHeader } from 'vuetify'
import EntityManager from '../common/EntityManager.vue'
import { EntityManagerInterface } from '@/models/core/entityManager/EntityManagerInterface';
import { LifecycleHash } from '@/models/lifecycles/LifecycleHash';
import { PropValidator } from 'vue/types/options';
import reformatDate from '@/util/reformatDate';
import { SearchUIFieldValue } from '@/models/core/searchQueries/SearchUIOption';
import StatusChip from '../lifecycles/StatusChip.vue'
import { TICKET_EDITOR } from '@/router/routeNames';
import TicketNew from './TicketNew.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    EntityManager,
    StatusChip,
    TicketNew,
  },

  props: {
    clientCode: {
      type: String,
      default: '',
    },

    useAsChooser: Boolean,
    
    useAsChooserSelection: {
      type: Object,
      default: null,
    } as PropValidator<TicketListModel|null>,
  },

  data () {
    return {
      searchFieldDefs: TicketSearchFieldDefs,
      showNewTicketDlg: false,
    }
  },

  computed: {
    canCreateTickets(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1014200") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1014210");
    },

    canEditTickets(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1014300") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1014310");
    },

    canViewTickets(): boolean {
      return this.$auth.hasPrivilegeAnyClient("1014100") ||
                this.$auth.hasPrivilegeForClient(this.clientCode, "1014110");
    },

    defaultQuery(): SearchUIFieldValue[] {
      return DefaultTicketQuery;
    },

    editorRoute(): string {
      return TICKET_EDITOR;
    },

    lifecycleHash(): LifecycleHash {
      return this.$store.getters[gt.CORE_GET_LIFECYCLE_HASH_FOR_TYPE]("Ticket") ?? {stages: {}, statuses: {}}
    },
    
    openTickets(): Ticket[] {
      return this.$store.getters[gt.TICKETS_OPEN_FOR_REF](this.clientCode, this.refName);
    },

    refName(): string {
      if (this.useAsChooser) {
        return ENTITY_MANAGER_AS_CHOOSER_REF
      } else {
        return ENTITY_MANAGER_REF
      }
    },

    tableHeaders(): DataTableHeader[] {
      const headers = [
        { text: 'Ticket #', value: 'ticketNumber', width: '110px'},
        { text: 'Name', value: 'name', },
        { text: 'Customer', value: 'customer.name', },
        { text: 'Type', value: 'type', },
        { text: 'Opened At', value: 'createdAt', },
        { text: 'Target', value: 'targetDate', },
        { text: 'Status', value: 'status', width: '200px' },
      ] as DataTableHeader[];

      if (this.useAsChooser) {
        return headers.filter(x => x.value == 'ticketNumber'
          || x.value == 'customer.name'
          || x.value == 'name'
          || x.value == 'status'
          || x.value == 'createdAt')
      } else {
        return headers;
      }
    },

    tickets(): TicketListModel[] {
      return this.$store.getters[gt.TICKETS_LIST_FOR_REF](this.clientCode, this.refName);
    },
  },

  created() {
    this.$store.dispatch(CORE_FETCH_ACTIVE_LIFECYCLE_IF_NEEDED, "Ticket");
  },

  methods: {
    formatDateAndTime(input: Date|string|null|undefined): string {
      return reformatDate(input, 'mm/dd/yyyy hh:MM tt');
    },

    formatDateOnly(input: Date|string|null|undefined): string {
      return reformatDate(input, 'mm/dd/yyyy');
    },

    async unloadEntities(skipRedirect = false): Promise<boolean> {
      return await (this.$refs["mgr"] as unknown as EntityManagerInterface)?.unloadEntities(skipRedirect);
    },
  }
})
