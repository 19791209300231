import PromiseCompleter, { PromiseRejectFunction, PromiseResolveFunction } from "./PromiseCompleter";
import { CloseReasonParams } from "./actionParams/CloseReasonParams";

export default class DocumentCloseRequest extends PromiseCompleter<CloseReasonParams|null> {
  constructor(
    resolve: PromiseResolveFunction<CloseReasonParams|null>,
    reject: PromiseRejectFunction,
    public reasonsToClose: string[],
    public documentType: string,
    public showAssignmentWarningOnForce = true,
  ) {
    super(resolve, reject);
  }
}